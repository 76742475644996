import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { Button } from '@tremor/react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { Wrapper, TextInput, Editor, Label } from '@/components';
import { handleFormError } from '@/utils';
import {
  useUpdatePage,
  getPagesQueryKey,
  getPageSuspenseQueryOptions,
} from '@/api';

export const Route = createFileRoute('/_authenticated/pages/$pageId/edit')({
  loader: async ({ context: { queryClient }, params: { pageId } }) =>
    queryClient.ensureQueryData(getPageSuspenseQueryOptions(Number(pageId))),
  component: EditPage,
});

const schema = z.object({
  title: z.string(),
  content: z.string(),
});

function EditPage() {
  const page = Route.useLoaderData();
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdatePage({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getPagesQueryKey(),
        });
      },
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      title: page.title,
      content: page.content,
    },
  });

  useEffect(() => {
    if (page) {
      reset({ title: page.title, content: page.content });
    }
  }, [page, reset]);

  const onSave = handleSubmit(async ({ title, content }) => {
    if (!page) return;

    try {
      const response = await mutateAsync({
        id: page.id,
        data: { title, content },
      });
      reset({ title: response.title, content: response.content });
      toast.success('Page updated successfully!');
    } catch (error) {
      handleFormError(error, setError);
    }
  });

  return (
    <Wrapper header='Edit page' backLink='/pages'>
      <div className='mb-4'>
        <TextInput
          name='title'
          label='Title'
          placeholder='Title'
          control={control}
          errorMessage={errors.title?.message}
        />
      </div>
      <div className='mb-2 block'>
        <Label value='Content' />
      </div>
      {getValues('content') && (
        <Editor
          content={getValues('content')}
          onChange={(content) => {
            setValue('content', content);
          }}
        />
      )}
      <div className='flex justify-end mt-4'>
        <Button onClick={onSave}>Save</Button>
      </div>
    </Wrapper>
  );
}
