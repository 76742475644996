/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import {
  Modal,
  TextInput,
  Textarea,
  DatePicker,
  SearchSelect,
} from '@/components';
import { useUpdateUser, getUsersQueryKey } from '@/api';
import { handleFormError, countries } from '@/utils';
import type { User } from '@/api/model';

const schema = z.object({
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  birthdate: z.date(),
  countryIso2: z.string().length(2),
  phoneNumber: z.string(),
  comment: z.string().optional(),
  billingAddress: z.object({
    name: z.string().nullable(),
    city: z.string(),
    countryIso2: z.string().length(2),
    number: z.string(),
    postalCode: z.string(),
    street: z.string(),
  }),
  shippingAddress: z.object({
    name: z.string().nullable(),
    city: z.string(),
    countryIso2: z.string().length(2),
    number: z.string(),
    postalCode: z.string(),
    street: z.string(),
  }),
});

export function UpdateUserModal({
  user,
  show,
  showModal,
}: {
  readonly user: User | undefined;
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdateUser({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getUsersQueryKey(),
        });
      },
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (user) {
      reset({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        birthdate: user.birthdate,
        countryIso2: user.countryIso2,
        phoneNumber: user.phoneNumber,
        comment: user.comment ?? '',
        billingAddress: user.billingAddress,
        shippingAddress: user.shippingAddress,
      });
    }
  }, [user, reset]);

  const onSave = handleSubmit(
    async ({
      email,
      firstName,
      lastName,
      birthdate,
      countryIso2,
      phoneNumber,
      comment,
      shippingAddress,
      billingAddress,
    }) => {
      if (!user) return;

      try {
        await mutateAsync({
          id: user.id,
          data: {
            email,
            first_name: firstName,
            last_name: lastName,
            birthdate,
            country_ISO2: countryIso2,
            phone_number: phoneNumber,
            comment,
            shipping_address: {
              name: shippingAddress.name,
              city: shippingAddress.city,
              country_ISO2: shippingAddress.countryIso2,
              number: shippingAddress.number,
              postal_code: shippingAddress.postalCode,
              street: shippingAddress.street,
            },
            billing_address: {
              name: billingAddress.name,
              city: billingAddress.city,
              country_ISO2: billingAddress.countryIso2,
              number: billingAddress.number,
              postal_code: billingAddress.postalCode,
              street: billingAddress.street,
            },
          },
        });
        reset();
        showModal(false);
        toast.success('User updated successfully!');
      } catch (error) {
        handleFormError(error, setError);
      }
    },
  );

  return (
    <Modal
      large
      title='Update user'
      show={show}
      onClose={() => {
        showModal(false);
      }}
      onSave={onSave}
    >
      <form>
        <div className='grid grid-cols-1 gap-4 sm:grid-cols-6'>
          <div className='flex flex-col gap-4 col-span-full sm:col-span-3'>
            <TextInput
              name='email'
              label='Email'
              placeholder='Email'
              type='email'
              control={control}
              errorMessage={errors.email?.message}
            />
            <TextInput
              name='firstName'
              label='First name'
              placeholder='First name'
              control={control}
              errorMessage={errors.firstName?.message}
            />
            <TextInput
              name='lastName'
              label='Last name'
              placeholder='Last name'
              control={control}
              errorMessage={errors.lastName?.message}
            />
            <DatePicker
              name='birthdate'
              label='Birthdate'
              placeholder='Birthdate'
              control={control}
              maxDate={new Date()}
            />
            <SearchSelect
              name='countryIso2'
              label='Country'
              options={countries.map((country) => ({
                value: country.code,
                label: country.name,
              }))}
              control={control}
              errorMessage={errors.countryIso2?.message}
              enableClear={false}
            />
            <TextInput
              name='phoneNumber'
              label='Phone number'
              placeholder='Phone number'
              control={control}
              errorMessage={errors.phoneNumber?.message}
            />
            <Textarea
              name='comment'
              label='Comment'
              placeholder='Comment'
              control={control}
              errorMessage={errors.comment?.message}
            />
          </div>
          <div className='col-span-full sm:col-span-3'>
            <div className='font-semibold text-gray-900 dark:text-white mb-1'>
              Billing address
            </div>
            <div className='flex flex-col gap-4'>
              <TextInput
                name='billingAddress.name'
                label='Name'
                placeholder='Name'
                control={control}
                errorMessage={errors.billingAddress?.name?.message}
              />
              <TextInput
                name='billingAddress.street'
                label='Street'
                placeholder='Street'
                control={control}
                errorMessage={errors.billingAddress?.street?.message}
              />
              <TextInput
                name='billingAddress.number'
                label='Number'
                placeholder='Number'
                control={control}
                errorMessage={errors.billingAddress?.number?.message}
              />
              <TextInput
                name='billingAddress.postalCode'
                label='Postal code'
                placeholder='Postal code'
                control={control}
                errorMessage={errors.billingAddress?.postalCode?.message}
              />
              <TextInput
                name='billingAddress.city'
                label='City'
                placeholder='City'
                control={control}
                errorMessage={errors.billingAddress?.city?.message}
              />
              <TextInput
                name='billingAddress.countryIso2'
                label='Country'
                placeholder='Country'
                control={control}
                errorMessage={errors.billingAddress?.countryIso2?.message}
              />
            </div>
            <div className='font-semibold text-gray-900 dark:text-white mt-4 mb-1'>
              Shipping address
            </div>
            <div className='flex flex-col gap-4'>
              <TextInput
                name='shippingAddress.name'
                label='Name'
                placeholder='Name'
                control={control}
                errorMessage={errors.shippingAddress?.name?.message}
              />
              <TextInput
                name='shippingAddress.street'
                label='Street'
                placeholder='Street'
                control={control}
                errorMessage={errors.shippingAddress?.street?.message}
              />
              <TextInput
                name='shippingAddress.number'
                label='Number'
                placeholder='Number'
                control={control}
                errorMessage={errors.shippingAddress?.number?.message}
              />
              <TextInput
                name='shippingAddress.postalCode'
                label='Postal code'
                placeholder='Postal code'
                control={control}
                errorMessage={errors.shippingAddress?.postalCode?.message}
              />
              <TextInput
                name='shippingAddress.city'
                label='City'
                placeholder='City'
                control={control}
                errorMessage={errors.shippingAddress?.city?.message}
              />
              <TextInput
                name='shippingAddress.countryIso2'
                label='Country'
                placeholder='Country'
                control={control}
                errorMessage={errors.shippingAddress?.countryIso2?.message}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
