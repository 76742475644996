import { snakeCase } from 'lodash';

/**
 * Converts an object's keys to snake case
 * @param {Record<string, unknown>} object - The object to convert to snake case.
 * @returns {Record<string, unknown>} The converted object.
 */
export const snakeCaseObject = (
  object: Record<string, unknown>,
): Record<string, unknown> => {
  const snakeCaseObject: Record<string, unknown> = {};
  for (const key of Object.keys(object)) {
    snakeCaseObject[snakeCase(key)] = object[key];
  }

  return snakeCaseObject;
};
