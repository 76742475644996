import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { Modal } from '@/components';
import { useDelayPayout, getItemQueryKey, getItemsQueryKey } from '@/api';
import type { Item, Order } from '@/api/model';

export function DelayPayoutOrderModal({
  item,
  order,
  show,
  showModal,
}: {
  readonly item: Item | undefined;
  readonly order: Order | undefined;
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const queryClient = useQueryClient();
  const { mutateAsync } = useDelayPayout({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getItemQueryKey(item!.id),
        });
        await queryClient.invalidateQueries({
          queryKey: getItemsQueryKey(),
        });
      },
    },
  });

  const onSave = async () => {
    if (!order) return;

    await mutateAsync({ id: order.id });
    showModal(false);
    toast.success('Order payout delayed successfully!');
  };

  return (
    <Modal
      title='Delay payout order'
      show={show}
      onClose={() => {
        showModal(false);
      }}
      onCancel={() => {
        showModal(false);
      }}
      onSave={onSave}
    >
      <p className='mb-4'>Are you sure you want to delay the payout?</p>
    </Modal>
  );
}
