/* eslint-disable @typescript-eslint/naming-convention */
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import {
  Modal,
  TextInput,
  Textarea,
  DatePicker,
  SearchSelect,
} from '@/components';
import { useCreateUser, getUsersQueryKey } from '@/api';
import { handleFormError, countries } from '@/utils';

const schema = z.object({
  email: z.string().email(),
  password: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  birthdate: z.date(),
  countryIso2: z.string().length(2),
  phoneNumber: z.string(),
  comment: z.string().optional(),
});

export function CreateUserModal({
  show,
  showModal,
}: {
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const queryClient = useQueryClient();
  const { mutateAsync } = useCreateUser({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getUsersQueryKey(),
        });
      },
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const onSave = handleSubmit(
    async ({
      email,
      password,
      firstName,
      lastName,
      birthdate,
      countryIso2,
      phoneNumber,
      comment,
    }) => {
      try {
        await mutateAsync({
          data: {
            email,
            password,
            first_name: firstName,
            last_name: lastName,
            birthdate,
            country_ISO2: countryIso2,
            phone_number: phoneNumber,
            comment,
          },
        });
        reset();
        showModal(false);
        toast.success('User created successfully!');
      } catch (error) {
        handleFormError(error, setError);
      }
    },
  );

  return (
    <Modal
      title='Create user'
      show={show}
      onClose={() => {
        showModal(false);
      }}
      onSave={onSave}
    >
      <form className='flex flex-col gap-4'>
        <TextInput
          name='email'
          label='Email'
          placeholder='Email'
          type='email'
          control={control}
          errorMessage={errors.email?.message}
        />
        <TextInput
          name='password'
          type='password'
          label='Password'
          placeholder='Password'
          control={control}
          errorMessage={errors.password?.message}
        />
        <TextInput
          name='firstName'
          label='First name'
          placeholder='First name'
          control={control}
          errorMessage={errors.firstName?.message}
        />
        <TextInput
          name='lastName'
          label='Last name'
          placeholder='Last name'
          control={control}
          errorMessage={errors.lastName?.message}
        />
        <DatePicker
          name='birthdate'
          label='Birthdate'
          placeholder='Birthdate'
          control={control}
          maxDate={new Date()}
        />
        <SearchSelect
          name='countryIso2'
          label='Country'
          options={countries.map((country) => ({
            value: country.code,
            label: country.name,
          }))}
          control={control}
          errorMessage={errors.countryIso2?.message}
          enableClear={false}
        />
        <TextInput
          name='countryIso2'
          label='Country'
          placeholder='Country'
          control={control}
          errorMessage={errors.countryIso2?.message}
        />
        <TextInput
          name='phoneNumber'
          label='Phone number'
          placeholder='Phone number'
          control={control}
          errorMessage={errors.phoneNumber?.message}
        />
        <Textarea
          name='comment'
          label='Comment'
          placeholder='Comment'
          control={control}
          errorMessage={errors.comment?.message}
        />
      </form>
    </Modal>
  );
}
