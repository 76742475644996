import { Button } from 'flowbite-react';

import { formatEnum } from '@/utils';

export function QuickSelect<T = string[]>({
  selected,
  setSelected,
  options,
}: {
  readonly selected: T | undefined;
  readonly setSelected: (value: T | undefined) => void;
  readonly options: Array<T | undefined>;
}) {
  return (
    <Button.Group className='shadow-sm'>
      {options.map((option) => (
        <Button
          key={option === undefined ? 'all' : (option as string)}
          color={option === selected ? 'primary' : 'gray'}
          onClick={() => {
            setSelected(option);
          }}
        >
          {formatEnum(option === undefined ? 'all' : (option as string))}
        </Button>
      ))}
    </Button.Group>
  );
}
