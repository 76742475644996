import {
  NumberInput as TremorNumberInput,
  type NumberInputProps,
} from '@tremor/react';
import {
  Controller,
  type FieldValues,
  type Control,
  type Path,
} from 'react-hook-form';

import { Label } from '../label';

export type NumberInputProperties<T extends FieldValues> = NumberInputProps & {
  readonly control: Control<T>;
  readonly name: Path<T>;
  readonly label?: string;
  readonly errorMessage?: string;
};

export function NumberInput<T extends FieldValues>({
  control,
  name,
  label,
  errorMessage,
  ...rest
}: NumberInputProperties<T>) {
  return (
    <div className='w-full'>
      {label && (
        <div className='mb-2 block'>
          <Label htmlFor={name} value={label} />
        </div>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TremorNumberInput
            {...field}
            error={Boolean(errorMessage)}
            errorMessage={errorMessage}
            onValueChange={field.onChange}
            {...rest}
          />
        )}
      />
    </div>
  );
}
