import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { Button } from '@tremor/react';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import {
  Wrapper,
  DataTable,
  adminColumns,
  QuickSelect,
  CreateAdminModal,
  UpdateAdminModal,
  DeleteModal,
} from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import { getUsersQueryKey, useDeleteUser, useUsers } from '@/api';
import { type User, UserRole } from '@/api/model';

export const Route = createFileRoute('/_authenticated/admins/')({
  component: Admins,
});

function Admins() {
  const queryClient = useQueryClient();
  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [role, setRole] = useState<UserRole>();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['name'],
    extraQueryParameters: {
      roleIn: role ? [role] : ['admin', 'super_admin'],
    },
  });

  const { data } = useUsers(queryParameters, queryOptions);
  const { users = [], meta } = data ?? {};

  const { mutateAsync: deleteAdmin } = useDeleteUser({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getUsersQueryKey(),
        });
      },
    },
  });

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<User>();

  const onDelete = async () => {
    if (!selectedAdmin) return;

    await deleteAdmin({ id: selectedAdmin.id });
    setOpenDeleteModal(false);
    toast.success('Admin deleted successfully!');
  };

  return (
    <Wrapper
      header='Admins'
      actions={
        <Button
          variant='secondary'
          onClick={() => {
            setOpenCreateModal(true);
          }}
        >
          Add new
        </Button>
      }
      extraActions={
        <QuickSelect
          selected={role}
          setSelected={setRole}
          options={[undefined, UserRole.admin, UserRole.super_admin]}
        />
      }
      onSearch={setSearchQuery}
    >
      <DataTable
        data={users}
        columns={adminColumns({
          onEdit(user) {
            setSelectedAdmin(user);
            setOpenUpdateModal(true);
          },
          onDelete(user) {
            setSelectedAdmin(user);
            setOpenDeleteModal(true);
          },
        })}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
      <CreateAdminModal show={openCreateModal} showModal={setOpenCreateModal} />
      <UpdateAdminModal
        admin={selectedAdmin}
        show={openUpdateModal}
        showModal={setOpenUpdateModal}
      />
      <DeleteModal
        type='admin'
        show={openDeleteModal}
        showModal={setOpenDeleteModal}
        onDelete={onDelete}
      />
    </Wrapper>
  );
}
