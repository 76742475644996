import { RouterProvider } from '@tanstack/react-router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';
import { Flowbite } from 'flowbite-react';

// eslint-disable-next-line import/no-unassigned-import
import 'flowbite';

import { router } from './router';

import { useAuth, AuthProvider } from '@/hooks';
import { flowbiteTheme } from '@/theme';

import '~/application.tailwind.css';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

function InnerApp() {
  const auth = useAuth();
  return <RouterProvider router={router} context={{ auth, queryClient }} />;
}

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Flowbite theme={{ theme: flowbiteTheme }}>
          <ToastContainer />
          <InnerApp />
        </Flowbite>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
