import { Dialog, DialogPanel, Button } from '@tremor/react';

export function ConfirmModal({
  description,
  show,
  showModal,
  onConfirm,
  onCancel,
}: {
  readonly description?: string;
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onConfirm: () => void;
  readonly onCancel?: () => void;
}) {
  return (
    <Dialog
      open={show}
      onClose={() => {
        showModal(false);
        onCancel?.();
      }}
    >
      <DialogPanel className='overflow-visible'>
        <h3 className='text-lg font-semibold text-gray-900 dark:text-white'>
          Are you sure?
        </h3>
        {description ? <p className='mt-2'>{description}</p> : null}
        <div className='flex justify-end mt-4 gap-1'>
          {onCancel && (
            <Button variant='secondary' onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button onClick={onConfirm}>Confirm</Button>
        </div>
      </DialogPanel>
    </Dialog>
  );
}
