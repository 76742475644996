import { lazy, Suspense } from 'react';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { type QueryClient } from '@tanstack/react-query';

import { type AuthContextType } from '@/hooks';

const TanStackRouterDevtools =
  // eslint-disable-next-line n/prefer-global/process
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : lazy(async () =>
        // Lazy load in development
        // eslint-disable-next-line promise/prefer-await-to-then
        import('@tanstack/router-devtools').then((resource) => ({
          default: resource.TanStackRouterDevtools,
        })),
      );

type RouterContext = {
  auth: AuthContextType;
  queryClient: QueryClient;
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: Root,
});

function Root() {
  return (
    <>
      <Outlet />
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  );
}
