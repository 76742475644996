import { type PropsWithChildren, type ReactNode } from 'react';
import { Link } from '@tanstack/react-router';
import { TextInput } from '@tremor/react';
import { Search, ChevronLeft } from 'lucide-react';

export function Header({
  children,
  backLink,
  actions,
  extraActions,
  onBack,
  onSearch,
}: PropsWithChildren<{
  readonly backLink?: string;
  readonly actions?: ReactNode;
  readonly extraActions?: ReactNode;
  readonly onBack?: () => void;
  readonly onSearch?: (value: string) => void;
}>) {
  return (
    <div>
      <div className='flex place-content-between mb-4'>
        <div className='flex content-center'>
          {backLink && (
            <Link
              to={backLink}
              className='flex items-center text-gray-900 hover:text-gray-500 dark:text-gray-200 mr-1'
            >
              <ChevronLeft size={26} />
            </Link>
          )}
          {onBack && (
            <Link
              className='flex items-center text-gray-900 hover:text-gray-500 dark:text-gray-200 mr-1'
              onClick={onBack}
            >
              <ChevronLeft size={26} />
            </Link>
          )}
          <h1 className='text-3xl text-gray-900 dark:text-gray-200'>
            {children}
          </h1>
          {Boolean(actions) && <div className='flex ml-4'>{actions}</div>}
        </div>
        {Boolean(onSearch) && (
          <div className='max-w-sm'>
            <TextInput
              icon={Search}
              placeholder='Search...'
              onChange={(event) => {
                onSearch?.(event.target.value);
              }}
            />
          </div>
        )}
      </div>
      {Boolean(extraActions) && (
        <div className='flex justify-end gap-4 my-4'>{extraActions}</div>
      )}
    </div>
  );
}
