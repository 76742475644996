import { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import starterKit from '@tiptap/starter-kit';

import { MenuBar } from './editor-menu-bar';

type EditorProperties = {
  readonly content?: string;
  readonly onChange?: (content: string) => void;
};

export function Editor({ content, onChange }: EditorProperties) {
  const editor = useEditor({
    extensions: [
      starterKit.configure({
        blockquote: false,
        code: false,
        codeBlock: false,
      }),
    ],
    content,
    editorProps: {
      attributes: {
        class:
          'prose dark:prose-invert max-w-none focus:outline-none p-4 bg-white dark:bg-gray-800 h-96 overflow-y-auto',
      },
    },
  });

  useEffect(() => {
    if (!editor) return;

    editor.on('update', () => {
      if (editor && onChange) {
        onChange(editor.getHTML());
      }
    });
  }, [editor, onChange]);

  if (!editor) return null;

  return (
    <div className='bg-white rounded-lg border border-gray-200'>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </div>
  );
}
