/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Preowned The Studio backend
 * OpenAPI spec version: v1
 */

export enum UserRole {
  super_admin = 'super_admin',
  admin = 'admin',
  user = 'user',
}
