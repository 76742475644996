type LabelProperties = {
  readonly htmlFor?: string;
  readonly value: string;
};

export function Label({ htmlFor, value }: LabelProperties) {
  return (
    <label
      htmlFor={htmlFor}
      className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
    >
      {value}
    </label>
  );
}
