import {
  Select as TremorSelect,
  SelectItem as TremorSelectItem,
  type SelectProps,
} from '@tremor/react';
import {
  Controller,
  type FieldValues,
  type Control,
  type Path,
} from 'react-hook-form';

import { Label } from '../label';

export type SelectProperties<T extends FieldValues> = Omit<
  SelectProps,
  'children'
> & {
  readonly control: Control<T>;
  readonly name: Path<T>;
  readonly options: ReadonlyArray<{
    readonly value: string;
    readonly label: string;
  }>;
  readonly label?: string;
  readonly errorMessage?: string;
};

export function Select<T extends FieldValues>({
  control,
  name,
  options,
  label,
  errorMessage,
  ...rest
}: SelectProperties<T>) {
  return (
    <div className='w-full'>
      {label && (
        <div className='mb-2 block'>
          <Label htmlFor={name} value={label} />
        </div>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TremorSelect
            {...field}
            error={Boolean(errorMessage)}
            errorMessage={errorMessage}
            onValueChange={field.onChange}
            {...rest}
          >
            {options.map((option) => (
              <TremorSelectItem key={option.value} value={option.value}>
                {option.label}
              </TremorSelectItem>
            ))}
          </TremorSelect>
        )}
      />
    </div>
  );
}
