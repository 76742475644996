import { Link } from '@tanstack/react-router';
import { Badge, Dropdown } from 'flowbite-react';
import { Button, Icon } from '@tremor/react';
import { createColumnHelper } from '@tanstack/react-table';
import { MoreVertical, Check } from 'lucide-react';

import { DataTableColumnHeader } from '../data-table';

import type { User, UserComplianceStatus } from '@/api/model';
import { formatDate, formatEnum, userComplianceStatusColor } from '@/utils';

const columnHelper = createColumnHelper<User>();
export const userColumns = ({
  onEdit,
  onDelete,
  onAddExpert,
  onRemoveExpert,
}: {
  onEdit: (user: User) => void;
  onDelete: (user: User) => void;
  onAddExpert: (user: User) => void;
  onRemoveExpert: (user: User) => void;
}) => [
  columnHelper.accessor((user) => user.name, {
    id: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Name' />
    ),
    cell: (properties) => (
      <Link
        to='/users/$userId'
        params={{
          userId: properties.row.original.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        <span className='ml-2 self-center font-semibold'>
          {properties.getValue()}
        </span>
      </Link>
    ),
  }),
  columnHelper.accessor((user) => user.email, {
    id: 'email',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Email' />
    ),
    cell: (properties) => (
      <div className='flex items-center'>
        {properties.getValue()}
        {properties.row.original.verified && (
          <Icon icon={Check} variant='simple' size='md' />
        )}
      </div>
    ),
  }),
  columnHelper.accessor((user) => user.complianceStatus as string, {
    id: 'complianceStatus',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Compliance status' />
    ),
    cell: (properties) =>
      Boolean(properties.getValue()) && (
        <div className='flex'>
          <Badge
            size='sm'
            color={userComplianceStatusColor(
              properties.getValue() as UserComplianceStatus,
            )}
          >
            {formatEnum(properties.getValue())}
          </Badge>
        </div>
      ),
  }),
  columnHelper.accessor(
    (user) => user.createdAt && formatDate(user.createdAt),
    {
      id: 'createdAt',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Registered at' />
      ),
    },
  ),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <div className='flex justify-end gap-1 relative'>
        <Link
          to='/users/$userId/advertisements'
          params={{
            userId: row.original.id.toString(),
          }}
        >
          <Button variant='secondary'>View ads</Button>
        </Link>
        <Link
          to='/users/$userId/orders'
          params={{
            userId: row.original.id.toString(),
          }}
        >
          <Button variant='secondary'>View orders</Button>
        </Link>
        <Dropdown
          label=''
          renderTrigger={() => (
            <Button variant='secondary' className='px-2' icon={MoreVertical} />
          )}
          dismissOnClick={false}
          placement='bottom'
        >
          <Dropdown.Item
            onClick={() => {
              onEdit(row.original);
            }}
          >
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              onDelete(row.original);
            }}
          >
            Delete
          </Dropdown.Item>
          {row.original.expert ? (
            <Dropdown.Item
              onClick={() => {
                onRemoveExpert(row.original);
              }}
            >
              Remove expert status
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              onClick={() => {
                onAddExpert(row.original);
              }}
            >
              Add expert status
            </Dropdown.Item>
          )}
        </Dropdown>
      </div>
    ),
  }),
];
