import { useLayoutEffect } from 'react';
import { createFileRoute, useRouter } from '@tanstack/react-router';
import { Alert } from 'flowbite-react';
import { Button, Card } from '@tremor/react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';

import { TextInput } from '@/components';
import { useAuth } from '@/hooks';
import logo from '@/assets/images/logo.svg';

export const Route = createFileRoute('/sign_in')({
  validateSearch: z.object({
    redirect: z.string().optional(),
  }),
  component: SignIn,
});

const schema = z.object({
  email: z.string().email(),
  password: z.string(),
});

function SignIn() {
  const router = useRouter();
  const auth = useAuth();
  const search = Route.useSearch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const onSubmit = handleSubmit(({ email, password }) => {
    auth.signIn(email, password);
  });

  useLayoutEffect(() => {
    if (auth.authenticated && search.redirect) {
      router.history.push(search.redirect);
    } else if (auth.authenticated) {
      router.history.push('/');
    }
  }, [auth.authenticated, search.redirect, router.history]);

  return (
    <div className='bg-gray-50 dark:bg-gray-900'>
      <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>
        <span className='flex items-center mb-6'>
          <img className='w-60' src={logo} alt='logo' />
        </span>
        <Card className='w-full md:mt-0 sm:max-w-md xl:p-0'>
          <div className='p-4'>
            <form className='flex flex-col gap-4'>
              {auth.error?.status === 401 && (
                <Alert color='failure'>Invalid email or password</Alert>
              )}
              <TextInput
                name='email'
                type='email'
                placeholder='Email'
                control={control}
                errorMessage={errors.email?.message}
              />
              <TextInput
                name='password'
                type='password'
                placeholder='Password'
                control={control}
                errorMessage={errors.password?.message}
              />
              <Button type='submit' onClick={onSubmit}>
                Sign in
              </Button>
            </form>
          </div>
        </Card>
      </div>
    </div>
  );
}
