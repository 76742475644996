/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';
import { createFileRoute, Link, useRouter } from '@tanstack/react-router';
import { Badge, Table, Avatar, Dropdown } from 'flowbite-react';
import { Button, Dialog, DialogPanel } from '@tremor/react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useSuspenseQuery } from '@tanstack/react-query';
import {
  DndContext,
  DragOverlay,
  closestCenter,
  MouseSensor,
  useSensor,
  useSensors,
  type DragStartEvent,
  type DragEndEvent,
  type UniqueIdentifier,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  Download,
  Trash2,
  ImageUp,
  ChevronLeft,
  ChevronRight,
  ChevronDown,
} from 'lucide-react';

import {
  Wrapper,
  SortableItem,
  Card,
  TextInput,
  PriceInput,
  Textarea,
  NumberInput,
  Select,
  SearchSelect,
  ConfirmModal,
  UpdateItemStatusModal,
  FeatureItemModal,
  RefundOrderModal,
  UpdateOrderModal,
  PayoutOrderModal,
  DelayPayoutOrderModal,
} from '@/components';
import {
  getItemQueryKey,
  getItemSuspenseQueryOptions,
  useItemBrands,
  useItemCategories,
  useUpdateItem,
  useItemImagePosition,
  useCreateItemImage,
  useDeleteItemImage,
} from '@/api';
import {
  formatCurrency,
  formatDateTime,
  formatEnum,
  handleFormError,
  itemStatusColor,
} from '@/utils';
import { useQuery } from '@/hooks';
import { ItemColor, ItemCondition, OrderTransactionStatus } from '@/api/model';

const schema = z.object({
  name: z.string(),
  description: z.string(),
  price: z.coerce.number(),
  dimensions: z.object({
    width: z.coerce.number(),
    height: z.coerce.number(),
    depth: z.coerce.number(),
  }),
  year: z.coerce.number(),
  chaindrop: z.coerce.number().nullable().optional(),
  categoryId: z.string(),
  brandId: z.string(),
  color: z.nativeEnum(ItemColor),
  condition: z.nativeEnum(ItemCondition),
  receipt: z.string(),
  comment: z.string().optional(),
});

const itemQueryOptions = (advertisementId: number) =>
  getItemSuspenseQueryOptions(advertisementId);

export const Route = createFileRoute(
  '/_authenticated/advertisements/$advertisementId',
)({
  loader: async ({ context: { queryClient }, params: { advertisementId } }) =>
    queryClient.ensureQueryData(itemQueryOptions(Number(advertisementId))),
  component: ShowAdvertisement,
});

function ShowAdvertisement() {
  const { advertisementId } = Route.useParams();
  const router = useRouter();
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { data: item } = useSuspenseQuery(
    itemQueryOptions(Number(advertisementId)),
  );
  const { queryParameters } = useQuery({
    extraQueryParameters: {
      items: 100,
    },
  });
  const { data: categories } = useItemCategories(queryParameters);
  const { itemCategories = [] } = categories ?? {};
  const { data: brands } = useItemBrands(queryParameters);
  const { itemBrands = [] } = brands ?? {};

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const { mutateAsync } = useUpdateItem({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getItemQueryKey(Number(item.id)),
        });
      },
    },
  });

  const { mutateAsync: updatePosition } = useItemImagePosition({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getItemQueryKey(Number(item.id)),
        });
      },
    },
  });

  const { mutateAsync: createImage } = useCreateItemImage({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getItemQueryKey(Number(item.id)),
        });
      },
    },
  });

  const { mutateAsync: deleteImage } = useDeleteItemImage({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getItemQueryKey(Number(item.id)),
        });
      },
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: item.name,
      description: item.description,
      price: item.price.cents / 100,
      dimensions: {
        width: item.dimensions.width / 10,
        height: item.dimensions.height / 10,
        depth: item.dimensions.depth / 10,
      },
      chaindrop: item.chaindrop && item.chaindrop / 10,
      year: item.year,
      categoryId: item.category.id.toString(),
      brandId: item.brand.id.toString(),
      color: item.color,
      condition: item.condition,
      receipt: item.receipt ? 'true' : 'false',
      comment: item.comment ?? '',
    },
  });
  const [isEditing, setIsEditing] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openFeatureModal, setOpenFeatureModal] = useState(false);
  const [openUpdateOrderModal, setOpenUpdateOrderModal] = useState(false);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [openPayoutModal, setOpenPayoutModal] = useState(false);
  const [openDelayPayoutModal, setOpenDelayPayoutModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmDeleteImageModal, setOpenConfirmDeleteImageModal] =
    useState(false);
  const [selectedImage, setSelectedImage] = useState<number>();
  const [viewImage, setViewImage] = useState(false);
  const [images, setImages] = useState(item.images?.map((image) => image));
  const [activeId, setActiveId] = useState<UniqueIdentifier>();
  const [items, setItems] = useState(
    item.images?.map((image) => image.id!) ?? [],
  );

  useEffect(() => {
    setImages(item.images?.map((image) => image));
    setItems(item.images?.map((image) => image.id!) ?? []);
  }, [item.images]);

  const renderAuditStatus = (status: string | string[] | undefined) => {
    if (!status) return null;

    if (Array.isArray(status)) {
      if (status.at(-1) === undefined) return null;

      return formatEnum(status.at(-1)!);
    }

    return formatEnum(status);
  };

  const onImageSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files) return;

    await createImage({
      data: {
        item_id: item.id,
        image: files[0],
      },
    });
  };

  const onSave = handleSubmit(async ({ dimensions, ...data }) => {
    try {
      const response = await mutateAsync({
        id: item.id,
        data: {
          ...data,
          'category_id': Number(data.categoryId),
          'brand_id': Number(data.brandId),
          'color': data.color,
          'condition': data.condition,
          'receipt': data.receipt === 'true',
          'offers_allowed': item.offersAllowed,
          'images': [],
          'dimensions[width]': dimensions.width * 10,
          'dimensions[height]': dimensions.height * 10,
          'dimensions[depth]': dimensions.depth * 10,
          'chaindrop': data.chaindrop && data.chaindrop * 10,
        },
      });
      reset(
        {
          name: response.name,
          description: response.description,
          price: response.price.cents / 100,
          dimensions: {
            width: response.dimensions.width / 10,
            height: response.dimensions.height / 10,
            depth: response.dimensions.depth / 10,
          },
          chaindrop: response.chaindrop && response.chaindrop / 10,
          year: response.year,
          categoryId: response.category.id.toString(),
          brandId: response.brand.id.toString(),
          color: response.color,
          condition: response.condition,
          receipt: response.receipt ? 'true' : 'false',
          comment: response.comment ?? '',
        },
        { keepValues: false },
      );
      setIsEditing(false);
      toast.success('Advertisement updated successfully!');
    } catch (error) {
      handleFormError(error, setError);
    }
  });

  const onCancel = () => {
    setIsEditing(false);
    reset();
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;

    setActiveId(active.id);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id as number);
        const newIndex = items.indexOf(over?.id as number);

        return arrayMove(items, oldIndex, newIndex);
      });

      if (images?.length) {
        setImages((images) => {
          const oldIndex = images!.findIndex((image) => image.id === active.id);
          const newIndex = images!.findIndex((image) => image.id === over?.id);

          return arrayMove(images!, oldIndex, newIndex);
        });
      }

      await updatePosition({
        id: Number(active.id),
        data: {
          position: items.indexOf(over?.id as number),
        },
      });
    }

    setActiveId(undefined);
  };

  const renderPricing = () => {
    return (
      <Table striped>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Platform fee</Table.Cell>
            <Table.Cell>
              {formatCurrency(
                item.platformFee.cents,
                item.platformFee.currencyIso,
              )}
            </Table.Cell>
          </Table.Row>
          {item.protectionFee && (
            <Table.Row>
              <Table.Cell>Protection fee</Table.Cell>
              <Table.Cell>
                {formatCurrency(
                  item.protectionFee.cents,
                  item.protectionFee.currencyIso,
                )}
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>Seller price</Table.Cell>
            <Table.Cell>
              {formatCurrency(
                item.price.cents - item.platformFee.cents,
                item.platformFee.currencyIso,
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Buyer price</Table.Cell>
            <Table.Cell>
              {formatCurrency(item.price.cents, item.platformFee.currencyIso)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  const renderAudits = () => {
    const audits = item.audits?.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    return (
      <Table striped>
        <Table.Body>
          {audits?.length ? (
            audits.map((audit) => (
              <Table.Row key={audit.id}>
                <Table.Cell>{formatDateTime(audit.createdAt)}</Table.Cell>
                <Table.Cell>
                  {audit.auditedChanges.hasOwnProperty(
                    'expert_review_request_sent_at',
                  ) &&
                    audit.auditedChanges.expert_review_request_sent_at &&
                    'Expert review request sent'}
                  {audit.auditedChanges.hasOwnProperty('status') &&
                    renderAuditStatus(
                      audit.auditedChanges.status as string | string[],
                    )}
                  {audit.auditedChanges.hasOwnProperty('transaction_status') &&
                    renderAuditStatus(
                      audit.auditedChanges.transaction_status as
                        | string
                        | string[],
                    )}
                  {audit.auditedChanges.hasOwnProperty('parcel_status') &&
                    renderAuditStatus(
                      audit.auditedChanges.parcel_status as string | string[],
                    )}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell>No history</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  };

  const renderFeatured = () => {
    const features = item.features?.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    return (
      <Table striped>
        <Table.Body>
          {features?.length ? (
            features.map((feature) => (
              <Table.Row key={feature.id}>
                <Table.Cell>{formatDateTime(feature.createdAt)}</Table.Cell>
                <Table.Cell>
                  {formatCurrency(
                    feature.price.cents,
                    feature.price.currencyIso,
                  )}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell>No highlights</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  };

  const renderImages = () => {
    if (!images) return null;

    return (
      <>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={items}
            strategy={horizontalListSortingStrategy}
          >
            {images.map(
              (image) =>
                image.image.versions.small?.url && (
                  <SortableItem key={image.id} id={image.id!}>
                    <div className='relative'>
                      <Avatar
                        bordered
                        img={image.image.versions.small.url}
                        size='xl'
                        onClick={() => {
                          setViewImage(true);
                          setSelectedImage(image.id);
                        }}
                      />
                      {image.image.versions.large?.url ? (
                        <Button
                          variant='secondary'
                          color='blue'
                          icon={Download}
                          className='absolute top-1 left-1 cursor-pointer border-0 p-1'
                          onClick={() => {
                            window.open(
                              image.image.versions.large!.url,
                              '_blank',
                            );
                          }}
                        />
                      ) : null}
                      <Button
                        variant='secondary'
                        color='red'
                        icon={Trash2}
                        className='absolute top-1 right-1 cursor-pointer border-0 p-1 z-1'
                        onClick={() => {
                          setSelectedImage(image.id);
                          setOpenConfirmDeleteImageModal(true);
                        }}
                      />
                    </div>
                  </SortableItem>
                ),
            )}
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <Avatar
                bordered
                img={
                  images.find((image) => image.id! === activeId)!.image.versions
                    .small!.url
                }
                size='xl'
              />
            ) : null}
          </DragOverlay>
        </DndContext>
        {Boolean(selectedImage) && viewImage && (
          <Dialog
            static
            open={Boolean(selectedImage) && viewImage}
            className='z-[100]'
            onClose={() => {
              setViewImage(false);
              setSelectedImage(undefined);
            }}
          >
            <DialogPanel className='max-w-sm w-auto p-0'>
              <img
                src={
                  images.find((image) => image.id! === selectedImage)!.image
                    .versions.large!.url
                }
              />
              <div className='flex justify-between p-2'>
                <Button
                  variant='secondary'
                  icon={ChevronLeft}
                  className='px-2'
                  onClick={() => {
                    const currentIndex = images.findIndex(
                      (image) => image.id === selectedImage,
                    );
                    const previousIndex = currentIndex - 1;
                    setSelectedImage(
                      images[
                        previousIndex < 0 ? images.length - 1 : previousIndex
                      ].id,
                    );
                  }}
                />
                <Button
                  variant='secondary'
                  icon={ChevronRight}
                  className='px-2'
                  onClick={() => {
                    const currentIndex = images.findIndex(
                      (image) => image.id === selectedImage,
                    );
                    const nextIndex = currentIndex + 1;
                    setSelectedImage(
                      images[nextIndex >= images.length ? 0 : nextIndex].id,
                    );
                  }}
                />
              </div>
            </DialogPanel>
          </Dialog>
        )}
      </>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div className='flex flex-wrap items-center gap-6 pb-4'>
          {renderImages()}
          <label
            htmlFor='fileUpload'
            className='flex items-center justify-center rounded p-1 ring-2 ring-gray-300 dark:ring-gray-500 bg-gray-100 h-36 w-36 cursor-pointer hover:ring-primary hover:bg-primary-200'
          >
            <input
              id='fileUpload'
              type='file'
              accept='image/png, image/jpeg, image/heic, image/heif, image/webp'
              className='hidden'
              onChange={onImageSelect}
            />
            <ImageUp />
          </label>
        </div>
        <div className='flex gap-4'>
          <div className='flex flex-auto flex-col gap-4'>
            {isEditing ? (
              <Card title='Basic info'>
                <div className='flex gap-4'>
                  <TextInput
                    name='name'
                    label='Name'
                    control={control}
                    errorMessage={errors.name?.message}
                  />
                  <PriceInput
                    name='price'
                    label='Price'
                    control={control}
                    errorMessage={errors.price?.message}
                  />
                </div>
                <div className='flex gap-4 mt-4'>
                  <SearchSelect
                    name='categoryId'
                    label='Category'
                    options={itemCategories.map((category) => ({
                      value: category.id.toString(),
                      label: category.parentName
                        ? `${category.parentName} > ${category.name}`
                        : category.name,
                    }))}
                    control={control}
                    errorMessage={errors.categoryId?.message}
                    enableClear={false}
                  />
                  <SearchSelect
                    name='brandId'
                    label='Brand'
                    options={itemBrands.map((brand) => ({
                      value: brand.id.toString(),
                      label: brand.name,
                    }))}
                    control={control}
                    errorMessage={errors.brandId?.message}
                    enableClear={false}
                  />
                </div>
              </Card>
            ) : (
              <div>
                <p className='text-xs text-gray-400'>
                  {item.category.name.toUpperCase()}
                </p>
                <div className='flex justify-between'>
                  <p className='text-xl'>{item.name}</p>
                  <p className='text-xl text-primary'>
                    {formatCurrency(item.price.cents, item.price.currencyIso)}
                  </p>
                </div>
                <p className='text-sm text-gray-400'>{item.brand.name}</p>
              </div>
            )}
            <Card title='Description'>
              {isEditing ? (
                <Textarea
                  name='description'
                  control={control}
                  errorMessage={errors.description?.message}
                />
              ) : (
                item.description
              )}
            </Card>
            <Card title='Dimensions'>
              <div className='flex gap-6'>
                <span>
                  <p className='text-xs text-gray-400'>Width</p>
                  {isEditing ? (
                    <NumberInput
                      name='dimensions.width'
                      placeholder='Width'
                      control={control}
                      errorMessage={errors.dimensions?.width?.message}
                    />
                  ) : (
                    <p className='text-lg'>{item.dimensions.width / 10}cm</p>
                  )}
                </span>
                <span>
                  <p className='text-xs text-gray-400'>Height</p>
                  {isEditing ? (
                    <NumberInput
                      name='dimensions.height'
                      placeholder='Height'
                      control={control}
                      errorMessage={errors.dimensions?.height?.message}
                    />
                  ) : (
                    <p className='text-lg'>{item.dimensions.height / 10}cm</p>
                  )}
                </span>
                <span>
                  <p className='text-xs text-gray-400'>Depth</p>
                  {isEditing ? (
                    <NumberInput
                      name='dimensions.depth'
                      placeholder='Depth'
                      control={control}
                      errorMessage={errors.dimensions?.depth?.message}
                    />
                  ) : (
                    <p className='text-lg'>{item.dimensions.depth / 10}cm</p>
                  )}
                </span>
                <span>
                  <p className='text-xs text-gray-400'>Chaindrop</p>
                  {isEditing ? (
                    <NumberInput
                      name='chaindrop'
                      placeholder='Chaindrop'
                      control={control}
                      errorMessage={errors.chaindrop?.message}
                    />
                  ) : item.chaindrop ? (
                    <p className='text-lg'>{item.chaindrop / 10}cm</p>
                  ) : (
                    '-'
                  )}
                </span>
              </div>
            </Card>
            <Card title='Extra data' padding={false}>
              <Table striped>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Color</Table.Cell>
                    <Table.Cell>
                      {isEditing ? (
                        <Select
                          name='color'
                          control={control}
                          options={Object.keys(ItemColor).map((color) => ({
                            value: color,
                            label: formatEnum(color),
                          }))}
                          errorMessage={errors.color?.message}
                        />
                      ) : (
                        formatEnum(item.color)
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Condition</Table.Cell>
                    <Table.Cell>
                      {isEditing ? (
                        <Select
                          name='condition'
                          control={control}
                          options={Object.keys(ItemCondition).map(
                            (condition) => ({
                              value: condition,
                              label: formatEnum(condition),
                            }),
                          )}
                          errorMessage={errors.condition?.message}
                        />
                      ) : (
                        formatEnum(item.condition)
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Receipt Present</Table.Cell>
                    <Table.Cell>
                      {isEditing ? (
                        <Select
                          name='receipt'
                          control={control}
                          options={[
                            { value: 'true', label: 'Yes' },
                            { value: 'false', label: 'No' },
                          ]}
                          errorMessage={errors.receipt?.message}
                        />
                      ) : item.receipt ? (
                        'Yes'
                      ) : (
                        'No'
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Year</Table.Cell>
                    <Table.Cell>
                      {isEditing ? (
                        <NumberInput
                          name='year'
                          control={control}
                          errorMessage={errors.year?.message}
                        />
                      ) : (
                        item.year
                      )}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card>
          </div>
          <div className='flex flex-1 flex-col gap-4'>
            <Card title='Pricing' padding={false}>
              {renderPricing()}
            </Card>
            <Card title='History' padding={false}>
              {renderAudits()}
            </Card>
            <Card title='Highlights' padding={false}>
              {renderFeatured()}
            </Card>
            <Card padding={false}>
              <Table striped>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan={3}>
                      <h5 className='font-semibold'>Seller</h5>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      {item.seller && (
                        <Link
                          to='/users/$userId'
                          params={{
                            userId: item.seller?.id.toString(),
                          }}
                          className='hover:text-primary'
                        >
                          {item.seller?.name}
                        </Link>
                      )}
                    </Table.Cell>
                    <Table.Cell>{item.seller?.email}</Table.Cell>
                    <Table.Cell>{item.seller?.phoneNumber}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colSpan={3}>
                      <h5 className='font-semibold'>Expert</h5>
                    </Table.Cell>
                  </Table.Row>
                  {item.expert ? (
                    <Table.Row>
                      <Table.Cell>{item.expert.name}</Table.Cell>
                      <Table.Cell>{item.expert?.email}</Table.Cell>
                      <Table.Cell>{item.expert?.phoneNumber}</Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={3}>No expert</Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Card>
            <Card title='Comment'>
              {isEditing ? (
                <Textarea
                  name='comment'
                  control={control}
                  errorMessage={errors.comment?.message}
                />
              ) : (
                item.comment
              )}
            </Card>
          </div>
        </div>
      </>
    );
  };

  return (
    <Wrapper
      header={item?.name}
      actions={
        <Badge size='sm' color={itemStatusColor(item.status)}>
          {formatEnum(item.status)}
        </Badge>
      }
      extraActions={
        isEditing ? (
          <>
            <Button variant='secondary' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                setOpenConfirmModal(true);
              }}
            >
              Save
            </Button>
          </>
        ) : (
          <>
            <Dropdown
              label=''
              renderTrigger={() => (
                <Button
                  variant='secondary'
                  icon={ChevronDown}
                  iconPosition='right'
                >
                  Extra
                </Button>
              )}
              dismissOnClick={false}
              placement='bottom'
            >
              {Boolean(item.order) && (
                <>
                  {item.order?.transactionStatus ===
                    OrderTransactionStatus.reserved && (
                    <Dropdown.Item
                      onClick={() => {
                        setOpenPayoutModal(true);
                      }}
                    >
                      Payout order
                    </Dropdown.Item>
                  )}
                  {item.order?.transactionStatus ===
                    OrderTransactionStatus.reserved && (
                    <Dropdown.Item
                      onClick={() => {
                        setOpenDelayPayoutModal(true);
                      }}
                    >
                      Delay payout order
                    </Dropdown.Item>
                  )}
                  {(item.order?.transactionStatus ===
                    OrderTransactionStatus.completed ||
                    item.order?.transactionStatus ===
                      OrderTransactionStatus.reserved) && (
                    <Dropdown.Item
                      onClick={() => {
                        setOpenRefundModal(true);
                      }}
                    >
                      Refund order
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      setOpenUpdateOrderModal(true);
                    }}
                  >
                    Update order
                  </Dropdown.Item>
                </>
              )}
              <Dropdown.Item
                onClick={() => {
                  setOpenFeatureModal(true);
                }}
              >
                Highlight advertisement
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setOpenStatusModal(true);
                }}
              >
                Change status
              </Dropdown.Item>
            </Dropdown>
            <Button
              variant='secondary'
              onClick={() => {
                setIsEditing(true);
              }}
            >
              Edit
            </Button>
          </>
        )
      }
      onBack={() => {
        router.history.back();
      }}
    >
      {renderContent()}
      <UpdateOrderModal
        item={item}
        order={item.order}
        show={openUpdateOrderModal}
        showModal={setOpenUpdateOrderModal}
      />
      <PayoutOrderModal
        item={item}
        order={item.order}
        show={openPayoutModal}
        showModal={setOpenPayoutModal}
      />
      <DelayPayoutOrderModal
        item={item}
        order={item.order}
        show={openDelayPayoutModal}
        showModal={setOpenDelayPayoutModal}
      />
      <RefundOrderModal
        item={item}
        order={item.order}
        show={openRefundModal}
        showModal={setOpenRefundModal}
      />
      <FeatureItemModal
        item={item}
        show={openFeatureModal}
        showModal={setOpenFeatureModal}
      />
      <UpdateItemStatusModal
        item={item}
        show={openStatusModal}
        showModal={setOpenStatusModal}
      />
      <ConfirmModal
        show={openConfirmModal}
        showModal={setOpenConfirmModal}
        onConfirm={async () => {
          setOpenConfirmModal(false);
          await onSave();
        }}
        onCancel={() => {
          setOpenConfirmModal(false);
          onCancel();
        }}
      />
      <ConfirmModal
        show={openConfirmDeleteImageModal}
        showModal={setOpenConfirmDeleteImageModal}
        onConfirm={async () => {
          setOpenConfirmDeleteImageModal(false);
          if (selectedImage) {
            await deleteImage({ id: selectedImage });
          }
        }}
        onCancel={() => {
          setOpenConfirmDeleteImageModal(false);
        }}
      />
    </Wrapper>
  );
}
