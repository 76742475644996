import { keepPreviousData } from '@tanstack/react-query';
import type { PaginationState, SortingState } from '@tanstack/react-table';
import { snakeCase } from 'lodash';

import { snakeCaseObject } from '@/utils';

/**
 * Options for the useQuery hook.
 */
type QueryOptions = {
  pagination?: PaginationState;
  sorting?: SortingState;
  searchQuery?: string | undefined;
  searchFields?: string[];
  extraQueryParameters?: Record<string, any>;
};

/**
 * Custom hook for handling queries with pagination, sorting, and search.
 * @param {QueryOptions} options - Options for the hook.
 * @returns {{ queryParameters: Object, queryOptions: { query: { placeholderData: typeof keepPreviousData } } }} - Query parameters and options.
 */
export function useQuery({
  pagination,
  sorting,
  searchQuery,
  searchFields,
  extraQueryParameters,
}: QueryOptions): {
  queryParameters: {
    page: number;
    q: Record<string, unknown>;
  };
  queryOptions: { query: { placeholderData: typeof keepPreviousData } };
} {
  // Generate sort keys for sorting parameter
  const sortKeys = (sorting ?? []).map(
    (sort) => `${snakeCase(sort.id)} ${sort.desc ? 'desc' : 'asc'}`,
  );

  // Generate key for search fields
  const searchFieldsKey = searchFields?.length
    ? `${searchFields.join('_or_')}_cont`
    : 'name_cont';

  const removeBracketsFromKeys = (inputObject: Record<string, unknown>) => {
    const resultObject: Record<string, unknown> = {};
    for (const key in inputObject) {
      // eslint-disable-next-line no-prototype-builtins
      if (inputObject.hasOwnProperty(key)) {
        const newKey = key.replaceAll(/q\[|]/g, '');
        resultObject[newKey] = inputObject[key];
      }
    }

    return resultObject;
  };

  // Generate query parameters for API call
  const queryParameters = {
    page: (pagination?.pageIndex ?? 0) + 1,
    q: Object.fromEntries(
      Object.entries({
        s: sortKeys.length > 0 ? sortKeys.join(' ') : undefined,
        [snakeCase(searchFieldsKey)]: searchQuery,
        ...(extraQueryParameters &&
          snakeCaseObject(removeBracketsFromKeys(extraQueryParameters))),
      }).filter(([, value]) => value !== undefined),
    ),
  };

  const queryOptions = {
    query: { placeholderData: keepPreviousData },
  };

  return {
    queryParameters,
    queryOptions,
  };
}
