/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Preowned The Studio backend
 * OpenAPI spec version: v1
 */

export enum FinancialPeriod {
  second = 'second',
  minute = 'minute',
  hour = 'hour',
  day = 'day',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year',
  day_of_week = 'day_of_week',
  hour_of_day = 'hour_of_day',
  minute_of_hour = 'minute_of_hour',
  day_of_month = 'day_of_month',
  day_of_year = 'day_of_year',
  month_of_year = 'month_of_year',
}
