/* eslint-disable @typescript-eslint/naming-convention */
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { Modal, TextInput, Select } from '@/components';
import { useCreateUser, getUsersQueryKey } from '@/api';
import { handleFormError } from '@/utils';
import { CreateUserBodyRole } from '@/api/model';

const schema = z.object({
  email: z.string().email(),
  password: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  role: z.nativeEnum(CreateUserBodyRole),
});

export function CreateAdminModal({
  show,
  showModal,
}: {
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const queryClient = useQueryClient();
  const { mutateAsync } = useCreateUser({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getUsersQueryKey(),
        });
      },
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const onSave = handleSubmit(
    async ({ email, password, firstName, lastName, role }) => {
      try {
        await mutateAsync({
          data: {
            email,
            password,
            first_name: firstName,
            last_name: lastName,
            role,
          },
        });
        reset();
        showModal(false);
        toast.success('Admin created successfully!');
      } catch (error) {
        handleFormError(error, setError);
      }
    },
  );

  return (
    <Modal
      title='Create admin'
      show={show}
      onClose={() => {
        showModal(false);
      }}
      onSave={onSave}
    >
      <form className='flex flex-col gap-4'>
        <TextInput
          name='email'
          label='Email'
          placeholder='Email'
          type='email'
          control={control}
          errorMessage={errors.email?.message}
        />
        <TextInput
          name='password'
          type='password'
          label='Password'
          placeholder='Password'
          control={control}
          errorMessage={errors.password?.message}
        />
        <TextInput
          name='firstName'
          label='First name'
          placeholder='First name'
          control={control}
          errorMessage={errors.firstName?.message}
        />
        <TextInput
          name='lastName'
          label='Last name'
          placeholder='Last name'
          control={control}
          errorMessage={errors.lastName?.message}
        />
        <Select
          name='role'
          control={control}
          label='Role'
          errorMessage={errors.role?.message}
          options={
            [
              { value: CreateUserBodyRole.admin, label: 'Admin' },
              { value: CreateUserBodyRole.super_admin, label: 'Super admin' },
            ] as const
          }
        />
      </form>
    </Modal>
  );
}
