import { isAxiosError } from 'axios';
import type { FieldValues, UseFormSetError, Path } from 'react-hook-form';
import { camelCase, capitalize } from 'lodash';

import { type ErrorType } from './api';

import { type Errors } from '@/api/model';

export function isServerError<ResponseType = Errors>(
  error: unknown,
): error is ErrorType<ResponseType> {
  return isAxiosError(error);
}

export const handleFormError = <T extends FieldValues>(
  error: unknown,
  setError: UseFormSetError<T>,
) => {
  if (isServerError(error)) {
    const apiErrors = error.response?.data?.errors;
    if (!apiErrors) throw error;

    for (const errorItem of apiErrors) {
      if (typeof errorItem.id === 'string') {
        setError(camelCase(errorItem.id) as Path<T>, {
          type: 'api',
          message: `${capitalize(errorItem.id.replace('_', ' '))} ${errorItem.detail}`,
        });
      }
    }
  } else {
    throw error;
  }
};
