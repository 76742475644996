import { type PropsWithChildren } from 'react';
import { Dialog, DialogPanel, Divider, Button } from '@tremor/react';

export function Modal({
  children,
  title,
  show,
  large,
  onClose,
  onCancel,
  onSave,
}: PropsWithChildren<{
  readonly title: string;
  readonly show: boolean;
  readonly large?: boolean;
  readonly onClose: () => void;
  readonly onCancel?: () => void;
  readonly onSave: () => void;
}>) {
  return (
    <Dialog open={show} onClose={onClose}>
      <DialogPanel
        className={large ? 'overflow-visible max-w-3xl' : 'overflow-visible'}
      >
        <h3 className='text-lg font-semibold text-gray-900 dark:text-white'>
          {title}
        </h3>
        <Divider className='my-2' />
        {children}
        <div className='flex justify-end mt-4 gap-1'>
          {onCancel && (
            <Button variant='secondary' onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button onClick={onSave}>Confirm</Button>
        </div>
      </DialogPanel>
    </Dialog>
  );
}
