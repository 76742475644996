import { ItemStatus, UserRole, UserComplianceStatus } from '@/api/model';

export const itemStatusColor = (status: ItemStatus | undefined) => {
  switch (status) {
    case ItemStatus.draft: {
      return 'info';
    }

    case ItemStatus.under_review: {
      return 'warning';
    }

    case ItemStatus.accepted: {
      return 'success';
    }

    case ItemStatus.rejected: {
      return 'failure';
    }

    default: {
      return 'gray';
    }
  }
};

export const userRoleColor = (role: UserRole | undefined) => {
  switch (role) {
    case UserRole.user: {
      return 'yellow';
    }

    case UserRole.admin: {
      return 'lime';
    }

    case UserRole.super_admin: {
      return 'teal';
    }

    default: {
      return 'gray';
    }
  }
};

export const userComplianceStatusColor = (
  status: UserComplianceStatus | undefined,
) => {
  switch (status) {
    case UserComplianceStatus.pending: {
      return 'info';
    }

    case UserComplianceStatus.unverified: {
      return 'warning';
    }

    case UserComplianceStatus.verified: {
      return 'success';
    }

    default: {
      return 'gray';
    }
  }
};
