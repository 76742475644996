import { Link } from '@tanstack/react-router';
import { Dropdown } from 'flowbite-react';
import { Button } from '@tremor/react';
import { createColumnHelper } from '@tanstack/react-table';
import { MoreVertical } from 'lucide-react';

import { DataTableColumnHeader } from '../data-table';

import type { User } from '@/api/model';

const columnHelper = createColumnHelper<User>();
export const expertColumns = ({
  onRemoveExpert,
  onEdit,
  onEditBrands,
}: {
  onRemoveExpert: (user: User) => void;
  onEdit: (user: User) => void;
  onEditBrands: (user: User) => void;
}) => [
  columnHelper.accessor((user) => user.name, {
    id: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Name' />
    ),
    cell: (properties) => (
      <span className='ml-2 self-center font-semibold'>
        {properties.getValue()}
      </span>
    ),
  }),
  columnHelper.accessor((user) => user.email, {
    id: 'email',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Email' />
    ),
  }),
  columnHelper.accessor(
    (user) => user.itemBrands?.map((brand) => brand.name).join(', '),
    {
      id: 'brands',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Brands' />
      ),
    },
  ),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <div className='flex justify-end gap-1 relative'>
        <Link
          to='/experts/$expertId/advertisements'
          params={{
            expertId: row.original.id.toString(),
          }}
        >
          <Button variant='secondary'>View ads</Button>
        </Link>
        <Dropdown
          label=''
          renderTrigger={() => (
            <Button variant='secondary' className='px-2' icon={MoreVertical} />
          )}
          dismissOnClick={false}
          placement='bottom'
        >
          <Dropdown.Item
            onClick={() => {
              onRemoveExpert(row.original);
            }}
          >
            Remove expert status
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              onEdit(row.original);
            }}
          >
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              onEditBrands(row.original);
            }}
          >
            Edit brands
          </Dropdown.Item>
        </Dropdown>
      </div>
    ),
  }),
];
