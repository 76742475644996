import { useState } from 'react';
import { createFileRoute, Link } from '@tanstack/react-router';
import { Spinner, Table } from 'flowbite-react';
import {
  Button,
  Card,
  DateRangePicker,
  type DateRangePickerValue,
} from '@tremor/react';

import { Wrapper, FinancialChart, Card as CardElement } from '@/components';
import { useExportFinancials, useFinancials } from '@/api';
import { formatDate, formatCurrency } from '@/utils';
import { type Price, FinancialPeriod } from '@/api/model';

const parsePeriod = (period?: string) => {
  switch (period) {
    case 'y': {
      return FinancialPeriod.month;
    }

    case 'm': {
      return FinancialPeriod.month;
    }

    case 't': {
      return FinancialPeriod.day;
    }

    case 'w': {
      return FinancialPeriod.day;
    }

    case 'tdy': {
      return FinancialPeriod.day;
    }

    default: {
      return FinancialPeriod.month;
    }
  }
};

export const Route = createFileRoute('/_authenticated/financial/')({
  component: Financial,
});

function Financial() {
  const [selectedDates, setSelectedDates] = useState<DateRangePickerValue>({
    from: new Date(new Date().getFullYear(), 0, 1, 1),
    to: new Date(new Date().getFullYear() + 1, 0, 1, 1),
    selectValue: 'y',
  });
  const { data } = useFinancials({
    from: selectedDates.from?.toISOString(),
    to: selectedDates.to?.toISOString(),
    period: parsePeriod(selectedDates.selectValue),
  });
  const {
    totalRevenue,
    platformFeeRevenue,
    protectionFeeRevenue,
    featureRevenue,
    revenue,
    revenueThisMonth,
    revenuePreviousMonth,
    latestOrders = [],
    latestFeatures = [],
  } = data ?? {};
  const { mutateAsync } = useExportFinancials({
    mutation: {
      async onSuccess(response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = `financials-${selectedDates.from?.getFullYear()}.csv`;
        link.setAttribute('download', fileName);
        document.body.append(link);
        link.click();
        link.remove();
      },
    },
  });

  const renderPrice = (price: Price | undefined) => {
    if (price) {
      return formatCurrency(price.cents, price.currencyIso);
    }

    return <Spinner />;
  };

  return (
    <Wrapper
      header='Financial'
      extraActions={
        <Button
          variant='secondary'
          onClick={async () =>
            mutateAsync({
              params: {
                from: selectedDates.from?.toISOString(),
                to: selectedDates.to?.toISOString(),
                period: parsePeriod(selectedDates.selectValue),
              },
            })
          }
        >
          Export CSV
        </Button>
      }
    >
      <div className='flex gap-4 flex-col'>
        <div className='flex gap-4'>
          <Card className='w-full md:mt-0 sm:max-w-md xl:p-0'>
            <div className='p-4'>
              <p className='text-sm text-gray-400'>Total Revenue</p>
              <p className='text-xl text-gray-900 dark:text-gray-200'>
                {renderPrice(totalRevenue)}
              </p>
            </div>
          </Card>
          <Card className='w-full md:mt-0 sm:max-w-md xl:p-0'>
            <div className='p-4'>
              <p className='text-sm text-gray-400'>Total Revenue Commissions</p>
              <p className='text-xl text-gray-900 dark:text-gray-200'>
                {renderPrice(platformFeeRevenue)}
              </p>
            </div>
          </Card>
          <Card className='w-full md:mt-0 sm:max-w-md xl:p-0'>
            <div className='p-4'>
              <p className='text-sm text-gray-400'>Total Revenue Highlights</p>
              <p className='text-xl text-gray-900 dark:text-gray-200'>
                {renderPrice(featureRevenue)}
              </p>
            </div>
          </Card>
          <Card className='w-full md:mt-0 sm:max-w-md xl:p-0'>
            <div className='p-4'>
              <p className='text-sm text-gray-400'>
                Total Revenue Buyer / Seller Protection
              </p>
              <p className='text-xl text-gray-900 dark:text-gray-200'>
                {renderPrice(protectionFeeRevenue)}
              </p>
            </div>
          </Card>
        </div>
        <DateRangePicker
          enableYearNavigation
          weekStartsOn={1}
          value={selectedDates}
          onValueChange={setSelectedDates}
        />
        <div className='flex gap-4'>
          <FinancialChart
            revenue={revenue}
            revenueThisMonth={revenueThisMonth}
            revenuePreviousMonth={revenuePreviousMonth}
            selectedDates={selectedDates}
          />
        </div>
        <CardElement
          title='Orders'
          subtitle='The latest orders'
          action={
            <Link to='/financial/orders'>
              <Button variant='secondary'>View More</Button>
            </Link>
          }
          padding={false}
        >
          <Table striped>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <h5 className='font-semibold'>Item</h5>
                </Table.Cell>
                <Table.Cell>
                  <h5 className='font-semibold text-gray-400'>Price</h5>
                </Table.Cell>
                <Table.Cell>
                  <h5 className='font-semibold text-gray-400'>Date</h5>
                </Table.Cell>
                <Table.Cell>
                  <h5 className='font-semibold text-gray-400'>Order number</h5>
                </Table.Cell>
                <Table.Cell>
                  <h5 className='font-semibold text-gray-400'>Seller name</h5>
                </Table.Cell>
                <Table.Cell>
                  <h5 className='font-semibold text-gray-400'>Buyer name</h5>
                </Table.Cell>
              </Table.Row>
              {latestOrders?.length ? (
                latestOrders.map((order) => (
                  <Table.Row key={order.id}>
                    <Table.Cell>
                      <Link
                        to='/advertisements/$advertisementId'
                        params={{
                          advertisementId: order.itemId.toString(),
                        }}
                        className='flex hover:text-primary'
                      >
                        <span className='font-semibold'>{order.itemName}</span>
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {formatCurrency(
                        order.price.cents,
                        order.price.currencyIso,
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {order.createdAt && formatDate(order.createdAt)}
                    </Table.Cell>
                    <Table.Cell>{order.id}</Table.Cell>
                    <Table.Cell>{order.seller.name}</Table.Cell>
                    <Table.Cell>{order.buyer.name}</Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell>No items</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </CardElement>
        <CardElement
          title='Highlights'
          subtitle='The latest highlights'
          action={
            <Link to='/financial/features'>
              <Button variant='secondary'>View More</Button>
            </Link>
          }
          padding={false}
        >
          <Table striped>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <h5 className='font-semibold'>Item</h5>
                </Table.Cell>
                <Table.Cell>
                  <h5 className='font-semibold text-gray-400'>Price</h5>
                </Table.Cell>
                <Table.Cell>
                  <h5 className='font-semibold text-gray-400'>Date</h5>
                </Table.Cell>
                <Table.Cell>
                  <h5 className='font-semibold text-gray-400'>Order number</h5>
                </Table.Cell>
                <Table.Cell>
                  <h5 className='font-semibold text-gray-400'>Buyer name</h5>
                </Table.Cell>
              </Table.Row>
              {latestFeatures?.length ? (
                latestFeatures.map((feature) => (
                  <Table.Row key={feature.id}>
                    <Table.Cell>
                      <Link
                        to='/advertisements/$advertisementId'
                        params={{
                          advertisementId: feature.itemId.toString(),
                        }}
                        className='flex hover:text-primary'
                      >
                        <span className='font-semibold'>
                          {feature.itemName}
                        </span>
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {formatCurrency(
                        feature.price.cents,
                        feature.price.currencyIso,
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {feature.createdAt && formatDate(feature.createdAt)}
                    </Table.Cell>
                    <Table.Cell>{feature.id}</Table.Cell>
                    <Table.Cell>{feature.userName}</Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell>No items</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </CardElement>
      </div>
    </Wrapper>
  );
}
