/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Preowned The Studio backend
 * OpenAPI spec version: v1
 */

/**
 * @nullable
 */
export enum UserComplianceStatus {
  unverified = 'unverified',
  pending = 'pending',
  verified = 'verified',
  null = null,
}
