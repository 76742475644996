import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { Button } from '@tremor/react';
import { Filter } from 'lucide-react';

import {
  Wrapper,
  DataTable,
  itemColumns,
  QuickSelect,
  FilterItemModal,
} from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import { getUserQueryOptions, useItems } from '@/api';
import { ItemStatus } from '@/api/model';

export const Route = createFileRoute(
  '/_authenticated/experts/$expertId/advertisements',
)({
  loader: async ({ context: { queryClient }, params: { expertId } }) =>
    queryClient.ensureQueryData(getUserQueryOptions(Number(expertId))),
  component: ExpertAdvertisements,
});

function ExpertAdvertisements() {
  const expert = Route.useLoaderData();
  const { expertId } = Route.useParams();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filters, setFilters] = useState({});

  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [status, setStatus] = useState<ItemStatus>();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['name', 'brandName'],
    extraQueryParameters: {
      expertIdEq: expertId,
      statusEq: status,
      ...filters,
    },
  });
  const { data } = useItems(queryParameters, queryOptions);
  const { items = [], meta } = data ?? {};

  return (
    <Wrapper
      header={expert.name}
      extraActions={
        <>
          <Button
            variant='secondary'
            icon={Filter}
            onClick={() => {
              setOpenFilterModal(true);
            }}
          >
            Filter
          </Button>
          <QuickSelect
            selected={status}
            setSelected={setStatus}
            options={[
              undefined,
              ItemStatus.accepted,
              ItemStatus.under_review,
              ItemStatus.rejected,
            ]}
          />
        </>
      }
      backLink='/experts'
      onSearch={setSearchQuery}
    >
      <DataTable
        data={items}
        columns={itemColumns()}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
      <FilterItemModal
        show={openFilterModal}
        showModal={setOpenFilterModal}
        onFilter={setFilters}
      />
    </Wrapper>
  );
}
