import { useState } from 'react';
import { MultiSelect, MultiSelectItem } from '@tremor/react';

import { FilterSelect, Modal, Label } from '@/components';
import { useItemFilters } from '@/api';

export function FilterItemModal({
  show,
  showModal,
  onFilter,
  filters = [
    'brand',
    'category',
    'color',
    'condition',
    'userName',
    'userEmail',
    'status',
  ],
}: {
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onFilter: (data: any) => void;
  readonly filters?: string[];
}) {
  const { data } = useItemFilters();
  const [brandFilters, setBrandFilters] = useState<string[]>();
  const [categoryFilters, setCategoryFilters] = useState<string[]>();
  const [colorFilters, setColorFilters] = useState<string[]>();
  const [conditionFilters, setConditionFilters] = useState<string[]>();
  const [userNameFilters, setUserNameFilters] = useState<string[]>();
  const [userEmailFilters, setUserEmailFilters] = useState<string[]>();
  const [statusFilters, setStatusFilters] = useState<string[]>();

  if (!data) return null;

  const {
    brands,
    categories,
    colors,
    conditions,
    userNames,
    userEmails,
    statuses,
  } = data;

  return (
    <Modal
      title='Filter options'
      show={show}
      onClose={() => {
        showModal(false);
      }}
      onSave={() => {
        onFilter({
          [brands.predicates.in]: brandFilters,
          [categories.predicates.in]: categoryFilters,
          [colors.predicates.in]: colorFilters,
          [conditions.predicates.in]: conditionFilters,
          [userNames.predicates.in]: userNameFilters,
          [userEmails.predicates.in]: userEmailFilters,
          [statuses.predicates.in]: statusFilters,
        });
        showModal(false);
      }}
    >
      <div className='flex flex-col gap-4'>
        {filters.includes('brand') && (
          <FilterSelect
            label='Brand'
            value={brandFilters}
            options={brands}
            onChange={setBrandFilters}
          />
        )}
        {filters.includes('category') && (
          <div>
            <div className='mb-2 block'>
              <Label htmlFor='Category' value='Category' />
            </div>
            <MultiSelect
              id='Category'
              value={categoryFilters}
              onValueChange={setCategoryFilters}
            >
              {categories.items?.map((category) => (
                <MultiSelectItem
                  key={category.id}
                  value={category.id.toString()}
                >
                  {category.parent_name
                    ? `${category.parent_name} > ${category.name}`
                    : category.name}
                </MultiSelectItem>
              ))}
            </MultiSelect>
          </div>
        )}
        {filters.includes('color') && (
          <FilterSelect
            label='Color'
            value={colorFilters}
            options={colors}
            onChange={setColorFilters}
          />
        )}
        {filters.includes('condition') && (
          <FilterSelect
            label='Condition'
            value={conditionFilters}
            options={conditions}
            onChange={setConditionFilters}
          />
        )}
        {filters.includes('status') && (
          <FilterSelect
            label='Status'
            value={statusFilters}
            options={statuses}
            onChange={setStatusFilters}
          />
        )}
        {filters.includes('userName') && (
          <FilterSelect
            label='Sellers name'
            value={userNameFilters}
            options={userNames}
            onChange={setUserNameFilters}
          />
        )}
        {filters.includes('userEmail') && (
          <FilterSelect
            label='Sellers email'
            value={userEmailFilters}
            options={userEmails}
            onChange={setUserEmailFilters}
          />
        )}
      </div>
    </Modal>
  );
}
