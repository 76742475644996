import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { Button } from '@tremor/react';
import { Filter } from 'lucide-react';

import {
  Wrapper,
  DataTable,
  itemColumns,
  QuickSelect,
  FilterItemModal,
} from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import { getItemBrandQueryOptions, useItems } from '@/api';
import { ItemStatus } from '@/api/model';

export const Route = createFileRoute(
  '/_authenticated/brands/$brandId/advertisements',
)({
  loader: async ({ context: { queryClient }, params: { brandId } }) =>
    queryClient.ensureQueryData(getItemBrandQueryOptions(Number(brandId))),
  component: BrandAdvertisements,
});

function BrandAdvertisements() {
  const brand = Route.useLoaderData();
  const { brandId } = Route.useParams();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filters, setFilters] = useState({});

  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [status, setStatus] = useState<ItemStatus>();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['name'],
    extraQueryParameters: {
      brandIdEq: brandId,
      statusEq: status,
      ...filters,
    },
  });
  const { data } = useItems(queryParameters, queryOptions);
  const { items = [], meta } = data ?? {};

  return (
    <Wrapper
      header={brand.name}
      extraActions={
        <>
          <Button
            variant='secondary'
            icon={Filter}
            onClick={() => {
              setOpenFilterModal(true);
            }}
          >
            Filter
          </Button>
          <QuickSelect
            selected={status}
            setSelected={setStatus}
            options={[
              undefined,
              ItemStatus.accepted,
              ItemStatus.under_review,
              ItemStatus.rejected,
            ]}
          />
        </>
      }
      backLink='/brands'
      onSearch={setSearchQuery}
    >
      <DataTable
        data={items}
        columns={itemColumns()}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
      <FilterItemModal
        show={openFilterModal}
        showModal={setOpenFilterModal}
        filters={['category', 'color', 'condition']}
        onFilter={setFilters}
      />
    </Wrapper>
  );
}
