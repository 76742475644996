import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { Button } from '@tremor/react';

import {
  Wrapper,
  DataTable,
  itemBrandColumns,
  CreateItemBrandModal,
  UpdateItemBrandModal,
} from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import { useItemBrands } from '@/api';
import type { ItemBrand } from '@/api/model';

export const Route = createFileRoute('/_authenticated/brands/')({
  component: Brands,
});

function Brands() {
  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['name'],
  });
  const { data } = useItemBrands(queryParameters, queryOptions);
  const { itemBrands = [], meta } = data ?? {};

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedItemBrand, setSelectedItemBrand] = useState<ItemBrand>();

  return (
    <Wrapper
      header='Brands'
      actions={
        <Button
          variant='secondary'
          onClick={() => {
            setOpenCreateModal(true);
          }}
        >
          Add new
        </Button>
      }
      onSearch={setSearchQuery}
    >
      <DataTable
        data={itemBrands}
        columns={itemBrandColumns({
          onEdit(itemBrand) {
            setSelectedItemBrand(itemBrand);
            setOpenUpdateModal(true);
          },
        })}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
      <CreateItemBrandModal
        show={openCreateModal}
        showModal={setOpenCreateModal}
      />
      <UpdateItemBrandModal
        itemBrand={selectedItemBrand}
        show={openUpdateModal}
        showModal={setOpenUpdateModal}
      />
    </Wrapper>
  );
}
