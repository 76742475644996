import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { Button } from '@tremor/react';
import { Filter } from 'lucide-react';

import {
  Wrapper,
  DataTable,
  reviewColumns,
  QuickSelect,
  FilterItemModal,
  SelectExpertModal,
  UpdateItemStatusModal,
} from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import { useItems } from '@/api';
import { type Item, ItemStatus } from '@/api/model';

export const Route = createFileRoute('/_authenticated/reviews/')({
  component: Reviews,
});

function Reviews() {
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filters, setFilters] = useState({});

  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [status, setStatus] = useState<ItemStatus>();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['name', 'brandName'],
    extraQueryParameters: {
      statusIn: status
        ? [status]
        : [ItemStatus.under_review, ItemStatus.rejected],
      ...filters,
    },
  });
  const { data } = useItems(queryParameters, queryOptions);
  const { items = [], meta } = data ?? {};

  const [openSelectExpertModal, setOpenSelectExpertModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Item>();

  return (
    <Wrapper
      header='Reviews'
      extraActions={
        <>
          <Button
            variant='secondary'
            icon={Filter}
            onClick={() => {
              setOpenFilterModal(true);
            }}
          >
            Filter
          </Button>
          <QuickSelect
            selected={status}
            setSelected={setStatus}
            options={[undefined, ItemStatus.under_review, ItemStatus.rejected]}
          />
        </>
      }
      onSearch={setSearchQuery}
    >
      <DataTable
        data={items}
        columns={reviewColumns({
          onChangeStatus(item) {
            setSelectedItem(item);
            setOpenStatusModal(true);
          },
          onMailToExpert(item) {
            setSelectedItem(item);
            setOpenSelectExpertModal(true);
          },
        })}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
      <FilterItemModal
        show={openFilterModal}
        showModal={setOpenFilterModal}
        onFilter={setFilters}
      />
      <SelectExpertModal
        item={selectedItem}
        show={openSelectExpertModal}
        showModal={setOpenSelectExpertModal}
      />
      <UpdateItemStatusModal
        item={selectedItem}
        show={openStatusModal}
        showModal={setOpenStatusModal}
      />
    </Wrapper>
  );
}
