import type { FieldValues } from 'react-hook-form';
import { Euro } from 'lucide-react';

import { NumberInput, type NumberInputProperties } from './number-input';

export type PriceInputProperties<T extends FieldValues> =
  NumberInputProperties<T>;

export function PriceInput<T extends FieldValues>({
  ...rest
}: PriceInputProperties<T>) {
  return (
    <NumberInput {...rest} enableStepper={false} step={0.01} icon={Euro} />
  );
}
