import { createFileRoute, Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { Table, Avatar, Badge } from 'flowbite-react';

import { Wrapper, FinancialChart, Card } from '@/components';
import { useAuth } from '@/hooks';
import { useFinancials, useItems, useItemBrands } from '@/api';
import { ItemStatus, FinancialPeriod } from '@/api/model';
import {
  formatEnum,
  itemStatusColor,
  formatDate,
  formatCurrency,
} from '@/utils';

export const Route = createFileRoute('/_authenticated/')({
  component: Index,
});

function Index() {
  const { profile } = useAuth();
  const { data } = useFinancials({
    from: new Date(new Date().getFullYear(), 0, 1, 1)?.toISOString(),
    to: new Date(new Date().getFullYear() + 1, 0, 1, 1)?.toISOString(),
    period: FinancialPeriod.month,
  });
  const {
    revenue,
    revenueThisMonth,
    revenuePreviousMonth,
    latestOrders = [],
    latestFeatures = [],
  } = data ?? {};

  const { data: itemData } = useItems({
    items: 5,
    q: {
      status_in: [ItemStatus.under_review, ItemStatus.rejected], // eslint-disable-line @typescript-eslint/naming-convention
    },
  });
  const { items = [] } = itemData ?? {};

  const { data: itemBrandsData } = useItemBrands({
    'items': 5,
    'q[s]': 'available_items_count desc', // eslint-disable-line @typescript-eslint/naming-convention
  });
  const { itemBrands = [] } = itemBrandsData ?? {};

  return (
    <Wrapper
      header={
        <>
          Welcome back,{' '}
          <span className='text-primary'>{profile?.firstName}!</span>
        </>
      }
    >
      <div className='flex gap-4 flex-col'>
        <FinancialChart
          revenue={revenue}
          revenueThisMonth={revenueThisMonth}
          revenuePreviousMonth={revenuePreviousMonth}
          selectedDates={{}}
          linkTo='/financial'
        />
        <div className='flex gap-4'>
          <Card
            title='Review list'
            subtitle='Advertisements that are in review'
            action={
              <Link to='/reviews'>
                <Button variant='secondary'>View More</Button>
              </Link>
            }
            padding={false}
          >
            <Table striped>
              <Table.Body>
                {items?.length ? (
                  items.map((item) => (
                    <Table.Row key={item.id}>
                      <Table.Cell>
                        <Link
                          to='/advertisements/$advertisementId'
                          params={{
                            advertisementId: item.id.toString(),
                          }}
                          className='flex hover:text-primary'
                        >
                          <Avatar
                            img={item.mainImage?.image.versions.small?.url}
                          />
                          <span className='ml-2 self-center font-semibold'>
                            {item.name}
                          </span>
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        {Boolean(item.status) && (
                          <div className='flex'>
                            <Badge
                              size='sm'
                              color={itemStatusColor(item.status)}
                            >
                              {formatEnum(item.status)}
                            </Badge>
                          </div>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell>No items</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Card>
          <Card
            title='Brands'
            subtitle='Top brands by available items'
            action={
              <Link to='/brands'>
                <Button variant='secondary'>View More</Button>
              </Link>
            }
            padding={false}
          >
            <Table striped>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <h5 className='font-semibold'>Brand name</h5>
                  </Table.Cell>
                  <Table.Cell>
                    <h5 className='font-semibold text-gray-400'>
                      Available items
                    </h5>
                  </Table.Cell>
                </Table.Row>
                {itemBrands?.length ? (
                  itemBrands.map((brand) => (
                    <Table.Row key={brand.id}>
                      <Table.Cell>
                        <Link
                          to='/brands/$brandId/advertisements'
                          params={{
                            brandId: brand.id.toString(),
                          }}
                          className='flex hover:text-primary'
                        >
                          <span className='font-semibold'>{brand.name}</span>
                        </Link>
                      </Table.Cell>
                      <Table.Cell>{brand.availableItemsCount}</Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell>No items</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Card>
        </div>
        <div className='flex gap-4'>
          <Card
            title='Orders'
            subtitle='The latest orders'
            action={
              <Link to='/financial/orders'>
                <Button variant='secondary'>View More</Button>
              </Link>
            }
            padding={false}
          >
            <Table striped>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <h5 className='font-semibold'>Item</h5>
                  </Table.Cell>
                  <Table.Cell>
                    <h5 className='font-semibold text-gray-400'>Price</h5>
                  </Table.Cell>
                  <Table.Cell>
                    <h5 className='font-semibold text-gray-400'>Date</h5>
                  </Table.Cell>
                  <Table.Cell>
                    <h5 className='font-semibold text-gray-400'>
                      Order number
                    </h5>
                  </Table.Cell>
                  <Table.Cell>
                    <h5 className='font-semibold text-gray-400'>Seller name</h5>
                  </Table.Cell>
                  <Table.Cell>
                    <h5 className='font-semibold text-gray-400'>Buyer name</h5>
                  </Table.Cell>
                </Table.Row>
                {latestOrders?.length ? (
                  latestOrders.map((order) => (
                    <Table.Row key={order.id}>
                      <Table.Cell>
                        <Link
                          to='/advertisements/$advertisementId'
                          params={{
                            advertisementId: order.itemId.toString(),
                          }}
                          className='flex hover:text-primary'
                        >
                          <span className='font-semibold'>
                            {order.itemName}
                          </span>
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        {formatCurrency(
                          order.price.cents,
                          order.price.currencyIso,
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {order.createdAt && formatDate(order.createdAt)}
                      </Table.Cell>
                      <Table.Cell>{order.id}</Table.Cell>
                      <Table.Cell>{order.seller.name}</Table.Cell>
                      <Table.Cell>{order.buyer.name}</Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell>No items</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Card>
        </div>
        <div className='flex gap-4'>
          <Card
            title='Highlights'
            subtitle='The latest highlights'
            action={
              <Link to='/financial/features'>
                <Button variant='secondary'>View More</Button>
              </Link>
            }
            padding={false}
          >
            <Table striped>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <h5 className='font-semibold'>Item</h5>
                  </Table.Cell>
                  <Table.Cell>
                    <h5 className='font-semibold text-gray-400'>Price</h5>
                  </Table.Cell>
                  <Table.Cell>
                    <h5 className='font-semibold text-gray-400'>Date</h5>
                  </Table.Cell>
                  <Table.Cell>
                    <h5 className='font-semibold text-gray-400'>
                      Order number
                    </h5>
                  </Table.Cell>
                  <Table.Cell>
                    <h5 className='font-semibold text-gray-400'>Buyer name</h5>
                  </Table.Cell>
                </Table.Row>
                {latestFeatures?.length ? (
                  latestFeatures.map((feature) => (
                    <Table.Row key={feature.id}>
                      <Table.Cell>
                        <Link
                          to='/advertisements/$advertisementId'
                          params={{
                            advertisementId: feature.itemId.toString(),
                          }}
                          className='flex hover:text-primary'
                        >
                          <span className='font-semibold'>
                            {feature.itemName}
                          </span>
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        {formatCurrency(
                          feature.price.cents,
                          feature.price.currencyIso,
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {feature.createdAt && formatDate(feature.createdAt)}
                      </Table.Cell>
                      <Table.Cell>{feature.id}</Table.Cell>
                      <Table.Cell>{feature.userName}</Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell>No items</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Card>
        </div>
      </div>
    </Wrapper>
  );
}
