import { Link } from '@tanstack/react-router';
import { Avatar } from 'flowbite-react';
import { createColumnHelper } from '@tanstack/react-table';

import { DataTableColumnHeader } from '../data-table';

import type { Order } from '@/api/model';
import { formatCurrency, formatDate, formatEnum } from '@/utils';

const columnHelper = createColumnHelper<Order>();
export const orderColumns = () => [
  columnHelper.accessor((order) => order.itemName, {
    id: 'itemName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Name' />
    ),
    cell: (properties) => (
      <Link
        to='/advertisements/$advertisementId'
        params={{
          advertisementId: properties.row.original.itemId.toString(),
        }}
        className='flex hover:text-primary'
      >
        <Avatar
          img={properties.row.original.itemMainImage.image.versions.small?.url}
        />
        <span className='ml-2 self-center font-semibold'>
          {properties.getValue()}
        </span>
      </Link>
    ),
  }),
  columnHelper.accessor(
    (order) => formatCurrency(order.price.cents, order.price.currencyIso),
    {
      id: 'priceCents',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Price' />
      ),
    },
  ),
  columnHelper.accessor(
    (order) =>
      formatCurrency(
        order.shipping.cents + order.shippingInsuranceFee.cents,
        order.shipping.currencyIso,
      ),
    {
      id: 'shippingCents',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Shipping' />
      ),
    },
  ),
  columnHelper.accessor(
    (order) =>
      formatCurrency(order.platformFee.cents, order.platformFee.currencyIso),
    {
      id: 'platformFeeCents',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='POTP fee' />
      ),
    },
  ),
  columnHelper.accessor(
    (order) =>
      formatCurrency(order.sellerPrice.cents, order.sellerPrice.currencyIso),
    {
      id: 'sellerPriceCents',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Seller price' />
      ),
    },
  ),
  columnHelper.accessor(
    (order) => order.createdAt && formatDate(order.createdAt),
    {
      id: 'createdAt',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Date' />
      ),
    },
  ),
  columnHelper.accessor((order) => order.protectionType, {
    id: 'protectionType',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Protection' />
    ),
    cell: (properties) =>
      properties.getValue() && formatEnum(properties.getValue()),
  }),
  columnHelper.accessor((order) => order.id, {
    id: 'id',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Order number' />
    ),
  }),
  columnHelper.accessor((order) => order.seller.name, {
    id: 'sellerName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Seller name' />
    ),
    cell: (properties) => (
      <Link
        to='/users/$userId/advertisements'
        params={{
          userId: properties.row.original.seller.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        <span className='ml-2 self-center'>{properties.getValue()}</span>
      </Link>
    ),
  }),
  columnHelper.accessor((order) => order.buyer.name, {
    id: 'buyerName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Buyer name' />
    ),
    cell: (properties) => (
      <Link
        to='/users/$userId'
        params={{
          userId: properties.row.original.buyer.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        <span className='ml-2 self-center'>{properties.getValue()}</span>
      </Link>
    ),
  }),
];
