import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { Modal, TextInput } from '@/components';
import { handleFormError } from '@/utils';
import { useUpdateOrder, getItemQueryKey, getItemsQueryKey } from '@/api';
import type { Item, Order } from '@/api/model';

const schema = z.object({
  parcelWithProtectionId: z.coerce.string().nullable(),
});

export function UpdateOrderModal({
  item,
  order,
  show,
  showModal,
}: {
  readonly item: Item | undefined;
  readonly order: Order | undefined;
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdateOrder({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getItemQueryKey(item!.id),
        });
        await queryClient.invalidateQueries({
          queryKey: getItemsQueryKey(),
        });
      },
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (order) {
      reset({
        parcelWithProtectionId: order.parcelWithProtectionId,
      });
    }
  }, [order, reset]);

  const onSave = handleSubmit(async (data) => {
    try {
      await mutateAsync({
        id: order!.id,
        data: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          parcel_with_protection_id: data.parcelWithProtectionId,
        },
      });
      reset();
      showModal(false);
      toast.success('Order updated successfully!');
    } catch (error) {
      handleFormError(error, setError);
    }
  });

  return (
    <Modal
      title='Update order'
      show={show}
      onClose={() => {
        showModal(false);
      }}
      onCancel={() => {
        showModal(false);
      }}
      onSave={onSave}
    >
      <form className='flex flex-col gap-4'>
        <TextInput
          name='parcelWithProtectionId'
          label='Parcel ID'
          placeholder='Parcel ID'
          control={control}
          errorMessage={errors.parcelWithProtectionId?.message}
        />
      </form>
    </Modal>
  );
}
