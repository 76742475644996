/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Preowned The Studio backend
 * OpenAPI spec version: v1
 */

export enum ItemStatus {
  draft = 'draft',
  under_review = 'under_review',
  accepted = 'accepted',
  rejected = 'rejected',
  expired = 'expired',
  sold = 'sold',
}
