import { useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import {
  Wrapper,
  DataTable,
  expertColumns,
  UpdateUserModal,
  UpdateExpertBrandsModal,
} from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import { useUsers, useExpertUser, getUsersQueryKey } from '@/api';
import type { User } from '@/api/model';

export const Route = createFileRoute('/_authenticated/experts/')({
  component: Experts,
});

function Experts() {
  const queryClient = useQueryClient();
  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['name'],
    extraQueryParameters: {
      expertEq: true,
    },
  });

  const { data } = useUsers(queryParameters, queryOptions);
  const { users = [], meta } = data ?? {};

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openUpdateBrandsModal, setOpenUpdateBrandsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();

  const { mutateAsync: makeExpert } = useExpertUser({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getUsersQueryKey(),
        });
      },
    },
  });

  const onRemoveExpert = async (user: User) => {
    await makeExpert({ id: user.id, data: { expert: false } });
    toast.success('Expert status removed');
  };

  return (
    <Wrapper header='Experts' onSearch={setSearchQuery}>
      <DataTable
        data={users}
        columns={expertColumns({
          onRemoveExpert,
          onEdit(user) {
            setSelectedUser(user);
            setOpenUpdateModal(true);
          },
          onEditBrands(user) {
            setSelectedUser(user);
            setOpenUpdateBrandsModal(true);
          },
        })}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
      <UpdateUserModal
        user={selectedUser}
        show={openUpdateModal}
        showModal={setOpenUpdateModal}
      />
      <UpdateExpertBrandsModal
        user={selectedUser}
        show={openUpdateBrandsModal}
        showModal={setOpenUpdateBrandsModal}
      />
    </Wrapper>
  );
}
