import { Link } from '@tanstack/react-router';
import { Avatar, Badge } from 'flowbite-react';
import { Button, Icon } from '@tremor/react';
import { createColumnHelper } from '@tanstack/react-table';
import { MailCheck } from 'lucide-react';

import { DataTableColumnHeader } from '../data-table';

import type { ItemStatus, Item } from '@/api/model';
import {
  formatCurrency,
  formatDate,
  formatDateTime,
  formatEnum,
  itemStatusColor,
} from '@/utils';

const columnHelper = createColumnHelper<Item>();
export const reviewColumns = ({
  onChangeStatus,
  onMailToExpert,
}: {
  onChangeStatus: (item: Item) => void;
  onMailToExpert: (item: Item) => void;
}) => [
  columnHelper.accessor((item) => item.name, {
    id: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Name' />
    ),
    cell: (properties) => (
      <Link
        to='/advertisements/$advertisementId'
        params={{
          advertisementId: properties.row.original.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        {Boolean(properties.row.original.mainImage?.image) && (
          <Avatar
            img={properties.row.original.mainImage?.image.versions.small?.url}
          />
        )}
        <span className='ml-2 self-center font-semibold'>
          {properties.getValue()}
        </span>
      </Link>
    ),
  }),
  columnHelper.accessor((item) => item.brand.name, {
    id: 'brandName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Brand' />
    ),
  }),
  columnHelper.accessor(
    (item) => formatCurrency(item.price.cents, item.price.currencyIso),
    {
      id: 'priceCents',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Price' />
      ),
    },
  ),
  columnHelper.accessor(
    (item) => item.createdAt && formatDate(item.createdAt),
    {
      id: 'createdAt',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Date' />
      ),
    },
  ),
  columnHelper.accessor((item) => item.seller.name, {
    id: 'sellerName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Seller name' />
    ),
  }),
  columnHelper.accessor((item) => item.status as string, {
    id: 'status',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Status' />
    ),
    cell: (properties) => (
      <div className='flex'>
        {Boolean(properties.getValue()) && (
          <Badge
            size='sm'
            color={itemStatusColor(properties.getValue() as ItemStatus)}
          >
            {formatEnum(properties.getValue())}
          </Badge>
        )}
      </div>
    ),
  }),
  columnHelper.accessor((item) => item?.expert?.name, {
    id: 'expertName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Expert' />
    ),
    cell: (properties) => (
      <div className='flex'>
        {properties.getValue() ? (
          <div className='flex items-center'>
            {properties.getValue()}
            {properties.row.original.expertReviewRequestSentAt && (
              <Icon
                icon={MailCheck}
                variant='simple'
                tooltip={formatDateTime(
                  properties.row.original.expertReviewRequestSentAt,
                )}
                size='md'
              />
            )}
          </div>
        ) : (
          'Not assigned'
        )}
      </div>
    ),
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <div className='flex justify-end gap-1 relative'>
        {row.original.expert ? null : (
          <Button
            variant='secondary'
            onClick={() => {
              onMailToExpert(row.original);
            }}
          >
            Mail to expert
          </Button>
        )}
        <Button
          variant='secondary'
          onClick={() => {
            onChangeStatus(row.original);
          }}
        >
          Change status
        </Button>
      </div>
    ),
  }),
];
