import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { Modal, Select } from '@/components';
import { useQuery } from '@/hooks';
import { handleFormError } from '@/utils';
import { useUsers, useAddExpert, getItemsQueryKey } from '@/api';
import type { Item } from '@/api/model';

const schema = z.object({
  expertId: z.string(),
});

export function SelectExpertModal({
  item,
  show,
  showModal,
}: {
  readonly item: Item | undefined;
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const queryClient = useQueryClient();
  const { queryParameters } = useQuery({
    extraQueryParameters: {
      expertEq: true,
      itemBrandsIdEq: item?.brand.id,
      items: 100,
    },
  });
  const { data } = useUsers(queryParameters);
  const { mutateAsync } = useAddExpert({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getItemsQueryKey(),
        });
      },
    },
  });
  const { users = [] } = data ?? {};

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const onSave = handleSubmit(async ({ expertId }) => {
    if (!item) return;

    try {
      await mutateAsync({
        id: item.id,
        expertId: Number(expertId),
      });
      reset();
      showModal(false);
      toast.success('Expert mailed successfully!');
    } catch (error) {
      handleFormError(error, setError);
    }
  });

  return (
    <Modal
      title='Mail expert'
      show={show}
      onClose={() => {
        showModal(false);
      }}
      onSave={onSave}
    >
      <form className='flex flex-col gap-4'>
        <Select
          name='expertId'
          label='Expert'
          options={users.map((user) => ({
            value: user.id.toString(),
            label: user.name,
          }))}
          control={control}
          errorMessage={errors.expertId?.message}
        />
      </form>
    </Modal>
  );
}
