import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { Modal, Select } from '@/components';
import { useSetItemStatus, getItemQueryKey, getItemsQueryKey } from '@/api';
import { formatEnum, handleFormError } from '@/utils';
import { ItemStatus, type Item } from '@/api/model';

const schema = z.object({
  status: z.nativeEnum(ItemStatus),
});

export function UpdateItemStatusModal({
  item,
  show,
  showModal,
}: {
  readonly item: Item | undefined;
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const queryClient = useQueryClient();
  const { mutateAsync } = useSetItemStatus({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getItemQueryKey(item!.id),
        });
        await queryClient.invalidateQueries({
          queryKey: getItemsQueryKey(),
        });
      },
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (item) {
      reset({ status: item.status });
    }
  }, [item, reset]);

  const onSave = handleSubmit(async ({ status }) => {
    if (!item) return;

    try {
      await mutateAsync({ id: item.id, data: { status } });
      reset();
      showModal(false);
      toast.success('Status updated successfully!');
    } catch (error) {
      handleFormError(error, setError);
    }
  });

  return (
    <Modal
      title='Update status'
      show={show}
      onClose={() => {
        showModal(false);
      }}
      onCancel={() => {
        showModal(false);
      }}
      onSave={onSave}
    >
      <form className='flex flex-col gap-4'>
        <Select
          name='status'
          control={control}
          options={Object.keys(ItemStatus).map((status) => ({
            value: status,
            label: formatEnum(status),
          }))}
          errorMessage={errors.status?.message}
        />
      </form>
    </Modal>
  );
}
