import { useEffect, useState, type SetStateAction } from 'react';

/**
 * Custom hook for managing search functionality with a delayed update.
 * @param {number} delay - The delay (in milliseconds) before triggering the search update.
 * @returns {[T | undefined, React.Dispatch<SetStateAction<T | undefined>>]} - The current search value and a function to set the search value.
 */
export function useSearch<T = string>(
  delay = 350,
): [T | undefined, React.Dispatch<SetStateAction<T | undefined>>] {
  const [search, setSearch] = useState<T>();
  const [searchQuery, setSearchQuery] = useState<T>();

  useEffect(() => {
    const delayFunction = setTimeout(() => {
      setSearch(searchQuery);
    }, delay);

    return () => {
      clearTimeout(delayFunction);
    };
  }, [searchQuery, delay]);

  return [search, setSearchQuery];
}
