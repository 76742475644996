import { Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { Dropdown } from 'flowbite-react';
import { createColumnHelper } from '@tanstack/react-table';
import { MoreVertical } from 'lucide-react';

import { DataTableColumnHeader } from '../data-table';

import type { Page } from '@/api/model';

const columnHelper = createColumnHelper<Page>();
export const pageColumns = ({
  onDelete,
}: {
  onDelete: (user: Page) => void;
}) => [
  columnHelper.accessor((page) => page.title, {
    id: 'title',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Title' />
    ),
    cell: (properties) => (
      <Link
        to='/pages/$pageId/edit'
        params={{
          pageId: properties.row.original.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        <span className='ml-2 self-center font-semibold'>
          {properties.getValue()}
        </span>
      </Link>
    ),
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <div className='flex justify-end gap-1 relative'>
        <Dropdown
          label=''
          renderTrigger={() => (
            <Button variant='secondary' className='px-2' icon={MoreVertical} />
          )}
          dismissOnClick={false}
          placement='bottom'
        >
          <Dropdown.Item>
            <Link
              to='/pages/$pageId/edit'
              params={{
                pageId: row.original.id.toString(),
              }}
            >
              Edit
            </Link>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              onDelete(row.original);
            }}
          >
            Delete
          </Dropdown.Item>
        </Dropdown>
      </div>
    ),
  }),
];
