import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { Modal, TextInput } from '@/components';
import { useCreateItemBrand, getItemBrandsQueryKey } from '@/api';
import { handleFormError } from '@/utils';

const schema = z.object({
  name: z.string(),
});

export function CreateItemBrandModal({
  show,
  showModal,
}: {
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const queryClient = useQueryClient();
  const { mutateAsync } = useCreateItemBrand({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getItemBrandsQueryKey(),
        });
      },
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const onSave = handleSubmit(async ({ name }) => {
    try {
      await mutateAsync({ data: { name } });
      reset();
      showModal(false);
      toast.success('Brand added successfully!');
    } catch (error) {
      handleFormError(error, setError);
    }
  });

  return (
    <Modal
      title='Add new brand'
      show={show}
      onClose={() => {
        showModal(false);
      }}
      onSave={onSave}
    >
      <form className='flex flex-col gap-4'>
        <TextInput
          name='name'
          label='Name'
          placeholder='Name'
          control={control}
          errorMessage={errors.name?.message}
        />
      </form>
    </Modal>
  );
}
