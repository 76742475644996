import { Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { createColumnHelper } from '@tanstack/react-table';
import { Pencil } from 'lucide-react';

import { DataTableColumnHeader } from '../data-table';

import type { ItemBrand } from '@/api/model';

const columnHelper = createColumnHelper<ItemBrand>();
export const itemBrandColumns = ({
  onEdit,
}: {
  onEdit: (itemBrand: ItemBrand) => void;
}) => [
  columnHelper.accessor((item) => item.name, {
    id: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Name' />
    ),
    cell: (properties) => (
      <Link
        to='/brands/$brandId/advertisements'
        params={{
          brandId: properties.row.original.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        <span className='ml-2 self-center font-semibold'>
          {properties.getValue()}
        </span>
      </Link>
    ),
  }),
  columnHelper.accessor((item) => item.availableItemsCount?.toString(), {
    id: 'availableItemsCount',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Available articles' />
    ),
    cell: (properties) => (
      <span className='ml-2 self-center font-semibold'>
        {properties.getValue()}
      </span>
    ),
  }),
  columnHelper.accessor((item) => item.soldItemsCount?.toString(), {
    id: 'soldItemsCount',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Articles sold' />
    ),
    cell: (properties) => (
      <span className='ml-2 self-center font-semibold'>
        {properties.getValue()}
      </span>
    ),
  }),
  columnHelper.accessor(
    (item) => item.experts?.map((expert) => expert.name).join(', '),
    {
      id: 'experts',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Experts' />
      ),
    },
  ),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <div className='flex justify-end gap-1 relative'>
        <Button
          variant='secondary'
          icon={Pencil}
          onClick={() => {
            onEdit(row.original);
          }}
        />
      </div>
    ),
  }),
];
