import { Dialog, DialogPanel, Button } from '@tremor/react';

export function DeleteModal({
  type,
  show,
  showModal,
  onDelete,
}: {
  readonly type: string;
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onDelete: () => void;
}) {
  return (
    <Dialog
      open={show}
      onClose={() => {
        showModal(false);
      }}
    >
      <DialogPanel className='overflow-visible'>
        <h3 className='text-lg font-semibold text-gray-900 dark:text-white'>
          Delete {type}
        </h3>
        <p className='mt-2'>Are you sure you want to delete this {type}?</p>
        <div className='flex justify-end mt-4'>
          <Button className='w-full' color='red' onClick={onDelete}>
            Delete
          </Button>
        </div>
      </DialogPanel>
    </Dialog>
  );
}
