import { createFileRoute, Link } from '@tanstack/react-router';
import { Button } from '@tremor/react';
import { Table } from 'flowbite-react';

import { Wrapper, Card } from '@/components';
import { getUserQueryOptions } from '@/api';
import { formatDate, formatDateTime, formatEnum } from '@/utils';

export const Route = createFileRoute('/_authenticated/users/$userId')({
  loader: async ({ context: { queryClient }, params: { userId } }) =>
    queryClient.ensureQueryData(getUserQueryOptions(Number(userId))),
  component: User,
});

function User() {
  const user = Route.useLoaderData();
  const { userId } = Route.useParams();

  return (
    <Wrapper
      header={user.name}
      actions={
        <div className='flex gap-2'>
          <Link
            to='/users/$userId/advertisements'
            params={{
              userId,
            }}
          >
            <Button variant='secondary'>View ads</Button>
          </Link>
          <Link
            to='/users/$userId/orders'
            params={{
              userId,
            }}
          >
            <Button variant='secondary'>View orders</Button>
          </Link>
        </div>
      }
      backLink='/users'
    >
      <div className='flex flex-auto flex-col gap-4'>
        <Card title='General details' padding={false}>
          <Table striped>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Name</Table.Cell>
                <Table.Cell>{user.name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Email</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Birthdate</Table.Cell>
                <Table.Cell>{formatDate(user.birthdate)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Country</Table.Cell>
                <Table.Cell>{user.countryIso2}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Phone number</Table.Cell>
                <Table.Cell>{user.phoneNumber}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Registered at</Table.Cell>
                <Table.Cell>{formatDateTime(user.createdAt)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Merchant status</Table.Cell>
                <Table.Cell>
                  {formatEnum(user.merchantStatus as string)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Compliance status</Table.Cell>
                <Table.Cell>
                  {formatEnum(user.complianceStatus as string)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Outstanding compliance requirements</Table.Cell>
                <Table.Cell>
                  {user.outstandingComplianceRequirements}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Card>
        <Card title='Shipping address' padding={false}>
          <Table striped>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Name</Table.Cell>
                <Table.Cell>{user.shippingAddress?.name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Street</Table.Cell>
                <Table.Cell>{user.shippingAddress?.street}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Number</Table.Cell>
                <Table.Cell>{user.shippingAddress?.number}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Postal code</Table.Cell>
                <Table.Cell>{user.shippingAddress?.postalCode}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>City</Table.Cell>
                <Table.Cell>{user.shippingAddress?.city}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Country</Table.Cell>
                <Table.Cell>{user.shippingAddress?.countryIso2}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Card>
        <Card title='Billing address' padding={false}>
          <Table striped>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Name</Table.Cell>
                <Table.Cell>{user.billingAddress?.name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Street</Table.Cell>
                <Table.Cell>{user.billingAddress?.street}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Number</Table.Cell>
                <Table.Cell>{user.billingAddress?.number}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Postal code</Table.Cell>
                <Table.Cell>{user.billingAddress?.postalCode}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>City</Table.Cell>
                <Table.Cell>{user.billingAddress?.city}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Country</Table.Cell>
                <Table.Cell>{user.billingAddress?.countryIso2}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Card>
        <Card title='Comment'>{user.comment}</Card>
      </div>
    </Wrapper>
  );
}
