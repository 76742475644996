import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { Button } from '@tremor/react';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import {
  Wrapper,
  DataTable,
  userColumns,
  QuickSelect,
  CreateUserModal,
  UpdateUserModal,
  DeleteModal,
} from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import {
  useUsers,
  useExpertUser,
  useDeleteUser,
  getUsersQueryKey,
} from '@/api';
import type { User } from '@/api/model';

export const Route = createFileRoute('/_authenticated/users/')({
  component: Users,
});

function Users() {
  const queryClient = useQueryClient();
  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [expert, setExpert] = useState<boolean>();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['name', 'email'],
    extraQueryParameters: {
      roleEq: 'user',
      expertEq: expert,
    },
  });

  const { data } = useUsers(queryParameters, queryOptions);
  const { users = [], meta } = data ?? {};

  const { mutateAsync: makeExpert } = useExpertUser({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getUsersQueryKey(),
        });
      },
    },
  });

  const { mutateAsync: deleteUser } = useDeleteUser({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getUsersQueryKey(),
        });
      },
    },
  });

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();

  const onAddExpert = async (user: User) => {
    await makeExpert({ id: user.id, data: { expert: true } });
    toast.success('Expert status added');
  };

  const onRemoveExpert = async (user: User) => {
    await makeExpert({ id: user.id, data: { expert: false } });
    toast.success('Expert status removed');
  };

  const onDelete = async () => {
    if (!selectedUser) return;

    await deleteUser({ id: selectedUser.id });
    setOpenDeleteModal(false);
    toast.success('User deleted successfully!');
  };

  return (
    <Wrapper
      header='Users'
      actions={
        <Button
          variant='secondary'
          onClick={() => {
            setOpenCreateModal(true);
          }}
        >
          Add new
        </Button>
      }
      extraActions={
        <QuickSelect
          selected={
            expert === undefined ? undefined : expert ? 'Experts' : 'Users'
          }
          setSelected={(value) => {
            if (value === undefined) {
              setExpert(undefined);
            } else {
              setExpert(value === 'Experts');
            }
          }}
          options={[undefined, 'Users', 'Experts']}
        />
      }
      onSearch={setSearchQuery}
    >
      <DataTable
        data={users}
        columns={userColumns({
          onEdit(user) {
            setSelectedUser(user);
            setOpenUpdateModal(true);
          },
          onDelete(user) {
            setSelectedUser(user);
            setOpenDeleteModal(true);
          },
          onAddExpert,
          onRemoveExpert,
        })}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
      <CreateUserModal show={openCreateModal} showModal={setOpenCreateModal} />
      <UpdateUserModal
        user={selectedUser}
        show={openUpdateModal}
        showModal={setOpenUpdateModal}
      />
      <DeleteModal
        type='user'
        show={openDeleteModal}
        showModal={setOpenDeleteModal}
        onDelete={onDelete}
      />
    </Wrapper>
  );
}
