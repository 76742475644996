/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { Modal, MultiSelect } from '@/components';
import { useItemBrands, useUserBrands, getUsersQueryKey } from '@/api';
import { handleFormError } from '@/utils';
import type { User } from '@/api/model';

const schema = z.object({
  brands: z.array(z.string()),
});

export function UpdateExpertBrandsModal({
  user,
  show,
  showModal,
}: {
  readonly user: User | undefined;
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const queryClient = useQueryClient();
  const { data } = useItemBrands({ items: 100 });
  const { mutateAsync } = useUserBrands({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getUsersQueryKey(),
        });
      },
    },
  });
  const { itemBrands = [] } = data ?? {};

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (user) {
      reset({
        brands: user.itemBrands?.map((brand) => brand.id.toString()),
      });
    }
  }, [user, reset]);

  const onSave = handleSubmit(async ({ brands }) => {
    if (!user) return;

    try {
      await mutateAsync({
        id: user.id,
        data: {
          user_brand_ids: brands.map(Number),
        },
      });
      reset();
      showModal(false);
      toast.success('Brands updated successfully!');
    } catch (error) {
      handleFormError(error, setError);
    }
  });

  return (
    <Modal
      title='Edit brands'
      show={show}
      onClose={() => {
        showModal(false);
      }}
      onSave={onSave}
    >
      <form className='flex flex-col gap-4'>
        <MultiSelect
          name='brands'
          label='Brands'
          options={itemBrands.map((brand) => ({
            value: brand.id.toString(),
            label: brand.name,
          }))}
          control={control}
          errorMessage={errors.brands?.message}
        />
      </form>
    </Modal>
  );
}
