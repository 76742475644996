import { Badge, Dropdown } from 'flowbite-react';
import { Button } from '@tremor/react';
import { createColumnHelper } from '@tanstack/react-table';
import { MoreVertical } from 'lucide-react';

import { DataTableColumnHeader } from '../data-table';

import { formatEnum, userRoleColor } from '@/utils';
import type { User } from '@/api/model';

const columnHelper = createColumnHelper<User>();
export const adminColumns = ({
  onEdit,
  onDelete,
}: {
  onEdit: (admin: User) => void;
  onDelete: (admin: User) => void;
}) => [
  columnHelper.accessor((admin) => admin.name, {
    id: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Name' />
    ),
    cell: (properties) => (
      <span className='ml-2 self-center font-semibold'>
        {properties.getValue()}
      </span>
    ),
  }),
  columnHelper.accessor((admin) => admin.email, {
    id: 'email',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Email' />
    ),
  }),
  columnHelper.accessor((admin) => admin.role, {
    id: 'role',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Role' />
    ),
    cell: (properties) => (
      <div className='flex'>
        <Badge size='sm' color={userRoleColor(properties.getValue())}>
          {formatEnum(properties.getValue())}
        </Badge>
      </div>
    ),
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <div className='flex justify-end gap-1 relative'>
        <Dropdown
          label=''
          renderTrigger={() => (
            <Button variant='secondary' className='px-2' icon={MoreVertical} />
          )}
          dismissOnClick={false}
          placement='bottom'
        >
          <Dropdown.Item
            onClick={() => {
              onEdit(row.original);
            }}
          >
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              onDelete(row.original);
            }}
          >
            Delete
          </Dropdown.Item>
        </Dropdown>
      </div>
    ),
  }),
];
