import { MultiSelect, MultiSelectItem } from '@tremor/react';

import { Label } from './label';

import { formatEnum } from '@/utils';

type FilterSelectProperties = {
  readonly label: string;
  readonly value?: string[];
  readonly options?: { items: { names: string[] } };
  readonly onChange: (value: string[]) => void;
};

export function FilterSelect({
  label,
  value,
  options,
  onChange,
}: FilterSelectProperties) {
  return (
    <div>
      <div className='mb-2 block'>
        <Label htmlFor={label} value={label} />
      </div>
      <MultiSelect id={label} value={value} onValueChange={onChange}>
        {options?.items.names.map((name) => (
          <MultiSelectItem key={name} value={name}>
            {formatEnum(name)}
          </MultiSelectItem>
        ))}
      </MultiSelect>
    </div>
  );
}

export default FilterSelect;
