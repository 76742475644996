import { useLayoutEffect } from 'react';
import {
  createFileRoute,
  redirect,
  Outlet,
  Link,
  useRouter,
} from '@tanstack/react-router';
import { Sidebar } from 'flowbite-react';
import {
  LayoutDashboard,
  Coins,
  BookOpenText,
  Sparkle,
  Zap,
  Users,
  Megaphone,
  Star,
  LogOut,
} from 'lucide-react';

import { Container } from '@/components';
import { useAuth } from '@/hooks';
import logo from '@/assets/images/logo.svg';

export const Route = createFileRoute('/_authenticated')({
  beforeLoad({ context, location }) {
    if (!context.auth.authenticated) {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect({
        to: '/sign_in',
        search: {
          redirect: location.href,
        },
      });
    }
  },
  component: Authenticated,
});

function Authenticated() {
  const router = useRouter();
  const navigate = Route.useNavigate();
  const { authenticated, signOut } = useAuth();

  const onLogout = async () => {
    signOut();
    // eslint-disable-next-line promise/no-return-in-finally
    await router.invalidate().finally(async () => {
      return navigate({ to: '/' });
    });
  };

  useLayoutEffect(() => {
    if (!authenticated) {
      router.history.push('/sign_in');
    }
  }, [authenticated, router.history]);

  return (
    <>
      <Sidebar>
        <Sidebar.Logo
          href='#'
          img={logo}
          imgAlt='Logo'
          className='justify-center'
        />
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            <Link to='/'>
              {({ isActive }) => (
                <Sidebar.Item
                  active={isActive}
                  as='span'
                  icon={LayoutDashboard}
                >
                  Dashboard
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/financial'>
              {({ isActive }) => (
                <Sidebar.Item active={isActive} as='span' icon={Coins}>
                  Financial
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/pages'>
              {({ isActive }) => (
                <Sidebar.Item active={isActive} as='span' icon={BookOpenText}>
                  Pages
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/brands'>
              {({ isActive }) => (
                <Sidebar.Item active={isActive} as='span' icon={Sparkle}>
                  Brands
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/experts'>
              {({ isActive }) => (
                <Sidebar.Item active={isActive} as='span' icon={Zap}>
                  Experts
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/users'>
              {({ isActive }) => (
                <Sidebar.Item active={isActive} as='span' icon={Users}>
                  Users
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/admins'>
              {({ isActive }) => (
                <Sidebar.Item active={isActive} as='span' icon={Users}>
                  Admins
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/advertisements'>
              {({ isActive }) => (
                <Sidebar.Item active={isActive} as='span' icon={Megaphone}>
                  Advertisements
                </Sidebar.Item>
              )}
            </Link>
            <Link to='/reviews'>
              {({ isActive }) => (
                <Sidebar.Item active={isActive} as='span' icon={Star}>
                  Reviews
                </Sidebar.Item>
              )}
            </Link>
          </Sidebar.ItemGroup>
          <Sidebar.ItemGroup>
            <Sidebar.Item icon={LogOut} onClick={onLogout}>
              Logout
            </Sidebar.Item>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
      <Container>
        <Outlet />
      </Container>
    </>
  );
}
