import { useState } from 'react';
import type { PaginationState, SortingState } from '@tanstack/react-table';

export function useTable() {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<SortingState>([]);

  return {
    pagination,
    setPagination,
    sorting,
    setSorting,
  };
}
