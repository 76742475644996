import { Link } from '@tanstack/react-router';
import { Avatar, Badge } from 'flowbite-react';
import { createColumnHelper } from '@tanstack/react-table';

import { DataTableColumnHeader } from '../data-table';

import type { ItemStatus, Item } from '@/api/model';
import {
  formatCurrency,
  formatDate,
  formatEnum,
  itemStatusColor,
} from '@/utils';

const columnHelper = createColumnHelper<Item>();
export const itemColumns = () => [
  columnHelper.accessor((item) => item.name, {
    id: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Name' />
    ),
    cell: (properties) => (
      <Link
        to='/advertisements/$advertisementId'
        params={{
          advertisementId: properties.row.original.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        {Boolean(properties.row.original.mainImage?.image) && (
          <Avatar
            img={properties.row.original.mainImage?.image.versions.small?.url}
          />
        )}
        <span className='ml-2 self-center font-semibold'>
          {properties.getValue()}
        </span>
      </Link>
    ),
  }),
  columnHelper.accessor((item) => item.brand.name, {
    id: 'brandName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Brand' />
    ),
  }),
  columnHelper.accessor(
    (item) => formatCurrency(item.price.cents, item.price.currencyIso),
    {
      id: 'priceCents',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Price' />
      ),
    },
  ),
  columnHelper.accessor(
    (item) =>
      item?.order?.sellerPrice &&
      formatCurrency(
        item?.order?.sellerPrice.cents,
        item?.order?.sellerPrice.currencyIso,
      ),
    {
      id: 'sellerPriceCents',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Seller price' />
      ),
    },
  ),
  columnHelper.accessor((item) => item.seller.name, {
    id: 'sellerName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Seller name' />
    ),
  }),
  columnHelper.accessor(
    (item) => item.createdAt && formatDate(item.createdAt),
    {
      id: 'createdAt',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Date' />
      ),
    },
  ),
  columnHelper.accessor((item) => item?.order?.protectionType as string, {
    id: 'protectionType',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Protection' />
    ),
    cell: (properties) =>
      properties.getValue() && formatEnum(properties.getValue()),
  }),
  columnHelper.accessor((item) => item.status as string, {
    id: 'status',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Status' />
    ),
    cell: (properties) =>
      properties.getValue() && (
        <div className='flex'>
          <Badge
            size='sm'
            color={itemStatusColor(properties.getValue() as ItemStatus)}
          >
            {formatEnum(properties.getValue())}
          </Badge>
        </div>
      ),
  }),
];
