import { useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { DateRangePicker, type DateRangePickerValue } from '@tremor/react';

import { Wrapper, DataTable, orderColumns } from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import { useOrders } from '@/api';

export const Route = createFileRoute('/_authenticated/financial/orders')({
  component: Orders,
});

function Orders() {
  const [selectedDates, setSelectedDates] = useState<DateRangePickerValue>({
    from: new Date(new Date().getFullYear(), 0, 1, 1),
    to: new Date(new Date().getFullYear() + 1, 0, 1, 1),
    selectValue: 'y',
  });
  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: [
      'hashid',
      'itemName',
      'transactionUid',
      'buyerName',
      'sellerName',
    ],
  });
  const { data } = useOrders(
    {
      ...queryParameters,
      q: {
        ...queryParameters.q,
        created_at_gteq: selectedDates.from?.toISOString(), // eslint-disable-line @typescript-eslint/naming-convention
        created_at_lteq: selectedDates.to?.toISOString(), // eslint-disable-line @typescript-eslint/naming-convention
      },
    },
    queryOptions,
  );
  const { orders = [], meta } = data ?? {};

  return (
    <Wrapper
      header='Orders'
      extraActions={
        <div className='z-20'>
          <DateRangePicker
            enableYearNavigation
            weekStartsOn={1}
            value={selectedDates}
            onValueChange={setSelectedDates}
          />
        </div>
      }
      onSearch={setSearchQuery}
    >
      <DataTable
        data={orders}
        columns={orderColumns()}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
    </Wrapper>
  );
}
