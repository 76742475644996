import { useMemo } from 'react';
import {
  Button,
  Card,
  BarChart,
  Legend,
  type DateRangePickerValue,
} from '@tremor/react';
import { MoveDownRight, MoveUpRight, MoveRight } from 'lucide-react';
import { Link } from '@tanstack/react-router';

import { formatCurrency, percentageChange } from '@/utils';
import type { Financials } from '@/api/model';

type FinancialChartProperties = {
  readonly revenue: Financials['revenue'] | undefined;
  readonly revenueThisMonth: Financials['revenueThisMonth'] | undefined;
  readonly revenuePreviousMonth: Financials['revenuePreviousMonth'] | undefined;
  readonly selectedDates: DateRangePickerValue;
  readonly linkTo?: string;
};

export function FinancialChart({
  revenue,
  revenueThisMonth,
  revenuePreviousMonth,
  selectedDates,
  linkTo,
}: FinancialChartProperties) {
  const chartData = useMemo(() => {
    return (revenue ?? []).map((item) => ({
      label: item.date,
      Revenue: item.revenue.cents,
    }));
  }, [revenue]);

  const renderPercentageChange = () => {
    if (revenueThisMonth && revenuePreviousMonth) {
      const change = percentageChange(revenuePreviousMonth, revenueThisMonth);

      if (change > 0) {
        return (
          <span className='text-green-400 font-semibold flex items-center mr-1'>
            <MoveUpRight size={16} className='mr-1' />
            {change.toFixed(2)}%
          </span>
        );
      }

      if (change === 0) {
        return (
          <span className='text-orange-400 font-semibold flex items-center mr-1'>
            <MoveRight size={16} className='mr-1' />
            {change.toFixed(2)}%
          </span>
        );
      }

      return (
        <span className='text-red-400 font-semibold flex items-center mr-1'>
          <MoveDownRight size={16} className='mr-1' />
          {change.toFixed(2)}%
        </span>
      );
    }
  };

  return (
    <Card className='w-full'>
      <div className='p-4'>
        <div className='flex justify-between'>
          <div>
            <p className='text-sm text-gray-400'>Financial overview</p>
            <p className='text-xl mt-2'>
              {revenueThisMonth &&
                formatCurrency(
                  revenueThisMonth.cents,
                  revenueThisMonth.currencyIso,
                )}
            </p>
            <p className='text-xs text-gray-400 flex'>
              {renderPercentageChange()} vs last month
            </p>
          </div>
          {linkTo && (
            <div>
              <Link to={linkTo}>
                <Button variant='secondary'>View More</Button>
              </Link>
            </div>
          )}
        </div>
        <p className='text-sm text-gray-400 mt-2'>
          Sales from {selectedDates.from?.getFullYear()} to{' '}
          {selectedDates.to?.getFullYear()}
        </p>
        <BarChart
          className='mt-4'
          data={chartData}
          index='label'
          categories={['Revenue']}
          colors={['primary']}
          valueFormatter={(number: number) => formatCurrency(number, 'EUR')}
          yAxisWidth={100}
          showLegend={false}
        />
        <Legend
          className='mt-3'
          categories={['Revenue']}
          colors={['primary']}
        />
      </div>
    </Card>
  );
}
