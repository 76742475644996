import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';

import {
  Wrapper,
  DataTable,
  orderColumns,
  FilterItemModal,
} from '@/components';
import { useTable, useSearch, useQuery } from '@/hooks';
import { getUserQueryOptions, useOrders } from '@/api';

export const Route = createFileRoute('/_authenticated/users/$userId/orders')({
  loader: async ({ context: { queryClient }, params: { userId } }) =>
    queryClient.ensureQueryData(getUserQueryOptions(Number(userId))),
  component: UserOrders,
});

function UserOrders() {
  const user = Route.useLoaderData();
  const { userId } = Route.useParams();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filters, setFilters] = useState({});

  const { pagination, setPagination, sorting, setSorting } = useTable();
  const [searchQuery, setSearchQuery] = useSearch();
  const { queryParameters, queryOptions } = useQuery({
    pagination,
    sorting,
    searchQuery,
    searchFields: ['itemName', 'ItemBrandName'],
    extraQueryParameters: {
      userIdEq: userId,
      ...filters,
    },
  });
  const { data } = useOrders(queryParameters, queryOptions);
  const { orders = [], meta } = data ?? {};

  return (
    <Wrapper header={user.name} backLink='/users' onSearch={setSearchQuery}>
      <DataTable
        data={orders}
        columns={orderColumns()}
        pageCount={meta?.pagination?.pages}
        pagination={pagination}
        sorting={sorting}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
      <FilterItemModal
        show={openFilterModal}
        showModal={setOpenFilterModal}
        onFilter={setFilters}
      />
    </Wrapper>
  );
}
