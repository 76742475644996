import { Link } from '@tanstack/react-router';
import { Avatar } from 'flowbite-react';
import { createColumnHelper } from '@tanstack/react-table';

import { DataTableColumnHeader } from '../data-table';

import type { ItemFeature } from '@/api/model';
import { formatCurrency, formatDate } from '@/utils';

const columnHelper = createColumnHelper<ItemFeature>();
export const itemFeatureColumns = () => [
  columnHelper.accessor((itemFeature) => itemFeature.itemName, {
    id: 'itemName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Name' />
    ),
    cell: (properties) => (
      <Link
        to='/advertisements/$advertisementId'
        params={{
          advertisementId: properties.row.original.itemId.toString(),
        }}
        className='flex hover:text-primary'
      >
        <Avatar
          img={properties.row.original.itemMainImage.image.versions.small?.url}
        />
        <span className='ml-2 self-center font-semibold'>
          {properties.getValue()}
        </span>
      </Link>
    ),
  }),
  columnHelper.accessor(
    (itemFeature) =>
      formatCurrency(itemFeature.price.cents, itemFeature.price.currencyIso),
    {
      id: 'priceCents',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Price' />
      ),
    },
  ),
  columnHelper.accessor(
    (itemFeature) => itemFeature.createdAt && formatDate(itemFeature.createdAt),
    {
      id: 'createdAt',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Date' />
      ),
    },
  ),
  columnHelper.accessor((itemFeature) => itemFeature.id, {
    id: 'id',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Order number' />
    ),
  }),
  columnHelper.accessor((itemFeature) => itemFeature.userName, {
    id: 'userName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Buyer name' />
    ),
    cell: (properties) => (
      <Link
        to='/users/$userId'
        params={{
          userId: properties.row.original.id.toString(),
        }}
        className='flex hover:text-primary'
      >
        <span className='ml-2 self-center'>{properties.getValue()}</span>
      </Link>
    ),
  }),
];
