/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Preowned The Studio backend
 * OpenAPI spec version: v1
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import type {
  DefinedInitialDataOptions,
  DefinedUseInfiniteQueryResult,
  DefinedUseQueryResult,
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseInfiniteQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type {
  AdminSignInBody,
  CreateItemBrandBody,
  CreateItemCategoryBody,
  CreateItemImageBody,
  CreatePageBody,
  CreateUserBody,
  CrudItem,
  Errors,
  ExpertUserBody,
  ExportFinancialsParams,
  Financials,
  FinancialsParams,
  Item,
  ItemBrand,
  ItemBrands200,
  ItemBrandsParams,
  ItemCategories200,
  ItemCategoriesParams,
  ItemCategory,
  ItemFeature,
  ItemFeatures200,
  ItemFeaturesParams,
  ItemFilter,
  ItemImagePositionBody,
  Items200,
  ItemsParams,
  Order,
  Orders200,
  OrdersParams,
  Page,
  Pages200,
  PagesParams,
  Profile,
  RefundBody,
  Session,
  Sessions,
  SetItemStatusBody,
  SignInBody,
  UpdateItemBrandBody,
  UpdateItemCategoryBody,
  UpdateItemImageBody,
  UpdateOrderBody,
  UpdatePageBody,
  UpdateProfileBody,
  UpdateUserBody,
  User,
  UserBrandsBody,
  Users200,
  UsersParams,
} from './model';
import { customInstance } from '../utils/api';
import type { ErrorType, BodyType } from '../utils/api';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Fetch item filters
 */
export const itemFilters = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ItemFilter>(
    { url: `/api/admin/items/filters`, method: 'GET', signal },
    options,
  );
};

export const getItemFiltersQueryKey = () => {
  return [`/api/admin/items/filters`] as const;
};

export const getItemFiltersInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof itemFilters>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemFiltersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemFilters>>> = ({
    signal,
  }) => itemFilters(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof itemFilters>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemFiltersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemFilters>>
>;
export type ItemFiltersInfiniteQueryError = ErrorType<unknown>;

export function useItemFiltersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFilters>>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof itemFilters>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFiltersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFilters>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof itemFilters>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFiltersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFilters>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item filters
 */

export function useItemFiltersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFilters>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemFiltersInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemFiltersQueryOptions = <
  TData = Awaited<ReturnType<typeof itemFilters>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof itemFilters>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemFiltersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemFilters>>> = ({
    signal,
  }) => itemFilters(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof itemFilters>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemFiltersQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemFilters>>
>;
export type ItemFiltersQueryError = ErrorType<unknown>;

export function useItemFilters<
  TData = Awaited<ReturnType<typeof itemFilters>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof itemFilters>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof itemFilters>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFilters<
  TData = Awaited<ReturnType<typeof itemFilters>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof itemFilters>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof itemFilters>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFilters<
  TData = Awaited<ReturnType<typeof itemFilters>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof itemFilters>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item filters
 */

export function useItemFilters<
  TData = Awaited<ReturnType<typeof itemFilters>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof itemFilters>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemFiltersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemFiltersSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof itemFilters>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemFiltersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemFilters>>> = ({
    signal,
  }) => itemFilters(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof itemFilters>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemFiltersSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemFilters>>
>;
export type ItemFiltersSuspenseQueryError = ErrorType<unknown>;

export function useItemFiltersSuspense<
  TData = Awaited<ReturnType<typeof itemFilters>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFiltersSuspense<
  TData = Awaited<ReturnType<typeof itemFilters>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFiltersSuspense<
  TData = Awaited<ReturnType<typeof itemFilters>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item filters
 */

export function useItemFiltersSuspense<
  TData = Awaited<ReturnType<typeof itemFilters>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemFiltersSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemFiltersSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof itemFilters>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemFiltersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemFilters>>> = ({
    signal,
  }) => itemFilters(requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof itemFilters>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemFiltersSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemFilters>>
>;
export type ItemFiltersSuspenseInfiniteQueryError = ErrorType<unknown>;

export function useItemFiltersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFilters>>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFiltersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFilters>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFiltersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFilters>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item filters
 */

export function useItemFiltersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFilters>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof itemFilters>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemFiltersSuspenseInfiniteQueryOptions(options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Fetch financials
 */
export const financials = (
  params?: FinancialsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Financials>(
    { url: `/api/admin/financials`, method: 'GET', params, signal },
    options,
  );
};

export const getFinancialsQueryKey = (params?: FinancialsParams) => {
  return [`/api/admin/financials`, ...(params ? [params] : [])] as const;
};

export const getFinancialsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof financials>>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFinancialsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof financials>>> = ({
    signal,
  }) => financials(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof financials>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FinancialsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof financials>>
>;
export type FinancialsInfiniteQueryError = ErrorType<void>;

export function useFinancialsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof financials>>>,
  TError = ErrorType<void>,
>(
  params: undefined | FinancialsParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof financials>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useFinancialsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof financials>>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof financials>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useFinancialsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof financials>>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch financials
 */

export function useFinancialsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof financials>>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getFinancialsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getFinancialsQueryOptions = <
  TData = Awaited<ReturnType<typeof financials>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof financials>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFinancialsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof financials>>> = ({
    signal,
  }) => financials(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof financials>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FinancialsQueryResult = NonNullable<
  Awaited<ReturnType<typeof financials>>
>;
export type FinancialsQueryError = ErrorType<void>;

export function useFinancials<
  TData = Awaited<ReturnType<typeof financials>>,
  TError = ErrorType<void>,
>(
  params: undefined | FinancialsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof financials>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof financials>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useFinancials<
  TData = Awaited<ReturnType<typeof financials>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof financials>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof financials>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useFinancials<
  TData = Awaited<ReturnType<typeof financials>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof financials>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch financials
 */

export function useFinancials<
  TData = Awaited<ReturnType<typeof financials>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof financials>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getFinancialsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getFinancialsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof financials>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFinancialsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof financials>>> = ({
    signal,
  }) => financials(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof financials>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FinancialsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof financials>>
>;
export type FinancialsSuspenseQueryError = ErrorType<void>;

export function useFinancialsSuspense<
  TData = Awaited<ReturnType<typeof financials>>,
  TError = ErrorType<void>,
>(
  params: undefined | FinancialsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useFinancialsSuspense<
  TData = Awaited<ReturnType<typeof financials>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useFinancialsSuspense<
  TData = Awaited<ReturnType<typeof financials>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch financials
 */

export function useFinancialsSuspense<
  TData = Awaited<ReturnType<typeof financials>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getFinancialsSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getFinancialsSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof financials>>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFinancialsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof financials>>> = ({
    signal,
  }) => financials(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof financials>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FinancialsSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof financials>>
>;
export type FinancialsSuspenseInfiniteQueryError = ErrorType<void>;

export function useFinancialsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof financials>>>,
  TError = ErrorType<void>,
>(
  params: undefined | FinancialsParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useFinancialsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof financials>>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useFinancialsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof financials>>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch financials
 */

export function useFinancialsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof financials>>>,
  TError = ErrorType<void>,
>(
  params?: FinancialsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof financials>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getFinancialsSuspenseInfiniteQueryOptions(
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Export financials
 */
export const exportFinancials = (
  params?: ExportFinancialsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<string>(
    { url: `/api/admin/financials/export.csv`, method: 'POST', params },
    options,
  );
};

export const getExportFinancialsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportFinancials>>,
    TError,
    { params?: ExportFinancialsParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof exportFinancials>>,
  TError,
  { params?: ExportFinancialsParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof exportFinancials>>,
    { params?: ExportFinancialsParams }
  > = (props) => {
    const { params } = props ?? {};

    return exportFinancials(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportFinancialsMutationResult = NonNullable<
  Awaited<ReturnType<typeof exportFinancials>>
>;

export type ExportFinancialsMutationError = ErrorType<void>;

/**
 * @summary Export financials
 */
export const useExportFinancials = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportFinancials>>,
    TError,
    { params?: ExportFinancialsParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof exportFinancials>>,
  TError,
  { params?: ExportFinancialsParams },
  TContext
> => {
  const mutationOptions = getExportFinancialsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch item brands
 */
export const itemBrands = (
  params?: ItemBrandsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ItemBrands200>(
    { url: `/api/admin/item_brands`, method: 'GET', params, signal },
    options,
  );
};

export const getItemBrandsQueryKey = (params?: ItemBrandsParams) => {
  return [`/api/admin/item_brands`, ...(params ? [params] : [])] as const;
};

export const getItemBrandsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrands>>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemBrandsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemBrands>>> = ({
    signal,
  }) => itemBrands(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof itemBrands>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemBrandsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemBrands>>
>;
export type ItemBrandsInfiniteQueryError = ErrorType<void>;

export function useItemBrandsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrands>>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemBrandsParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemBrands>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrandsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrands>>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemBrands>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrandsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrands>>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item brands
 */

export function useItemBrandsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrands>>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemBrandsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemBrandsQueryOptions = <
  TData = Awaited<ReturnType<typeof itemBrands>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemBrands>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemBrandsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemBrands>>> = ({
    signal,
  }) => itemBrands(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof itemBrands>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemBrandsQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemBrands>>
>;
export type ItemBrandsQueryError = ErrorType<void>;

export function useItemBrands<
  TData = Awaited<ReturnType<typeof itemBrands>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemBrandsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemBrands>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemBrands>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrands<
  TData = Awaited<ReturnType<typeof itemBrands>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemBrands>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemBrands>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrands<
  TData = Awaited<ReturnType<typeof itemBrands>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemBrands>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item brands
 */

export function useItemBrands<
  TData = Awaited<ReturnType<typeof itemBrands>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemBrands>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemBrandsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemBrandsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof itemBrands>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemBrandsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemBrands>>> = ({
    signal,
  }) => itemBrands(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof itemBrands>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemBrandsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemBrands>>
>;
export type ItemBrandsSuspenseQueryError = ErrorType<void>;

export function useItemBrandsSuspense<
  TData = Awaited<ReturnType<typeof itemBrands>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemBrandsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrandsSuspense<
  TData = Awaited<ReturnType<typeof itemBrands>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrandsSuspense<
  TData = Awaited<ReturnType<typeof itemBrands>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item brands
 */

export function useItemBrandsSuspense<
  TData = Awaited<ReturnType<typeof itemBrands>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemBrandsSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemBrandsSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrands>>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemBrandsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemBrands>>> = ({
    signal,
  }) => itemBrands(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof itemBrands>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemBrandsSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemBrands>>
>;
export type ItemBrandsSuspenseInfiniteQueryError = ErrorType<void>;

export function useItemBrandsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrands>>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemBrandsParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrandsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrands>>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrandsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrands>>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item brands
 */

export function useItemBrandsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrands>>>,
  TError = ErrorType<void>,
>(
  params?: ItemBrandsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrands>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemBrandsSuspenseInfiniteQueryOptions(
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create item brand
 */
export const createItemBrand = (
  createItemBrandBody: BodyType<CreateItemBrandBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ItemBrand>(
    {
      url: `/api/admin/item_brands`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createItemBrandBody,
    },
    options,
  );
};

export const getCreateItemBrandMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createItemBrand>>,
    TError,
    { data: BodyType<CreateItemBrandBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createItemBrand>>,
  TError,
  { data: BodyType<CreateItemBrandBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createItemBrand>>,
    { data: BodyType<CreateItemBrandBody> }
  > = (props) => {
    const { data } = props ?? {};

    return createItemBrand(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateItemBrandMutationResult = NonNullable<
  Awaited<ReturnType<typeof createItemBrand>>
>;
export type CreateItemBrandMutationBody = BodyType<CreateItemBrandBody>;
export type CreateItemBrandMutationError = ErrorType<void | Errors>;

/**
 * @summary Create item brand
 */
export const useCreateItemBrand = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createItemBrand>>,
    TError,
    { data: BodyType<CreateItemBrandBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createItemBrand>>,
  TError,
  { data: BodyType<CreateItemBrandBody> },
  TContext
> => {
  const mutationOptions = getCreateItemBrandMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch item brand
 */
export const itemBrand = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ItemBrand>(
    { url: `/api/admin/item_brands/${id}`, method: 'GET', signal },
    options,
  );
};

export const getItemBrandQueryKey = (id: number) => {
  return [`/api/admin/item_brands/${id}`] as const;
};

export const getItemBrandInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrand>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemBrandQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemBrand>>> = ({
    signal,
  }) => itemBrand(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof itemBrand>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemBrandInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemBrand>>
>;
export type ItemBrandInfiniteQueryError = ErrorType<void>;

export function useItemBrandInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrand>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemBrand>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrandInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrand>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemBrand>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrandInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrand>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item brand
 */

export function useItemBrandInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrand>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemBrandInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemBrandQueryOptions = <
  TData = Awaited<ReturnType<typeof itemBrand>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemBrand>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemBrandQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemBrand>>> = ({
    signal,
  }) => itemBrand(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof itemBrand>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ItemBrandQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemBrand>>
>;
export type ItemBrandQueryError = ErrorType<void>;

export function useItemBrand<
  TData = Awaited<ReturnType<typeof itemBrand>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemBrand>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemBrand>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrand<
  TData = Awaited<ReturnType<typeof itemBrand>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemBrand>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemBrand>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrand<
  TData = Awaited<ReturnType<typeof itemBrand>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemBrand>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item brand
 */

export function useItemBrand<
  TData = Awaited<ReturnType<typeof itemBrand>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemBrand>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemBrandQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemBrandSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof itemBrand>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemBrandQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemBrand>>> = ({
    signal,
  }) => itemBrand(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof itemBrand>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemBrandSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemBrand>>
>;
export type ItemBrandSuspenseQueryError = ErrorType<void>;

export function useItemBrandSuspense<
  TData = Awaited<ReturnType<typeof itemBrand>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrandSuspense<
  TData = Awaited<ReturnType<typeof itemBrand>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrandSuspense<
  TData = Awaited<ReturnType<typeof itemBrand>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item brand
 */

export function useItemBrandSuspense<
  TData = Awaited<ReturnType<typeof itemBrand>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemBrandSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemBrandSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrand>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemBrandQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemBrand>>> = ({
    signal,
  }) => itemBrand(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof itemBrand>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemBrandSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemBrand>>
>;
export type ItemBrandSuspenseInfiniteQueryError = ErrorType<void>;

export function useItemBrandSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrand>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrandSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrand>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemBrandSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrand>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item brand
 */

export function useItemBrandSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemBrand>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemBrandSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update item brand
 */
export const updateItemBrand = (
  id: number,
  updateItemBrandBody: BodyType<UpdateItemBrandBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ItemBrand>(
    {
      url: `/api/admin/item_brands/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateItemBrandBody,
    },
    options,
  );
};

export const getUpdateItemBrandMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateItemBrand>>,
    TError,
    { id: number; data: BodyType<UpdateItemBrandBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateItemBrand>>,
  TError,
  { id: number; data: BodyType<UpdateItemBrandBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateItemBrand>>,
    { id: number; data: BodyType<UpdateItemBrandBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateItemBrand(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateItemBrandMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateItemBrand>>
>;
export type UpdateItemBrandMutationBody = BodyType<UpdateItemBrandBody>;
export type UpdateItemBrandMutationError = ErrorType<void | Errors>;

/**
 * @summary Update item brand
 */
export const useUpdateItemBrand = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateItemBrand>>,
    TError,
    { id: number; data: BodyType<UpdateItemBrandBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateItemBrand>>,
  TError,
  { id: number; data: BodyType<UpdateItemBrandBody> },
  TContext
> => {
  const mutationOptions = getUpdateItemBrandMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Remove item brand
 */
export const deleteItemBrand = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/admin/item_brands/${id}`, method: 'DELETE' },
    options,
  );
};

export const getDeleteItemBrandMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteItemBrand>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteItemBrand>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteItemBrand>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteItemBrand(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteItemBrandMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteItemBrand>>
>;

export type DeleteItemBrandMutationError = ErrorType<void>;

/**
 * @summary Remove item brand
 */
export const useDeleteItemBrand = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteItemBrand>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteItemBrand>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteItemBrandMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch item categories
 */
export const itemCategories = (
  params?: ItemCategoriesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ItemCategories200>(
    { url: `/api/admin/item_categories`, method: 'GET', params, signal },
    options,
  );
};

export const getItemCategoriesQueryKey = (params?: ItemCategoriesParams) => {
  return [`/api/admin/item_categories`, ...(params ? [params] : [])] as const;
};

export const getItemCategoriesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategories>>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemCategoriesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemCategories>>> = ({
    signal,
  }) => itemCategories(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof itemCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemCategoriesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemCategories>>
>;
export type ItemCategoriesInfiniteQueryError = ErrorType<void>;

export function useItemCategoriesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategories>>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemCategoriesParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemCategories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategoriesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategories>>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemCategories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategoriesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategories>>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item categories
 */

export function useItemCategoriesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategories>>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemCategoriesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemCategoriesQueryOptions = <
  TData = Awaited<ReturnType<typeof itemCategories>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemCategories>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemCategoriesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemCategories>>> = ({
    signal,
  }) => itemCategories(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof itemCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemCategoriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemCategories>>
>;
export type ItemCategoriesQueryError = ErrorType<void>;

export function useItemCategories<
  TData = Awaited<ReturnType<typeof itemCategories>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemCategoriesParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemCategories>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemCategories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategories<
  TData = Awaited<ReturnType<typeof itemCategories>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemCategories>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemCategories>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategories<
  TData = Awaited<ReturnType<typeof itemCategories>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemCategories>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item categories
 */

export function useItemCategories<
  TData = Awaited<ReturnType<typeof itemCategories>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemCategories>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemCategoriesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemCategoriesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof itemCategories>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemCategoriesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemCategories>>> = ({
    signal,
  }) => itemCategories(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof itemCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemCategoriesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemCategories>>
>;
export type ItemCategoriesSuspenseQueryError = ErrorType<void>;

export function useItemCategoriesSuspense<
  TData = Awaited<ReturnType<typeof itemCategories>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemCategoriesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategoriesSuspense<
  TData = Awaited<ReturnType<typeof itemCategories>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategoriesSuspense<
  TData = Awaited<ReturnType<typeof itemCategories>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item categories
 */

export function useItemCategoriesSuspense<
  TData = Awaited<ReturnType<typeof itemCategories>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemCategoriesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemCategoriesSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategories>>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemCategoriesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemCategories>>> = ({
    signal,
  }) => itemCategories(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof itemCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemCategoriesSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemCategories>>
>;
export type ItemCategoriesSuspenseInfiniteQueryError = ErrorType<void>;

export function useItemCategoriesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategories>>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemCategoriesParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategoriesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategories>>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategoriesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategories>>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item categories
 */

export function useItemCategoriesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategories>>>,
  TError = ErrorType<void>,
>(
  params?: ItemCategoriesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategories>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemCategoriesSuspenseInfiniteQueryOptions(
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create item category
 */
export const createItemCategory = (
  createItemCategoryBody: BodyType<CreateItemCategoryBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ItemCategory>(
    {
      url: `/api/admin/item_categories`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createItemCategoryBody,
    },
    options,
  );
};

export const getCreateItemCategoryMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createItemCategory>>,
    TError,
    { data: BodyType<CreateItemCategoryBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createItemCategory>>,
  TError,
  { data: BodyType<CreateItemCategoryBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createItemCategory>>,
    { data: BodyType<CreateItemCategoryBody> }
  > = (props) => {
    const { data } = props ?? {};

    return createItemCategory(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateItemCategoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof createItemCategory>>
>;
export type CreateItemCategoryMutationBody = BodyType<CreateItemCategoryBody>;
export type CreateItemCategoryMutationError = ErrorType<void | Errors>;

/**
 * @summary Create item category
 */
export const useCreateItemCategory = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createItemCategory>>,
    TError,
    { data: BodyType<CreateItemCategoryBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createItemCategory>>,
  TError,
  { data: BodyType<CreateItemCategoryBody> },
  TContext
> => {
  const mutationOptions = getCreateItemCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch item category
 */
export const itemCategory = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ItemCategory>(
    { url: `/api/admin/item_categories/${id}`, method: 'GET', signal },
    options,
  );
};

export const getItemCategoryQueryKey = (id: number) => {
  return [`/api/admin/item_categories/${id}`] as const;
};

export const getItemCategoryInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategory>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemCategoryQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemCategory>>> = ({
    signal,
  }) => itemCategory(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof itemCategory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemCategoryInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemCategory>>
>;
export type ItemCategoryInfiniteQueryError = ErrorType<void>;

export function useItemCategoryInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategory>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemCategory>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategoryInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategory>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemCategory>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategoryInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategory>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item category
 */

export function useItemCategoryInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategory>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemCategoryInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemCategoryQueryOptions = <
  TData = Awaited<ReturnType<typeof itemCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemCategory>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemCategoryQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemCategory>>> = ({
    signal,
  }) => itemCategory(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof itemCategory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemCategoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemCategory>>
>;
export type ItemCategoryQueryError = ErrorType<void>;

export function useItemCategory<
  TData = Awaited<ReturnType<typeof itemCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemCategory>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemCategory>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategory<
  TData = Awaited<ReturnType<typeof itemCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemCategory>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemCategory>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategory<
  TData = Awaited<ReturnType<typeof itemCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemCategory>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item category
 */

export function useItemCategory<
  TData = Awaited<ReturnType<typeof itemCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemCategory>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemCategoryQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemCategorySuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof itemCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemCategoryQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemCategory>>> = ({
    signal,
  }) => itemCategory(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof itemCategory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemCategorySuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemCategory>>
>;
export type ItemCategorySuspenseQueryError = ErrorType<void>;

export function useItemCategorySuspense<
  TData = Awaited<ReturnType<typeof itemCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategorySuspense<
  TData = Awaited<ReturnType<typeof itemCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategorySuspense<
  TData = Awaited<ReturnType<typeof itemCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item category
 */

export function useItemCategorySuspense<
  TData = Awaited<ReturnType<typeof itemCategory>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemCategorySuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemCategorySuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategory>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemCategoryQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemCategory>>> = ({
    signal,
  }) => itemCategory(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof itemCategory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemCategorySuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemCategory>>
>;
export type ItemCategorySuspenseInfiniteQueryError = ErrorType<void>;

export function useItemCategorySuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategory>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategorySuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategory>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemCategorySuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategory>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item category
 */

export function useItemCategorySuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemCategory>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemCategory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemCategorySuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update item category
 */
export const updateItemCategory = (
  id: number,
  updateItemCategoryBody: BodyType<UpdateItemCategoryBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ItemCategory>(
    {
      url: `/api/admin/item_categories/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateItemCategoryBody,
    },
    options,
  );
};

export const getUpdateItemCategoryMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateItemCategory>>,
    TError,
    { id: number; data: BodyType<UpdateItemCategoryBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateItemCategory>>,
  TError,
  { id: number; data: BodyType<UpdateItemCategoryBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateItemCategory>>,
    { id: number; data: BodyType<UpdateItemCategoryBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateItemCategory(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateItemCategoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateItemCategory>>
>;
export type UpdateItemCategoryMutationBody = BodyType<UpdateItemCategoryBody>;
export type UpdateItemCategoryMutationError = ErrorType<void | Errors>;

/**
 * @summary Update item category
 */
export const useUpdateItemCategory = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateItemCategory>>,
    TError,
    { id: number; data: BodyType<UpdateItemCategoryBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateItemCategory>>,
  TError,
  { id: number; data: BodyType<UpdateItemCategoryBody> },
  TContext
> => {
  const mutationOptions = getUpdateItemCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Remove item category
 */
export const deleteItemCategory = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/admin/item_categories/${id}`, method: 'DELETE' },
    options,
  );
};

export const getDeleteItemCategoryMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteItemCategory>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteItemCategory>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteItemCategory>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteItemCategory(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteItemCategoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteItemCategory>>
>;

export type DeleteItemCategoryMutationError = ErrorType<void>;

/**
 * @summary Remove item category
 */
export const useDeleteItemCategory = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteItemCategory>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteItemCategory>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteItemCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch item features
 */
export const itemFeatures = (
  params?: ItemFeaturesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ItemFeatures200>(
    { url: `/api/admin/item_features`, method: 'GET', params, signal },
    options,
  );
};

export const getItemFeaturesQueryKey = (params?: ItemFeaturesParams) => {
  return [`/api/admin/item_features`, ...(params ? [params] : [])] as const;
};

export const getItemFeaturesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeatures>>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemFeaturesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemFeatures>>> = ({
    signal,
  }) => itemFeatures(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof itemFeatures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemFeaturesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemFeatures>>
>;
export type ItemFeaturesInfiniteQueryError = ErrorType<void>;

export function useItemFeaturesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeatures>>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemFeaturesParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemFeatures>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeaturesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeatures>>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemFeatures>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeaturesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeatures>>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item features
 */

export function useItemFeaturesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeatures>>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemFeaturesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemFeaturesQueryOptions = <
  TData = Awaited<ReturnType<typeof itemFeatures>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemFeatures>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemFeaturesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemFeatures>>> = ({
    signal,
  }) => itemFeatures(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof itemFeatures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemFeaturesQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemFeatures>>
>;
export type ItemFeaturesQueryError = ErrorType<void>;

export function useItemFeatures<
  TData = Awaited<ReturnType<typeof itemFeatures>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemFeaturesParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemFeatures>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemFeatures>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeatures<
  TData = Awaited<ReturnType<typeof itemFeatures>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemFeatures>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemFeatures>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeatures<
  TData = Awaited<ReturnType<typeof itemFeatures>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemFeatures>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item features
 */

export function useItemFeatures<
  TData = Awaited<ReturnType<typeof itemFeatures>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemFeatures>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemFeaturesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemFeaturesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof itemFeatures>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemFeaturesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemFeatures>>> = ({
    signal,
  }) => itemFeatures(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof itemFeatures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemFeaturesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemFeatures>>
>;
export type ItemFeaturesSuspenseQueryError = ErrorType<void>;

export function useItemFeaturesSuspense<
  TData = Awaited<ReturnType<typeof itemFeatures>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemFeaturesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeaturesSuspense<
  TData = Awaited<ReturnType<typeof itemFeatures>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeaturesSuspense<
  TData = Awaited<ReturnType<typeof itemFeatures>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item features
 */

export function useItemFeaturesSuspense<
  TData = Awaited<ReturnType<typeof itemFeatures>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemFeaturesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemFeaturesSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeatures>>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemFeaturesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemFeatures>>> = ({
    signal,
  }) => itemFeatures(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof itemFeatures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemFeaturesSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemFeatures>>
>;
export type ItemFeaturesSuspenseInfiniteQueryError = ErrorType<void>;

export function useItemFeaturesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeatures>>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemFeaturesParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeaturesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeatures>>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeaturesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeatures>>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item features
 */

export function useItemFeaturesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeatures>>>,
  TError = ErrorType<void>,
>(
  params?: ItemFeaturesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeatures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemFeaturesSuspenseInfiniteQueryOptions(
    params,
    options,
  );

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Fetch item feature
 */
export const itemFeature = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ItemFeature>(
    { url: `/api/admin/item_features/${id}`, method: 'GET', signal },
    options,
  );
};

export const getItemFeatureQueryKey = (id: number) => {
  return [`/api/admin/item_features/${id}`] as const;
};

export const getItemFeatureInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeature>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemFeatureQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemFeature>>> = ({
    signal,
  }) => itemFeature(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof itemFeature>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemFeatureInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemFeature>>
>;
export type ItemFeatureInfiniteQueryError = ErrorType<void>;

export function useItemFeatureInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeature>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemFeature>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeatureInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeature>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemFeature>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeatureInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeature>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item feature
 */

export function useItemFeatureInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeature>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemFeatureInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemFeatureQueryOptions = <
  TData = Awaited<ReturnType<typeof itemFeature>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemFeature>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemFeatureQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemFeature>>> = ({
    signal,
  }) => itemFeature(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof itemFeature>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemFeatureQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemFeature>>
>;
export type ItemFeatureQueryError = ErrorType<void>;

export function useItemFeature<
  TData = Awaited<ReturnType<typeof itemFeature>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemFeature>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemFeature>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeature<
  TData = Awaited<ReturnType<typeof itemFeature>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemFeature>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof itemFeature>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeature<
  TData = Awaited<ReturnType<typeof itemFeature>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemFeature>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item feature
 */

export function useItemFeature<
  TData = Awaited<ReturnType<typeof itemFeature>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof itemFeature>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemFeatureQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemFeatureSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof itemFeature>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemFeatureQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemFeature>>> = ({
    signal,
  }) => itemFeature(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof itemFeature>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemFeatureSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemFeature>>
>;
export type ItemFeatureSuspenseQueryError = ErrorType<void>;

export function useItemFeatureSuspense<
  TData = Awaited<ReturnType<typeof itemFeature>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeatureSuspense<
  TData = Awaited<ReturnType<typeof itemFeature>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeatureSuspense<
  TData = Awaited<ReturnType<typeof itemFeature>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item feature
 */

export function useItemFeatureSuspense<
  TData = Awaited<ReturnType<typeof itemFeature>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemFeatureSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemFeatureSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeature>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemFeatureQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof itemFeature>>> = ({
    signal,
  }) => itemFeature(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof itemFeature>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemFeatureSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof itemFeature>>
>;
export type ItemFeatureSuspenseInfiniteQueryError = ErrorType<void>;

export function useItemFeatureSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeature>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeatureSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeature>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemFeatureSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeature>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item feature
 */

export function useItemFeatureSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof itemFeature>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof itemFeature>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemFeatureSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create item image
 */
export const createItemImage = (
  createItemImageBody: BodyType<CreateItemImageBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = new FormData();
  formData.append('item_id', createItemImageBody.item_id.toString());
  formData.append('image', createItemImageBody.image);

  return customInstance<Item>(
    {
      url: `/api/admin/item_images`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export const getCreateItemImageMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createItemImage>>,
    TError,
    { data: BodyType<CreateItemImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createItemImage>>,
  TError,
  { data: BodyType<CreateItemImageBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createItemImage>>,
    { data: BodyType<CreateItemImageBody> }
  > = (props) => {
    const { data } = props ?? {};

    return createItemImage(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateItemImageMutationResult = NonNullable<
  Awaited<ReturnType<typeof createItemImage>>
>;
export type CreateItemImageMutationBody = BodyType<CreateItemImageBody>;
export type CreateItemImageMutationError = ErrorType<void>;

/**
 * @summary Create item image
 */
export const useCreateItemImage = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createItemImage>>,
    TError,
    { data: BodyType<CreateItemImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createItemImage>>,
  TError,
  { data: BodyType<CreateItemImageBody> },
  TContext
> => {
  const mutationOptions = getCreateItemImageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update item image
 */
export const updateItemImage = (
  id: number,
  updateItemImageBody: BodyType<UpdateItemImageBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = new FormData();
  formData.append('image', updateItemImageBody.image);

  return customInstance<Item>(
    {
      url: `/api/admin/item_images/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export const getUpdateItemImageMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateItemImage>>,
    TError,
    { id: number; data: BodyType<UpdateItemImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateItemImage>>,
  TError,
  { id: number; data: BodyType<UpdateItemImageBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateItemImage>>,
    { id: number; data: BodyType<UpdateItemImageBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateItemImage(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateItemImageMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateItemImage>>
>;
export type UpdateItemImageMutationBody = BodyType<UpdateItemImageBody>;
export type UpdateItemImageMutationError = ErrorType<void>;

/**
 * @summary Update item image
 */
export const useUpdateItemImage = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateItemImage>>,
    TError,
    { id: number; data: BodyType<UpdateItemImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateItemImage>>,
  TError,
  { id: number; data: BodyType<UpdateItemImageBody> },
  TContext
> => {
  const mutationOptions = getUpdateItemImageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete item image
 */
export const deleteItemImage = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/admin/item_images/${id}`, method: 'DELETE' },
    options,
  );
};

export const getDeleteItemImageMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteItemImage>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteItemImage>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteItemImage>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteItemImage(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteItemImageMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteItemImage>>
>;

export type DeleteItemImageMutationError = ErrorType<void>;

/**
 * @summary Delete item image
 */
export const useDeleteItemImage = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteItemImage>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteItemImage>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteItemImageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update item image position
 */
export const itemImagePosition = (
  id: number,
  itemImagePositionBody: BodyType<ItemImagePositionBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Item>(
    {
      url: `/api/admin/item_images/${id}/position`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: itemImagePositionBody,
    },
    options,
  );
};

export const getItemImagePositionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof itemImagePosition>>,
    TError,
    { id: number; data: BodyType<ItemImagePositionBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof itemImagePosition>>,
  TError,
  { id: number; data: BodyType<ItemImagePositionBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof itemImagePosition>>,
    { id: number; data: BodyType<ItemImagePositionBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return itemImagePosition(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ItemImagePositionMutationResult = NonNullable<
  Awaited<ReturnType<typeof itemImagePosition>>
>;
export type ItemImagePositionMutationBody = BodyType<ItemImagePositionBody>;
export type ItemImagePositionMutationError = ErrorType<void>;

/**
 * @summary Update item image position
 */
export const useItemImagePosition = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof itemImagePosition>>,
    TError,
    { id: number; data: BodyType<ItemImagePositionBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof itemImagePosition>>,
  TError,
  { id: number; data: BodyType<ItemImagePositionBody> },
  TContext
> => {
  const mutationOptions = getItemImagePositionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch items
 */
export const items = (
  params?: ItemsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Items200>(
    { url: `/api/admin/items`, method: 'GET', params, signal },
    options,
  );
};

export const getItemsQueryKey = (params?: ItemsParams) => {
  return [`/api/admin/items`, ...(params ? [params] : [])] as const;
};

export const getItemsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof items>>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof items>>> = ({
    signal,
  }) => items(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof items>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof items>>
>;
export type ItemsInfiniteQueryError = ErrorType<void>;

export function useItemsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof items>>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemsParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof items>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof items>>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof items>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof items>>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch items
 */

export function useItemsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof items>>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemsQueryOptions = <
  TData = Awaited<ReturnType<typeof items>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof items>>> = ({
    signal,
  }) => items(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof items>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemsQueryResult = NonNullable<Awaited<ReturnType<typeof items>>>;
export type ItemsQueryError = ErrorType<void>;

export function useItems<
  TData = Awaited<ReturnType<typeof items>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof items>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItems<
  TData = Awaited<ReturnType<typeof items>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof items>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItems<
  TData = Awaited<ReturnType<typeof items>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch items
 */

export function useItems<
  TData = Awaited<ReturnType<typeof items>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof items>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof items>>> = ({
    signal,
  }) => items(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof items>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof items>>
>;
export type ItemsSuspenseQueryError = ErrorType<void>;

export function useItemsSuspense<
  TData = Awaited<ReturnType<typeof items>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemsSuspense<
  TData = Awaited<ReturnType<typeof items>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemsSuspense<
  TData = Awaited<ReturnType<typeof items>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch items
 */

export function useItemsSuspense<
  TData = Awaited<ReturnType<typeof items>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof items>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemsSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemsSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof items>>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof items>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof items>>> = ({
    signal,
  }) => items(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof items>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemsSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof items>>
>;
export type ItemsSuspenseInfiniteQueryError = ErrorType<void>;

export function useItemsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof items>>>,
  TError = ErrorType<void>,
>(
  params: undefined | ItemsParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof items>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof items>>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof items>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof items>>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof items>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch items
 */

export function useItemsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof items>>>,
  TError = ErrorType<void>,
>(
  params?: ItemsParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof items>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemsSuspenseInfiniteQueryOptions(params, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Fetch item
 */
export const item = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Item>(
    { url: `/api/admin/items/${id}`, method: 'GET', signal },
    options,
  );
};

export const getItemQueryKey = (id: number) => {
  return [`/api/admin/items/${id}`] as const;
};

export const getItemInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof item>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof item>>> = ({
    signal,
  }) => item(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof item>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof item>>
>;
export type ItemInfiniteQueryError = ErrorType<void>;

export function useItemInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof item>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof item>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof item>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof item>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof item>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item
 */

export function useItemInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof item>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemQueryOptions = <
  TData = Awaited<ReturnType<typeof item>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof item>>> = ({
    signal,
  }) => item(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ItemQueryResult = NonNullable<Awaited<ReturnType<typeof item>>>;
export type ItemQueryError = ErrorType<void>;

export function useItem<
  TData = Awaited<ReturnType<typeof item>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof item>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItem<
  TData = Awaited<ReturnType<typeof item>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof item>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItem<
  TData = Awaited<ReturnType<typeof item>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item
 */

export function useItem<
  TData = Awaited<ReturnType<typeof item>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof item>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof item>>> = ({
    signal,
  }) => item(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof item>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof item>>
>;
export type ItemSuspenseQueryError = ErrorType<void>;

export function useItemSuspense<
  TData = Awaited<ReturnType<typeof item>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemSuspense<
  TData = Awaited<ReturnType<typeof item>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemSuspense<
  TData = Awaited<ReturnType<typeof item>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item
 */

export function useItemSuspense<
  TData = Awaited<ReturnType<typeof item>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof item>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getItemSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof item>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof item>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getItemQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof item>>> = ({
    signal,
  }) => item(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof item>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ItemSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof item>>
>;
export type ItemSuspenseInfiniteQueryError = ErrorType<void>;

export function useItemSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof item>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof item>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof item>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof item>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useItemSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof item>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof item>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch item
 */

export function useItemSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof item>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof item>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getItemSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update item
 */
export const updateItem = (
  id: number,
  crudItem: BodyType<CrudItem>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = new FormData();
  formData.append('name', crudItem.name);
  formData.append('description', crudItem.description);
  if (crudItem.dimensions !== undefined) {
    formData.append('dimensions', JSON.stringify(crudItem.dimensions));
  }
  if (crudItem['dimensions[width]'] !== undefined) {
    formData.append(
      'dimensions[width]',
      crudItem['dimensions[width]'].toString(),
    );
  }
  if (crudItem['dimensions[height]'] !== undefined) {
    formData.append(
      'dimensions[height]',
      crudItem['dimensions[height]'].toString(),
    );
  }
  if (crudItem['dimensions[depth]'] !== undefined) {
    formData.append(
      'dimensions[depth]',
      crudItem['dimensions[depth]'].toString(),
    );
  }
  if (crudItem.chaindrop !== undefined && crudItem.chaindrop !== null) {
    formData.append('chaindrop', crudItem.chaindrop.toString());
  }
  formData.append('color', crudItem.color);
  formData.append('condition', crudItem.condition);
  formData.append('year', crudItem.year.toString());
  formData.append('receipt', crudItem.receipt.toString());
  if (crudItem.seller_protection !== undefined) {
    formData.append('seller_protection', crudItem.seller_protection.toString());
  }
  formData.append('offers_allowed', crudItem.offers_allowed.toString());
  formData.append('price', crudItem.price.toString());
  formData.append('brand_id', crudItem.brand_id.toString());
  formData.append('category_id', crudItem.category_id.toString());
  crudItem.images.forEach((value) =>
    formData.append('images', JSON.stringify(value)),
  );
  if (crudItem.comment !== undefined && crudItem.comment !== null) {
    formData.append('comment', crudItem.comment);
  }

  return customInstance<Item>(
    {
      url: `/api/admin/items/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export const getUpdateItemMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateItem>>,
    TError,
    { id: number; data: BodyType<CrudItem> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateItem>>,
  TError,
  { id: number; data: BodyType<CrudItem> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateItem>>,
    { id: number; data: BodyType<CrudItem> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateItem(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateItemMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateItem>>
>;
export type UpdateItemMutationBody = BodyType<CrudItem>;
export type UpdateItemMutationError = ErrorType<void | Errors>;

/**
 * @summary Update item
 */
export const useUpdateItem = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateItem>>,
    TError,
    { id: number; data: BodyType<CrudItem> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateItem>>,
  TError,
  { id: number; data: BodyType<CrudItem> },
  TContext
> => {
  const mutationOptions = getUpdateItemMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Remove item
 */
export const deleteItem = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/admin/items/${id}`, method: 'DELETE' },
    options,
  );
};

export const getDeleteItemMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteItem>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteItem>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteItem>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteItem(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteItemMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteItem>>
>;

export type DeleteItemMutationError = ErrorType<void>;

/**
 * @summary Remove item
 */
export const useDeleteItem = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteItem>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteItem>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteItemMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Set item status
 */
export const setItemStatus = (
  id: number,
  setItemStatusBody: BodyType<SetItemStatusBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Item>(
    {
      url: `/api/admin/items/${id}/set_status`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: setItemStatusBody,
    },
    options,
  );
};

export const getSetItemStatusMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setItemStatus>>,
    TError,
    { id: number; data: BodyType<SetItemStatusBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setItemStatus>>,
  TError,
  { id: number; data: BodyType<SetItemStatusBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setItemStatus>>,
    { id: number; data: BodyType<SetItemStatusBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return setItemStatus(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetItemStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof setItemStatus>>
>;
export type SetItemStatusMutationBody = BodyType<SetItemStatusBody>;
export type SetItemStatusMutationError = ErrorType<void | Errors>;

/**
 * @summary Set item status
 */
export const useSetItemStatus = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setItemStatus>>,
    TError,
    { id: number; data: BodyType<SetItemStatusBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setItemStatus>>,
  TError,
  { id: number; data: BodyType<SetItemStatusBody> },
  TContext
> => {
  const mutationOptions = getSetItemStatusMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Feature item
 */
export const featureItem = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Item>(
    { url: `/api/admin/items/${id}/feature`, method: 'POST' },
    options,
  );
};

export const getFeatureItemMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof featureItem>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof featureItem>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof featureItem>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return featureItem(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FeatureItemMutationResult = NonNullable<
  Awaited<ReturnType<typeof featureItem>>
>;

export type FeatureItemMutationError = ErrorType<void>;

/**
 * @summary Feature item
 */
export const useFeatureItem = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof featureItem>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof featureItem>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getFeatureItemMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Add expert to item
 */
export const addExpert = (
  id: number,
  expertId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Item>(
    { url: `/api/admin/items/${id}/add_expert/${expertId}`, method: 'POST' },
    options,
  );
};

export const getAddExpertMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addExpert>>,
    TError,
    { id: number; expertId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addExpert>>,
  TError,
  { id: number; expertId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addExpert>>,
    { id: number; expertId: number }
  > = (props) => {
    const { id, expertId } = props ?? {};

    return addExpert(id, expertId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddExpertMutationResult = NonNullable<
  Awaited<ReturnType<typeof addExpert>>
>;

export type AddExpertMutationError = ErrorType<void>;

/**
 * @summary Add expert to item
 */
export const useAddExpert = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addExpert>>,
    TError,
    { id: number; expertId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addExpert>>,
  TError,
  { id: number; expertId: number },
  TContext
> => {
  const mutationOptions = getAddExpertMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch orders
 */
export const orders = (
  params?: OrdersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Orders200>(
    { url: `/api/admin/orders`, method: 'GET', params, signal },
    options,
  );
};

export const getOrdersQueryKey = (params?: OrdersParams) => {
  return [`/api/admin/orders`, ...(params ? [params] : [])] as const;
};

export const getOrdersInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof orders>>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof orders>>> = ({
    signal,
  }) => orders(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof orders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrdersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof orders>>
>;
export type OrdersInfiniteQueryError = ErrorType<void>;

export function useOrdersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof orders>>>,
  TError = ErrorType<void>,
>(
  params: undefined | OrdersParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof orders>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrdersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof orders>>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof orders>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrdersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof orders>>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch orders
 */

export function useOrdersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof orders>>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getOrdersInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof orders>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof orders>>> = ({
    signal,
  }) => orders(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof orders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrdersQueryResult = NonNullable<Awaited<ReturnType<typeof orders>>>;
export type OrdersQueryError = ErrorType<void>;

export function useOrders<
  TData = Awaited<ReturnType<typeof orders>>,
  TError = ErrorType<void>,
>(
  params: undefined | OrdersParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof orders>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrders<
  TData = Awaited<ReturnType<typeof orders>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof orders>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrders<
  TData = Awaited<ReturnType<typeof orders>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch orders
 */

export function useOrders<
  TData = Awaited<ReturnType<typeof orders>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getOrdersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getOrdersSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof orders>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof orders>>> = ({
    signal,
  }) => orders(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof orders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrdersSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof orders>>
>;
export type OrdersSuspenseQueryError = ErrorType<void>;

export function useOrdersSuspense<
  TData = Awaited<ReturnType<typeof orders>>,
  TError = ErrorType<void>,
>(
  params: undefined | OrdersParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrdersSuspense<
  TData = Awaited<ReturnType<typeof orders>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrdersSuspense<
  TData = Awaited<ReturnType<typeof orders>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch orders
 */

export function useOrdersSuspense<
  TData = Awaited<ReturnType<typeof orders>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof orders>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getOrdersSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getOrdersSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof orders>>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof orders>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof orders>>> = ({
    signal,
  }) => orders(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof orders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrdersSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof orders>>
>;
export type OrdersSuspenseInfiniteQueryError = ErrorType<void>;

export function useOrdersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof orders>>>,
  TError = ErrorType<void>,
>(
  params: undefined | OrdersParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof orders>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrdersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof orders>>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof orders>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrdersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof orders>>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof orders>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch orders
 */

export function useOrdersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof orders>>>,
  TError = ErrorType<void>,
>(
  params?: OrdersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof orders>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getOrdersSuspenseInfiniteQueryOptions(params, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Fetch order
 */
export const order = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Order>(
    { url: `/api/admin/orders/${id}`, method: 'GET', signal },
    options,
  );
};

export const getOrderQueryKey = (id: number) => {
  return [`/api/admin/orders/${id}`] as const;
};

export const getOrderInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof order>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrderQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof order>>> = ({
    signal,
  }) => order(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof order>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrderInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof order>>
>;
export type OrderInfiniteQueryError = ErrorType<void>;

export function useOrderInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof order>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof order>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrderInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof order>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof order>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrderInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof order>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch order
 */

export function useOrderInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof order>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getOrderInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getOrderQueryOptions = <
  TData = Awaited<ReturnType<typeof order>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrderQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof order>>> = ({
    signal,
  }) => order(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type OrderQueryResult = NonNullable<Awaited<ReturnType<typeof order>>>;
export type OrderQueryError = ErrorType<void>;

export function useOrder<
  TData = Awaited<ReturnType<typeof order>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof order>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrder<
  TData = Awaited<ReturnType<typeof order>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof order>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrder<
  TData = Awaited<ReturnType<typeof order>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch order
 */

export function useOrder<
  TData = Awaited<ReturnType<typeof order>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getOrderQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getOrderSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof order>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrderQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof order>>> = ({
    signal,
  }) => order(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof order>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrderSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof order>>
>;
export type OrderSuspenseQueryError = ErrorType<void>;

export function useOrderSuspense<
  TData = Awaited<ReturnType<typeof order>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrderSuspense<
  TData = Awaited<ReturnType<typeof order>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrderSuspense<
  TData = Awaited<ReturnType<typeof order>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch order
 */

export function useOrderSuspense<
  TData = Awaited<ReturnType<typeof order>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof order>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getOrderSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getOrderSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof order>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof order>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrderQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof order>>> = ({
    signal,
  }) => order(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof order>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrderSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof order>>
>;
export type OrderSuspenseInfiniteQueryError = ErrorType<void>;

export function useOrderSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof order>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof order>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrderSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof order>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof order>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useOrderSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof order>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof order>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch order
 */

export function useOrderSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof order>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof order>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getOrderSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update order
 */
export const updateOrder = (
  id: number,
  updateOrderBody: BodyType<UpdateOrderBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Order>(
    {
      url: `/api/admin/orders/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateOrderBody,
    },
    options,
  );
};

export const getUpdateOrderMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateOrder>>,
    TError,
    { id: number; data: BodyType<UpdateOrderBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateOrder>>,
  TError,
  { id: number; data: BodyType<UpdateOrderBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateOrder>>,
    { id: number; data: BodyType<UpdateOrderBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateOrder(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateOrderMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateOrder>>
>;
export type UpdateOrderMutationBody = BodyType<UpdateOrderBody>;
export type UpdateOrderMutationError = ErrorType<void>;

/**
 * @summary Update order
 */
export const useUpdateOrder = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateOrder>>,
    TError,
    { id: number; data: BodyType<UpdateOrderBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateOrder>>,
  TError,
  { id: number; data: BodyType<UpdateOrderBody> },
  TContext
> => {
  const mutationOptions = getUpdateOrderMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Refund order
 */
export const refund = (
  id: number,
  refundBody: BodyType<RefundBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Order>(
    {
      url: `/api/admin/orders/${id}/refund`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: refundBody,
    },
    options,
  );
};

export const getRefundMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof refund>>,
    TError,
    { id: number; data: BodyType<RefundBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof refund>>,
  TError,
  { id: number; data: BodyType<RefundBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof refund>>,
    { id: number; data: BodyType<RefundBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return refund(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RefundMutationResult = NonNullable<
  Awaited<ReturnType<typeof refund>>
>;
export type RefundMutationBody = BodyType<RefundBody>;
export type RefundMutationError = ErrorType<void>;

/**
 * @summary Refund order
 */
export const useRefund = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof refund>>,
    TError,
    { id: number; data: BodyType<RefundBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof refund>>,
  TError,
  { id: number; data: BodyType<RefundBody> },
  TContext
> => {
  const mutationOptions = getRefundMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Payout order
 */
export const payout = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Order>(
    { url: `/api/admin/orders/${id}/payout`, method: 'POST' },
    options,
  );
};

export const getPayoutMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof payout>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof payout>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof payout>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return payout(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PayoutMutationResult = NonNullable<
  Awaited<ReturnType<typeof payout>>
>;

export type PayoutMutationError = ErrorType<void>;

/**
 * @summary Payout order
 */
export const usePayout = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof payout>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof payout>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getPayoutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delay payout order
 */
export const delayPayout = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Order>(
    { url: `/api/admin/orders/${id}/delay_payout`, method: 'POST' },
    options,
  );
};

export const getDelayPayoutMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof delayPayout>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof delayPayout>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof delayPayout>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return delayPayout(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DelayPayoutMutationResult = NonNullable<
  Awaited<ReturnType<typeof delayPayout>>
>;

export type DelayPayoutMutationError = ErrorType<void>;

/**
 * @summary Delay payout order
 */
export const useDelayPayout = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof delayPayout>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof delayPayout>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDelayPayoutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch pages
 */
export const pages = (
  params?: PagesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Pages200>(
    { url: `/api/admin/pages`, method: 'GET', params, signal },
    options,
  );
};

export const getPagesQueryKey = (params?: PagesParams) => {
  return [`/api/admin/pages`, ...(params ? [params] : [])] as const;
};

export const getPagesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPagesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pages>>> = ({
    signal,
  }) => pages(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pages>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PagesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof pages>>
>;
export type PagesInfiniteQueryError = ErrorType<void>;

export function usePagesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params: undefined | PagesParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof pages>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePagesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof pages>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePagesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch pages
 */

export function usePagesInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPagesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPagesQueryOptions = <
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPagesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pages>>> = ({
    signal,
  }) => pages(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pages>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PagesQueryResult = NonNullable<Awaited<ReturnType<typeof pages>>>;
export type PagesQueryError = ErrorType<void>;

export function usePages<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params: undefined | PagesParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof pages>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePages<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof pages>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePages<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch pages
 */

export function usePages<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPagesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPagesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPagesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pages>>> = ({
    signal,
  }) => pages(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof pages>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PagesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof pages>>
>;
export type PagesSuspenseQueryError = ErrorType<void>;

export function usePagesSuspense<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params: undefined | PagesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePagesSuspense<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePagesSuspense<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch pages
 */

export function usePagesSuspense<
  TData = Awaited<ReturnType<typeof pages>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPagesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPagesSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPagesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pages>>> = ({
    signal,
  }) => pages(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof pages>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PagesSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof pages>>
>;
export type PagesSuspenseInfiniteQueryError = ErrorType<void>;

export function usePagesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params: undefined | PagesParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePagesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePagesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch pages
 */

export function usePagesSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof pages>>>,
  TError = ErrorType<void>,
>(
  params?: PagesParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof pages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPagesSuspenseInfiniteQueryOptions(params, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create page
 */
export const createPage = (
  createPageBody: BodyType<CreatePageBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Page>(
    {
      url: `/api/admin/pages`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createPageBody,
    },
    options,
  );
};

export const getCreatePageMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPage>>,
    TError,
    { data: BodyType<CreatePageBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPage>>,
  TError,
  { data: BodyType<CreatePageBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPage>>,
    { data: BodyType<CreatePageBody> }
  > = (props) => {
    const { data } = props ?? {};

    return createPage(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePageMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPage>>
>;
export type CreatePageMutationBody = BodyType<CreatePageBody>;
export type CreatePageMutationError = ErrorType<void | Errors>;

/**
 * @summary Create page
 */
export const useCreatePage = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPage>>,
    TError,
    { data: BodyType<CreatePageBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createPage>>,
  TError,
  { data: BodyType<CreatePageBody> },
  TContext
> => {
  const mutationOptions = getCreatePageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch page
 */
export const page = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Page>(
    { url: `/api/admin/pages/${id}`, method: 'GET', signal },
    options,
  );
};

export const getPageQueryKey = (id: number) => {
  return [`/api/admin/pages/${id}`] as const;
};

export const getPageInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPageQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof page>>> = ({
    signal,
  }) => page(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof page>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PageInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof page>>
>;
export type PageInfiniteQueryError = ErrorType<void>;

export function usePageInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof page>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePageInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof page>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePageInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch page
 */

export function usePageInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPageInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPageQueryOptions = <
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPageQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof page>>> = ({
    signal,
  }) => page(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type PageQueryResult = NonNullable<Awaited<ReturnType<typeof page>>>;
export type PageQueryError = ErrorType<void>;

export function usePage<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof page>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePage<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof page>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePage<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch page
 */

export function usePage<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPageQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPageSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPageQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof page>>> = ({
    signal,
  }) => page(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof page>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PageSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof page>>
>;
export type PageSuspenseQueryError = ErrorType<void>;

export function usePageSuspense<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePageSuspense<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePageSuspense<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch page
 */

export function usePageSuspense<
  TData = Awaited<ReturnType<typeof page>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof page>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPageSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPageSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof page>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPageQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof page>>> = ({
    signal,
  }) => page(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof page>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PageSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof page>>
>;
export type PageSuspenseInfiniteQueryError = ErrorType<void>;

export function usePageSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof page>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePageSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof page>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function usePageSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof page>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch page
 */

export function usePageSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof page>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof page>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getPageSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update page
 */
export const updatePage = (
  id: number,
  updatePageBody: BodyType<UpdatePageBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Page>(
    {
      url: `/api/admin/pages/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updatePageBody,
    },
    options,
  );
};

export const getUpdatePageMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePage>>,
    TError,
    { id: number; data: BodyType<UpdatePageBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePage>>,
  TError,
  { id: number; data: BodyType<UpdatePageBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePage>>,
    { id: number; data: BodyType<UpdatePageBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updatePage(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePageMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePage>>
>;
export type UpdatePageMutationBody = BodyType<UpdatePageBody>;
export type UpdatePageMutationError = ErrorType<void | Errors>;

/**
 * @summary Update page
 */
export const useUpdatePage = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePage>>,
    TError,
    { id: number; data: BodyType<UpdatePageBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePage>>,
  TError,
  { id: number; data: BodyType<UpdatePageBody> },
  TContext
> => {
  const mutationOptions = getUpdatePageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Remove page
 */
export const deletePage = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/admin/pages/${id}`, method: 'DELETE' },
    options,
  );
};

export const getDeletePageMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePage>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePage>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePage>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deletePage(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePageMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePage>>
>;

export type DeletePageMutationError = ErrorType<void>;

/**
 * @summary Remove page
 */
export const useDeletePage = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePage>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deletePage>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeletePageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch users
 */
export const users = (
  params?: UsersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Users200>(
    { url: `/api/admin/users`, method: 'GET', params, signal },
    options,
  );
};

export const getUsersQueryKey = (params?: UsersParams) => {
  return [`/api/admin/users`, ...(params ? [params] : [])] as const;
};

export const getUsersInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof users>>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof users>>> = ({
    signal,
  }) => users(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof users>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UsersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof users>>
>;
export type UsersInfiniteQueryError = ErrorType<void>;

export function useUsersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof users>>>,
  TError = ErrorType<void>,
>(
  params: undefined | UsersParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof users>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUsersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof users>>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof users>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUsersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof users>>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch users
 */

export function useUsersInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof users>>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getUsersInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof users>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof users>>> = ({
    signal,
  }) => users(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof users>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UsersQueryResult = NonNullable<Awaited<ReturnType<typeof users>>>;
export type UsersQueryError = ErrorType<void>;

export function useUsers<
  TData = Awaited<ReturnType<typeof users>>,
  TError = ErrorType<void>,
>(
  params: undefined | UsersParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof users>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUsers<
  TData = Awaited<ReturnType<typeof users>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof users>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUsers<
  TData = Awaited<ReturnType<typeof users>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch users
 */

export function useUsers<
  TData = Awaited<ReturnType<typeof users>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getUsersSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof users>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof users>>> = ({
    signal,
  }) => users(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof users>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UsersSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof users>>
>;
export type UsersSuspenseQueryError = ErrorType<void>;

export function useUsersSuspense<
  TData = Awaited<ReturnType<typeof users>>,
  TError = ErrorType<void>,
>(
  params: undefined | UsersParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUsersSuspense<
  TData = Awaited<ReturnType<typeof users>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUsersSuspense<
  TData = Awaited<ReturnType<typeof users>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch users
 */

export function useUsersSuspense<
  TData = Awaited<ReturnType<typeof users>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getUsersSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getUsersSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof users>>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof users>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof users>>> = ({
    signal,
  }) => users(params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof users>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UsersSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof users>>
>;
export type UsersSuspenseInfiniteQueryError = ErrorType<void>;

export function useUsersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof users>>>,
  TError = ErrorType<void>,
>(
  params: undefined | UsersParams,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof users>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUsersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof users>>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof users>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUsersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof users>>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof users>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch users
 */

export function useUsersSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof users>>>,
  TError = ErrorType<void>,
>(
  params?: UsersParams,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof users>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getUsersSuspenseInfiniteQueryOptions(params, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create user
 */
export const createUser = (
  createUserBody: BodyType<CreateUserBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/api/admin/users`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createUserBody,
    },
    options,
  );
};

export const getCreateUserMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: BodyType<CreateUserBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createUser>>,
  TError,
  { data: BodyType<CreateUserBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUser>>,
    { data: BodyType<CreateUserBody> }
  > = (props) => {
    const { data } = props ?? {};

    return createUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUser>>
>;
export type CreateUserMutationBody = BodyType<CreateUserBody>;
export type CreateUserMutationError = ErrorType<void | Errors>;

/**
 * @summary Create user
 */
export const useCreateUser = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: BodyType<CreateUserBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createUser>>,
  TError,
  { data: BodyType<CreateUserBody> },
  TContext
> => {
  const mutationOptions = getCreateUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch user
 */
export const user = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<User>(
    { url: `/api/admin/users/${id}`, method: 'GET', signal },
    options,
  );
};

export const getUserQueryKey = (id: number) => {
  return [`/api/admin/users/${id}`] as const;
};

export const getUserInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof user>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof user>>> = ({
    signal,
  }) => user(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof user>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof user>>
>;
export type UserInfiniteQueryError = ErrorType<void>;

export function useUserInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof user>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof user>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUserInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof user>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof user>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUserInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof user>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch user
 */

export function useUserInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof user>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getUserInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getUserQueryOptions = <
  TData = Awaited<ReturnType<typeof user>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof user>>> = ({
    signal,
  }) => user(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type UserQueryResult = NonNullable<Awaited<ReturnType<typeof user>>>;
export type UserQueryError = ErrorType<void>;

export function useUser<
  TData = Awaited<ReturnType<typeof user>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof user>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUser<
  TData = Awaited<ReturnType<typeof user>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof user>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUser<
  TData = Awaited<ReturnType<typeof user>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch user
 */

export function useUser<
  TData = Awaited<ReturnType<typeof user>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getUserQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getUserSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof user>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof user>>> = ({
    signal,
  }) => user(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof user>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof user>>
>;
export type UserSuspenseQueryError = ErrorType<void>;

export function useUserSuspense<
  TData = Awaited<ReturnType<typeof user>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUserSuspense<
  TData = Awaited<ReturnType<typeof user>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUserSuspense<
  TData = Awaited<ReturnType<typeof user>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch user
 */

export function useUserSuspense<
  TData = Awaited<ReturnType<typeof user>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof user>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getUserSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getUserSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof user>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof user>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof user>>> = ({
    signal,
  }) => user(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof user>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof user>>
>;
export type UserSuspenseInfiniteQueryError = ErrorType<void>;

export function useUserSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof user>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof user>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUserSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof user>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof user>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUserSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof user>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof user>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch user
 */

export function useUserSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof user>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof user>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getUserSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update user
 */
export const updateUser = (
  id: number,
  updateUserBody: BodyType<UpdateUserBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/api/admin/users/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateUserBody,
    },
    options,
  );
};

export const getUpdateUserMutationOptions = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { id: number; data: BodyType<UpdateUserBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUser>>,
  TError,
  { id: number; data: BodyType<UpdateUserBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUser>>,
    { id: number; data: BodyType<UpdateUserBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateUser(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUser>>
>;
export type UpdateUserMutationBody = BodyType<UpdateUserBody>;
export type UpdateUserMutationError = ErrorType<void | Errors>;

/**
 * @summary Update user
 */
export const useUpdateUser = <
  TError = ErrorType<void | Errors>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { id: number; data: BodyType<UpdateUserBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUser>>,
  TError,
  { id: number; data: BodyType<UpdateUserBody> },
  TContext
> => {
  const mutationOptions = getUpdateUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete user
 */
export const deleteUser = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/admin/users/${id}`, method: 'DELETE' },
    options,
  );
};

export const getDeleteUserMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUser>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUser>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUser>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteUser(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUser>>
>;

export type DeleteUserMutationError = ErrorType<void>;

/**
 * @summary Delete user
 */
export const useDeleteUser = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUser>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUser>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Set user as expert
 */
export const expertUser = (
  id: number,
  expertUserBody: BodyType<ExpertUserBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/api/admin/users/${id}/expert`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: expertUserBody,
    },
    options,
  );
};

export const getExpertUserMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expertUser>>,
    TError,
    { id: number; data: BodyType<ExpertUserBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof expertUser>>,
  TError,
  { id: number; data: BodyType<ExpertUserBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expertUser>>,
    { id: number; data: BodyType<ExpertUserBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return expertUser(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExpertUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof expertUser>>
>;
export type ExpertUserMutationBody = BodyType<ExpertUserBody>;
export type ExpertUserMutationError = ErrorType<void>;

/**
 * @summary Set user as expert
 */
export const useExpertUser = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expertUser>>,
    TError,
    { id: number; data: BodyType<ExpertUserBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof expertUser>>,
  TError,
  { id: number; data: BodyType<ExpertUserBody> },
  TContext
> => {
  const mutationOptions = getExpertUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Set user brands
 */
export const userBrands = (
  id: number,
  userBrandsBody: BodyType<UserBrandsBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/api/admin/users/${id}/brands`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: userBrandsBody,
    },
    options,
  );
};

export const getUserBrandsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userBrands>>,
    TError,
    { id: number; data: BodyType<UserBrandsBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userBrands>>,
  TError,
  { id: number; data: BodyType<UserBrandsBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userBrands>>,
    { id: number; data: BodyType<UserBrandsBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return userBrands(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserBrandsMutationResult = NonNullable<
  Awaited<ReturnType<typeof userBrands>>
>;
export type UserBrandsMutationBody = BodyType<UserBrandsBody>;
export type UserBrandsMutationError = ErrorType<void>;

/**
 * @summary Set user brands
 */
export const useUserBrands = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userBrands>>,
    TError,
    { id: number; data: BodyType<UserBrandsBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userBrands>>,
  TError,
  { id: number; data: BodyType<UserBrandsBody> },
  TContext
> => {
  const mutationOptions = getUserBrandsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch profile
 */
export const profile = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Profile>(
    { url: `/api/identity/profile`, method: 'GET', signal },
    options,
  );
};

export const getProfileQueryKey = () => {
  return [`/api/identity/profile`] as const;
};

export const getProfileInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProfileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof profile>>> = ({
    signal,
  }) => profile(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof profile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProfileInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof profile>>
>;
export type ProfileInfiniteQueryError = ErrorType<void>;

export function useProfileInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof profile>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfileInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof profile>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfileInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch profile
 */

export function useProfileInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getProfileInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProfileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof profile>>> = ({
    signal,
  }) => profile(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof profile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof profile>>
>;
export type ProfileQueryError = ErrorType<void>;

export function useProfile<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof profile>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfile<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof profile>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfile<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch profile
 */

export function useProfile<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getProfileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getProfileSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProfileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof profile>>> = ({
    signal,
  }) => profile(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof profile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProfileSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof profile>>
>;
export type ProfileSuspenseQueryError = ErrorType<void>;

export function useProfileSuspense<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfileSuspense<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfileSuspense<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch profile
 */

export function useProfileSuspense<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getProfileSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getProfileSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof profile>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProfileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof profile>>> = ({
    signal,
  }) => profile(requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof profile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProfileSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof profile>>
>;
export type ProfileSuspenseInfiniteQueryError = ErrorType<void>;

export function useProfileSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof profile>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfileSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof profile>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useProfileSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof profile>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch profile
 */

export function useProfileSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof profile>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof profile>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getProfileSuspenseInfiniteQueryOptions(options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update profile
 */
export const updateProfile = (
  updateProfileBody: BodyType<UpdateProfileBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Profile>(
    {
      url: `/api/identity/profile`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateProfileBody,
    },
    options,
  );
};

export const getUpdateProfileMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProfile>>,
    TError,
    { data: BodyType<UpdateProfileBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateProfile>>,
  TError,
  { data: BodyType<UpdateProfileBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateProfile>>,
    { data: BodyType<UpdateProfileBody> }
  > = (props) => {
    const { data } = props ?? {};

    return updateProfile(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateProfileMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateProfile>>
>;
export type UpdateProfileMutationBody = BodyType<UpdateProfileBody>;
export type UpdateProfileMutationError = ErrorType<void>;

/**
 * @summary Update profile
 */
export const useUpdateProfile = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProfile>>,
    TError,
    { data: BodyType<UpdateProfileBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateProfile>>,
  TError,
  { data: BodyType<UpdateProfileBody> },
  TContext
> => {
  const mutationOptions = getUpdateProfileMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Fetch sessions
 */
export const sessions = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Sessions>(
    { url: `/api/sessions`, method: 'GET', signal },
    options,
  );
};

export const getSessionsQueryKey = () => {
  return [`/api/sessions`] as const;
};

export const getSessionsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessions>>> = ({
    signal,
  }) => sessions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sessions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SessionsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sessions>>
>;
export type SessionsInfiniteQueryError = ErrorType<void>;

export function useSessionsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof sessions>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof sessions>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sessions
 */

export function useSessionsInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionsInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSessionsQueryOptions = <
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessions>>> = ({
    signal,
  }) => sessions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sessions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SessionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof sessions>>
>;
export type SessionsQueryError = ErrorType<void>;

export function useSessions<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof sessions>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessions<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof sessions>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessions<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sessions
 */

export function useSessions<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSessionsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessions>>> = ({
    signal,
  }) => sessions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof sessions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SessionsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof sessions>>
>;
export type SessionsSuspenseQueryError = ErrorType<void>;

export function useSessionsSuspense<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionsSuspense<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionsSuspense<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sessions
 */

export function useSessionsSuspense<
  TData = Awaited<ReturnType<typeof sessions>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof sessions>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSessionsSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof sessions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessions>>> = ({
    signal,
  }) => sessions(requestOptions, signal);

  return {
    queryKey,
    queryFn,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sessions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SessionsSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sessions>>
>;
export type SessionsSuspenseInfiniteQueryError = ErrorType<void>;

export function useSessionsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options: {
  query: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof sessions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof sessions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof sessions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch sessions
 */

export function useSessionsSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof sessions>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: Partial<
    UseSuspenseInfiniteQueryOptions<
      Awaited<ReturnType<typeof sessions>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionsSuspenseInfiniteQueryOptions(options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Fetch session
 */
export const session = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Session>(
    { url: `/api/sessions/${id}`, method: 'GET', signal },
    options,
  );
};

export const getSessionQueryKey = (id: number) => {
  return [`/api/sessions/${id}`] as const;
};

export const getSessionInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof session>>> = ({
    signal,
  }) => session(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof session>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SessionInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof session>>
>;
export type SessionInfiniteQueryError = ErrorType<void>;

export function useSessionInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof session>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof session>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch session
 */

export function useSessionInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSessionQueryOptions = <
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof session>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof session>>> = ({
    signal,
  }) => session(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof session>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type SessionQueryResult = NonNullable<
  Awaited<ReturnType<typeof session>>
>;
export type SessionQueryError = ErrorType<void>;

export function useSession<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof session>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof session>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSession<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof session>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof session>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSession<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof session>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch session
 */

export function useSession<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof session>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSessionSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof session>>> = ({
    signal,
  }) => session(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof session>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SessionSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof session>>
>;
export type SessionSuspenseQueryError = ErrorType<void>;

export function useSessionSuspense<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionSuspense<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionSuspense<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch session
 */

export function useSessionSuspense<
  TData = Awaited<ReturnType<typeof session>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSessionSuspenseInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSessionQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof session>>> = ({
    signal,
  }) => session(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseInfiniteQueryOptions<
    Awaited<ReturnType<typeof session>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SessionSuspenseInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof session>>
>;
export type SessionSuspenseInfiniteQueryError = ErrorType<void>;

export function useSessionSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options: {
    query: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useSessionSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Fetch session
 */

export function useSessionSuspenseInfinite<
  TData = InfiniteData<Awaited<ReturnType<typeof session>>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseInfiniteQueryOptions<
        Awaited<ReturnType<typeof session>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSessionSuspenseInfiniteQueryOptions(id, options);

  const query = useSuspenseInfiniteQuery(
    queryOptions,
  ) as UseSuspenseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Remove session
 */
export const deleteSession = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/sessions/${id}`, method: 'DELETE' },
    options,
  );
};

export const getDeleteSessionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSession>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSession>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSession>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteSession(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSessionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSession>>
>;

export type DeleteSessionMutationError = ErrorType<void>;

/**
 * @summary Remove session
 */
export const useDeleteSession = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSession>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteSession>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteSessionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Sign in
 */
export const signIn = (
  signInBody: BodyType<SignInBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/sign_in`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: signInBody,
    },
    options,
  );
};

export const getSignInMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signIn>>,
    TError,
    { data: BodyType<SignInBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof signIn>>,
  TError,
  { data: BodyType<SignInBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof signIn>>,
    { data: BodyType<SignInBody> }
  > = (props) => {
    const { data } = props ?? {};

    return signIn(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SignInMutationResult = NonNullable<
  Awaited<ReturnType<typeof signIn>>
>;
export type SignInMutationBody = BodyType<SignInBody>;
export type SignInMutationError = ErrorType<void>;

/**
 * @summary Sign in
 */
export const useSignIn = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signIn>>,
    TError,
    { data: BodyType<SignInBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof signIn>>,
  TError,
  { data: BodyType<SignInBody> },
  TContext
> => {
  const mutationOptions = getSignInMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Admin sign in
 */
export const adminSignIn = (
  adminSignInBody: BodyType<AdminSignInBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/admin/sign_in`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: adminSignInBody,
    },
    options,
  );
};

export const getAdminSignInMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminSignIn>>,
    TError,
    { data: BodyType<AdminSignInBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminSignIn>>,
  TError,
  { data: BodyType<AdminSignInBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminSignIn>>,
    { data: BodyType<AdminSignInBody> }
  > = (props) => {
    const { data } = props ?? {};

    return adminSignIn(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminSignInMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminSignIn>>
>;
export type AdminSignInMutationBody = BodyType<AdminSignInBody>;
export type AdminSignInMutationError = ErrorType<void>;

/**
 * @summary Admin sign in
 */
export const useAdminSignIn = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminSignIn>>,
    TError,
    { data: BodyType<AdminSignInBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminSignIn>>,
  TError,
  { data: BodyType<AdminSignInBody> },
  TContext
> => {
  const mutationOptions = getAdminSignInMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Sign out
 */
export const signOut = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>(
    { url: `/api/sign_out`, method: 'DELETE' },
    options,
  );
};

export const getSignOutMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signOut>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof signOut>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof signOut>>,
    void
  > = () => {
    return signOut(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SignOutMutationResult = NonNullable<
  Awaited<ReturnType<typeof signOut>>
>;

export type SignOutMutationError = ErrorType<void>;

/**
 * @summary Sign out
 */
export const useSignOut = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signOut>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof signOut>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getSignOutMutationOptions(options);

  return useMutation(mutationOptions);
};
