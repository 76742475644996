import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { createFileRoute } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { Button } from '@tremor/react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { Wrapper, TextInput, Label, Editor } from '@/components';
import { handleFormError } from '@/utils';
import { useCreatePage, getPagesQueryKey } from '@/api';

export const Route = createFileRoute('/_authenticated/pages/new')({
  component: NewPage,
});

const schema = z.object({
  title: z.string(),
  content: z.string(),
});

function NewPage() {
  const queryClient = useQueryClient();
  const { mutateAsync } = useCreatePage({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getPagesQueryKey(),
        });
      },
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const onSave = handleSubmit(async ({ title, content }) => {
    try {
      await mutateAsync({ data: { title, content } });
      reset();
      toast.success('Page created successfully!');
    } catch (error) {
      handleFormError(error, setError);
    }
  });

  return (
    <Wrapper header='New page' backLink='/pages'>
      <div className='mb-4'>
        <TextInput
          name='title'
          label='Title'
          placeholder='Title'
          control={control}
          errorMessage={errors.title?.message}
        />
      </div>
      <div className='mb-2 block'>
        <Label value='Content' />
      </div>
      <Editor
        onChange={(content) => {
          setValue('content', content);
        }}
      />
      <div className='flex justify-end mt-4'>
        <Button onClick={onSave}>Save</Button>
      </div>
    </Wrapper>
  );
}
