/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Preowned The Studio backend
 * OpenAPI spec version: v1
 */

/**
 * @nullable
 */
export enum OrderTransactionStatus {
  created = 'created',
  pending = 'pending',
  planned = 'planned',
  completed = 'completed',
  reserved = 'reserved',
  cancelled = 'cancelled',
  expired = 'expired',
  failed = 'failed',
  refunded = 'refunded',
  chargeback = 'chargeback',
  null = null,
}
