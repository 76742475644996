import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { Modal } from '@/components';
import { useFeatureItem, getItemQueryKey, getItemsQueryKey } from '@/api';
import type { Item } from '@/api/model';

export function FeatureItemModal({
  item,
  show,
  showModal,
}: {
  readonly item: Item | undefined;
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const queryClient = useQueryClient();
  const { mutateAsync } = useFeatureItem({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getItemQueryKey(item!.id),
        });
        await queryClient.invalidateQueries({
          queryKey: getItemsQueryKey(),
        });
      },
    },
  });

  const onSave = async () => {
    if (!item) return;

    await mutateAsync({ id: item.id });
    showModal(false);
    toast.success('Status updated successfully!');
  };

  return (
    <Modal
      title='Highlight advertisement'
      show={show}
      onClose={() => {
        showModal(false);
      }}
      onCancel={() => {
        showModal(false);
      }}
      onSave={onSave}
    >
      <p>Are you sure you want to highlight the advertisement?</p>
    </Modal>
  );
}
