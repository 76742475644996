import { capitalize } from 'lodash';

import type { Price } from '@/api/model';

/**
 * Gets the current browser's locale.
 * @type {string}
 */
const locale: string = navigator.language;

/**
 * Formats the given amount as currency.
 * @param {number} amount - The amount to format as currency.
 * @param {string} [currency='EUR'] - The currency code to use for formatting.
 * @returns {string} The formatted currency string.
 */
export const formatCurrency = (amount: number, currency = 'EUR'): string => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(amount / 100);
};

/**
 * Formats the given date string.
 * @param {string} date - The date string to format.
 * @returns {string} The formatted date string.
 */
export const formatDate = (date: string | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }).format(typeof date === 'string' ? new Date(date) : date);
};

/**
 * Formats the given date string.
 * @param {string} date - The date string to format.
 * @returns {string} The formatted date time string.
 */
export const formatDateTime = (date: string | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(typeof date === 'string' ? new Date(date) : date);
};

/**
 * Formats the given date string.
 * @param {string} date - The date string to format.
 * @returns {string} The formatted year.
 */
export const formatYear = (date: string | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
  }).format(typeof date === 'string' ? new Date(date) : date);
};

/**
 * Formats the given date string.
 * @param {string} date - The date string to format.
 * @returns {string} The formatted month.
 */
export const formatMonth = (date: string | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    month: 'short',
  }).format(typeof date === 'string' ? new Date(date) : date);
};

export const formatEnum = (value: string): string => {
  return capitalize(value).replaceAll('_', ' ');
};

export const percentageChange = (previous: Price, current: Price): number => {
  if (current && previous) {
    const previousMonthCents = previous.cents;

    if (previousMonthCents !== 0) {
      const change = (current.cents - previousMonthCents) / previousMonthCents;
      return change * 100;
    }

    if (current.cents === 0) {
      return 0;
    }

    return 100;
  }

  return 0;
};
