/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { Modal, TextInput } from '@/components';
import { useUpdateUser, getUsersQueryKey } from '@/api';
import { handleFormError } from '@/utils';
import type { User } from '@/api/model';

const schema = z.object({
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
});

export function UpdateAdminModal({
  admin,
  show,
  showModal,
}: {
  readonly admin: User | undefined;
  readonly show: boolean;
  readonly showModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdateUser({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getUsersQueryKey(),
        });
      },
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (admin) {
      reset({
        email: admin.email,
        firstName: admin.firstName,
        lastName: admin.lastName,
      });
    }
  }, [admin, reset]);

  const onSave = handleSubmit(async ({ email, firstName, lastName }) => {
    if (!admin) return;

    try {
      await mutateAsync({
        id: admin.id,
        data: {
          email,
          first_name: firstName,
          last_name: lastName,
          birthdate: new Date(admin.birthdate),
          country_ISO2: admin.countryIso2,
          phone_number: admin.phoneNumber,
        },
      });
      reset();
      showModal(false);
      toast.success('Admin updated successfully!');
    } catch (error) {
      handleFormError(error, setError);
    }
  });

  return (
    <Modal
      title='Update admin'
      show={show}
      onClose={() => {
        showModal(false);
      }}
      onSave={onSave}
    >
      <form className='flex flex-col gap-4'>
        <TextInput
          name='email'
          label='Email'
          placeholder='Email'
          type='email'
          control={control}
          errorMessage={errors.email?.message}
        />
        <TextInput
          name='firstName'
          label='First name'
          placeholder='First name'
          control={control}
          errorMessage={errors.firstName?.message}
        />
        <TextInput
          name='lastName'
          label='Last name'
          placeholder='Last name'
          control={control}
          errorMessage={errors.lastName?.message}
        />
      </form>
    </Modal>
  );
}
