import { Button } from 'flowbite-react';
import {
  Bold,
  Italic,
  Pilcrow,
  Heading2,
  List,
  ListOrdered,
  Undo,
  Redo,
} from 'lucide-react';
import type { Editor } from '@tiptap/react';

export function MenuBar({ editor }: { readonly editor: Editor }) {
  return (
    <Button.Group className='bg-gray-100 w-full p-2'>
      <Button
        size='sm'
        disabled={!editor.can().chain().focus().toggleBold().run()}
        color={editor.isActive('bold') ? 'primary' : 'gray'}
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <Bold size={18} />
      </Button>
      <Button
        size='sm'
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        color={editor.isActive('italic') ? 'primary' : 'gray'}
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        <Italic size={18} />
      </Button>
      <Button
        size='sm'
        color={editor.isActive('paragraph') ? 'primary' : 'gray'}
        onClick={() => editor.chain().focus().setParagraph().run()}
      >
        <Pilcrow size={18} />
      </Button>
      <Button
        size='sm'
        color={editor.isActive('heading', { level: 2 }) ? 'primary' : 'gray'}
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
      >
        <Heading2 size={18} />
      </Button>
      <Button
        size='sm'
        color={editor.isActive('bulletList') ? 'primary' : 'gray'}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <List size={18} />
      </Button>
      <Button
        size='sm'
        color={editor.isActive('orderedList') ? 'primary' : 'gray'}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <ListOrdered size={18} />
      </Button>
      <Button
        size='sm'
        color='gray'
        disabled={!editor.can().chain().focus().undo().run()}
        onClick={() => editor.chain().focus().undo().run()}
      >
        <Undo size={18} />
      </Button>
      <Button
        size='sm'
        color='gray'
        disabled={!editor.can().chain().focus().redo().run()}
        onClick={() => editor.chain().focus().redo().run()}
      >
        <Redo size={18} />
      </Button>
    </Button.Group>
  );
}
