/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Preowned The Studio backend
 * OpenAPI spec version: v1
 */

export enum ItemCondition {
  new = 'new',
  mint = 'mint',
  excellent = 'excellent',
  good = 'good',
  fair = 'fair',
}
