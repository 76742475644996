/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Preowned The Studio backend
 * OpenAPI spec version: v1
 */

export enum ItemColor {
  beige = 'beige',
  black = 'black',
  blue = 'blue',
  brown = 'brown',
  burgundy = 'burgundy',
  camel = 'camel',
  charcoal = 'charcoal',
  ecru = 'ecru',
  gold = 'gold',
  green = 'green',
  grey = 'grey',
  khaki = 'khaki',
  metallic = 'metallic',
  multicolour = 'multicolour',
  navy = 'navy',
  orange = 'orange',
  pink = 'pink',
  purple = 'purple',
  red = 'red',
  silver = 'silver',
  turquoise = 'turquoise',
  white = 'white',
  yellow = 'yellow',
  other = 'other',
}
